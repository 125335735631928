import { Components, paperClasses, Theme } from '@mui/material';

export const MuiPopover: Components<Theme>['MuiPopover'] = {
  defaultProps: {
    PaperProps: {
      elevation: 1,
    },
  },
  styleOverrides: {
    root: {
      padding: 0,
    },
    paper: ({ theme }) => ({
      borderRadius: 4,
      border: '1px solid rgba(0, 0, 0, 0.23)',
      [`&.${paperClasses.outlined}`]: {
        border: `2px solid ${theme.palette.brand.darkBlue}`,
        borderRadius: '10px',
      },
      [`&.${paperClasses.elevation1}`]: {
        boxShadow: '0px 2px 6px 1px rgba(0, 0, 0, 0.1)',
      },
    }),
  },
};
