import { getCookie, setCookie } from 'cookies-next';
import { useRouter } from 'next/router';
import qs from 'query-string';
import React, {
  createContext,
  PropsWithChildren,
  useCallback,
  useTransition,
} from 'react';

import { isValidCountry } from 'utils/country.utils';

import { Routes } from 'constants/routes.constants';
import {
  COOKIES_KEY_RECEIVER_COUNTRY,
  COOKIES_KEY_SENDER_COUNTRY,
  COOKIES_MAX_AGE_COUNTRY,
} from 'constants/storage.constants';

export type Countries = {
  receiverCountry: string;
  senderCountry: string;
};

interface CountryContextProps extends Countries {
  updateCountries(countries: Partial<Countries>, pushToRoute?: boolean): void;
}

const initialValues: CountryContextProps = {
  senderCountry: 'jm',
  receiverCountry: 'jm',
  updateCountries: () => {},
};

export const CountryContext = createContext<CountryContextProps>(initialValues);

export const CountryProvider: React.FC<PropsWithChildren> = ({ children }) => {
  const { query, replace } = useRouter();

  const [, startTransition] = useTransition();

  const getCookies = () => {
    const receiverCountry = getCookie(COOKIES_KEY_RECEIVER_COUNTRY) as
      | string
      | undefined;
    const senderCountry = getCookie(COOKIES_KEY_SENDER_COUNTRY) as
      | string
      | undefined;

    return {
      receiverCountry,
      senderCountry,
    };
  };

  const { receiverCountry, senderCountry } = getCookies();

  const handleUpdateCountries = useCallback(
    (countries: Partial<Countries>, pushToRoute: boolean = true) => {
      if (pushToRoute) {
        const search = qs.parse(window.location.search, { sort: false });

        const { receiverCountry, senderCountry } = getCookies();

        if (
          countries.receiverCountry &&
          countries.receiverCountry !== receiverCountry &&
          isValidCountry(countries.receiverCountry)
        ) {
          setCookie(COOKIES_KEY_RECEIVER_COUNTRY, countries.receiverCountry, {
            sameSite: 'strict',
            secure: true,
            maxAge: COOKIES_MAX_AGE_COUNTRY,
          });
        }

        if (
          countries.senderCountry &&
          countries.senderCountry !== senderCountry &&
          isValidCountry(countries.senderCountry)
        ) {
          setCookie(COOKIES_KEY_SENDER_COUNTRY, countries.senderCountry, {
            sameSite: 'strict',
            secure: true,
            maxAge: COOKIES_MAX_AGE_COUNTRY,
          });
        }

        startTransition(() => {
          replace(
            {
              pathname: Routes.Root,
              query: {
                ...query,
                senderCountry:
                  senderCountry?.toLowerCase() || initialValues.senderCountry,
                receiverCountry:
                  receiverCountry?.toLowerCase() ||
                  initialValues.receiverCountry,
                ...countries,
                // add search from location, so essential params (for tracking), don't get stripped from the url
                ...search,
              },
            },
            undefined,
            { shallow: true },
          );
        });
      }
    },
    [query, replace],
  );

  return (
    <CountryContext.Provider
      value={{
        receiverCountry: receiverCountry || initialValues.receiverCountry,
        senderCountry: senderCountry || initialValues.senderCountry,
        updateCountries: handleUpdateCountries,
      }}
    >
      {children}
    </CountryContext.Provider>
  );
};
