import { CountryContext } from 'providers/country';
import { useCallback, useContext } from 'react';

import { useTopupCountries } from 'queries/topup/useTopupCountries';

export const useCountries = () => {
  const countryContext = useContext(CountryContext);
  const {
    data: countries,
    isFetching,
    isFetchedAfterMount,
  } = useTopupCountries();

  const getCountryByCode = useCallback(
    (code: string) =>
      countries.find((c) => c.codeAlpha2.toLowerCase() === code),
    [countries],
  );

  return {
    ...countryContext,
    getCountryByCode,
    countries,
    isFetching,
    isFetchedAfterMount,
  };
};
