import { AxiosRequestConfig, InternalAxiosRequestConfig } from 'axios';
import { getCookie } from 'cookies-next';
import { v4 as uuidv4 } from 'uuid';

import { isApiUrl } from 'utils/api.utils';
import { isAndroid, isDesktop, isInIframe, isIos } from 'utils/device.utils';
import { getTokens } from 'utils/token.utils';

import { AVAILABLE_LOCALES } from 'constants/i18n.constants';
import {
  COOKIES_KEY_LOCALE,
  COOKIES_KEY_RECEIVER_COUNTRY,
  STORAGE_KEY_ANONYMOUS,
  STORAGE_KEY_MDA,
} from 'constants/storage.constants';

const success = (request: InternalAxiosRequestConfig) => {
  let locale = getCookie(COOKIES_KEY_LOCALE) as string | undefined;

  if (!locale && typeof window !== 'undefined') {
    // .slice(1) removes the first slash, so when we split on the remaining slashes, we can just grab the first item
    locale = window.location.pathname.slice(1).split('/')[0];
  }

  const receiverCountry = getCookie(COOKIES_KEY_RECEIVER_COUNTRY) as
    | string
    | undefined;

  const { accessToken, appAccessToken } = getTokens();

  const { baseURL } = request;

  if (typeof window !== 'undefined') {
    const isMda = !!sessionStorage.getItem(STORAGE_KEY_MDA) || isInIframe;

    if (isDesktop()) {
      request.headers.set('app-platform', isMda ? 'mydigicel-web' : 'web');
    } else if (isIos()) {
      request.headers.set(
        'app-platform',
        isMda ? 'mydigicel-ios' : 'web-mobile',
      );
    } else if (isAndroid()) {
      request.headers.set(
        'app-platform',
        isMda ? 'mydigicel-android' : 'web-mobile',
      );
    }
  }

  if (locale && AVAILABLE_LOCALES.includes(locale)) {
    request.headers.set('Accept-Language', locale);
  }

  request.headers.set('dua-request-id', uuidv4());

  if (receiverCountry?.length) {
    request.headers.set('app-country', receiverCountry.toLowerCase());
  }

  if (isApiUrl(baseURL) && !request.authDisabled) {
    if (accessToken || appAccessToken) {
      if (appAccessToken) {
        request.headers.set('dua-version', '1');
        request.headers.set('Authorization', `Bearer ${appAccessToken}`);
      } else {
        request.headers.set('Authorization', `Bearer ${accessToken}`);
      }
    } else if (request.hasAnonymous) {
      const id = getCookie(STORAGE_KEY_ANONYMOUS) as string | undefined;
      if (id && id.length) {
        request.headers.set('Authorization', `Anonymous ${id}`);
      }
    }
  }

  return request;
};

const error = (connection: AxiosRequestConfig) => Promise.reject(connection);

export default { success, error };
