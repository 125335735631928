import axios, { AxiosError, AxiosInstance, AxiosResponse } from 'axios';

import { clearTokens, getTokens, storeTokens } from 'utils/token.utils';

import { LOGOUT_EVENT } from 'constants/events.constants';
import QueryKeys from 'queries/QueryKeys';
import { apiService } from 'services';
import { queryClient } from 'services/react-query';
import { TokenResponse } from 'types/auth.types';

type OpenRetryConnection = {
  resolve: (value: unknown) => void;
  reject: (reason?: unknown) => void;
  originalRequest: AxiosError;
};

let openRetryConnections: Array<OpenRetryConnection> = [];
let isRefreshingToken = false;

const success = (response: AxiosResponse) => response;

const handleRefreshSuccess = (tokens: TokenResponse) => {
  const { remember } = getTokens();

  storeTokens(tokens, remember);
};

const handleRefreshFail = () => {
  clearTokens();
  queryClient.removeQueries({ queryKey: QueryKeys.user.profile() });
  queryClient.removeQueries({ queryKey: QueryKeys.transactions.all() });
  queryClient.removeQueries({ queryKey: QueryKeys.topup.autoPays() });

  if (typeof window !== 'undefined') {
    window.dispatchEvent(new CustomEvent(LOGOUT_EVENT));
  }
};

const error = (error: AxiosError) => {
  if (shouldInterceptError(error)) {
    const { refreshToken } = getTokens();
    if (refreshToken) {
      return retryWithNewAccessToken(refreshToken, error);
    } else {
      return handleRefreshFailure();
    }
  }

  return Promise.reject(error);
};

const retryWithNewAccessToken = async (
  refreshToken: string,
  originalRequest: AxiosError,
) =>
  new Promise((resolve, reject) => {
    openRetryConnections.push({
      resolve,
      reject,
      originalRequest,
    });

    if (!isRefreshingToken) {
      isRefreshingToken = true;
      refreshAndResolveOpenCalls(refreshToken).then((response) => {
        if (response) {
          handleRefreshSuccess(response);
        }
      });
    }
  });

const refreshAndResolveOpenCalls = async (refreshToken: string) => {
  console.info('Auth - refreshing token');
  try {
    const { data } = await apiService.refresh({ refreshToken });

    resolveOpenConnectons(data.accessToken);

    return data;
  } catch (error) {
    handleRefreshFailure();
  }
};

const handleRefreshFailure = () => {
  handleRefreshFail();
  rejectOpenConnections();
};

const rejectOpenConnections = () => {
  openRetryConnections.forEach(({ reject, originalRequest }) => {
    reject(originalRequest);
  });

  openRetryConnections = [];

  isRefreshingToken = false;
};

const resolveOpenConnectons = (accessToken: string) => {
  openRetryConnections.forEach(({ resolve, originalRequest }) => {
    if (!originalRequest?.config) return;

    if (originalRequest?.config?.headers)
      originalRequest.config.headers.set(
        'Authorization',
        `Bearer ${accessToken}`,
      );

    return resolve(axios(originalRequest?.config));
  });

  openRetryConnections = [];

  isRefreshingToken = false;
};

const INTERCEPT_STATUS_CODES = [401];

const shouldInterceptError = (error: AxiosError) => {
  return (
    error.response &&
    INTERCEPT_STATUS_CODES.includes(error.response.status) &&
    !error?.config?.url?.includes('oauth')
  );
};

export const createAuthRefreshInterceptor = (axios: AxiosInstance) =>
  axios.interceptors.response.use(success, error);
