import { ClientConfig } from '@prismicio/client';

export const routes: ClientConfig['routes'] = [
  { path: '/en/privacy-policy', type: 'privacy_policy' },
  { path: '/en/terms-of-use', type: 'terms_of_use' },
  { path: '/en/terms-and-conditions', type: 'terms_and_conditions' },
  { path: '/en/faq', type: 'faq' },
  { path: '/en/contact', type: 'contact' },
  {
    path: '/en/promotions/:uid',
    type: 'promotions',
  },
  {
    path: '/en/digicel-call-international',
    type: 'digicel_call_international',
  },
  {
    path: '/en/digicel-international-app',
    type: 'digicel_international_app',
  },
  {
    path: '/en/support',
    type: 'support',
  },
  {
    path: '/en/cookie-notice',
    type: 'cookie_notice',
  },
  {
    path: '/en/faq/:uid',
    type: 'faq_page',
  },
];
