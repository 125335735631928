import { createTheme } from '@mui/material/styles';
// Setup theme allows to be imported at other places
export const baseTheme = createTheme({
  spacing: 4,
  palette: {
    primary: { main: '#E4002B' },
    secondary: { main: '#2C2D67' },
    error: { main: '#E4002B' },
    success: { main: '#89E168' },
    background: {
      default: '#F4F5FA',
    },
    brand: {
      red: '#E4002B',
      darkBlue: '#2C2D67',
      lightGrey: '#FAFAFA',
      darkGrey: '#6D6E70',
      black: '#000000',
    },
    gradient: {
      primary: 'linear-gradient(45deg, #FE402A, #A101FF 100%)',
      disabled: 'linear-gradient(270deg, #D1D1D1 0%, #737576 100%)',
      warning: 'linear-gradient(270deg, #FCD144 0%, #FF7800 100%)',
      success: 'linear-gradient(76.84deg, #5DB037 10.34%, #8BE36A 90.64%)',
      error: 'linear-gradient(76.84deg, #E01A2D 10.34%, #FF1D33 90.64%)',
    },
    support: {
      neutral01: '#FFFFFF',
      neutral02: '#000000',
      neutral03: '#333333',
      neutral04: '#A2A2A2',
      neutral05: '#D9D9D9',
      neutral06: '#F7F7F7',
    },
    messaging: {
      validation: '#00C864',
      validation100: '#CAFEE4',
      validation200: '#006432',
      error: '#DE350B',
      error100: '#FFBDAD',
      error200: '#BF2600',
      warning: '#FFAA00',
      warning100: '#FFEECC',
      warning200: '#803D00',
      information: '#00A5FF',
      information100: '#CCEDFF',
      information200: '#0066B0',
    },
  },
});
