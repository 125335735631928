import { createContext, ReactNode, useEffect, useState } from 'react';

export const MountedContext = createContext(false);

interface Props {
  children?: ReactNode;
}

export const MountedProvider = ({ children }: Props) => {
  const [isMounted, setIsMounted] = useState(false);

  useEffect(() => {
    setIsMounted(true);
  }, []);

  return (
    <MountedContext.Provider value={isMounted}>
      {children}
    </MountedContext.Provider>
  );
};
