import { capitalize } from '@mui/material';
import {
  UseMutateFunction,
  useMutation,
  useQueryClient,
} from '@tanstack/react-query';
import { useRouter } from 'next/router';
import {
  createContext,
  ReactNode,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';

import { clearTokens, getTokens, storeTokens } from 'utils/token.utils';

import { LOGOUT_EVENT } from 'constants/events.constants';
import { Routes } from 'constants/routes.constants';
import { useCountries } from 'hooks/useCountries';
import { fetchUserProfile } from 'queries/user/queries';
import { apiService } from 'services';
import {
  errorLogged,
  login as trackLogin,
  MethodValueType,
  signUp,
} from 'services/avo/avo';
import { useResetStore } from 'store';
import { ApiError, AxiosApiErrorResponse } from 'types/api.types';
import {
  LoginParams,
  SocialLoginParams,
  TokenResponse,
} from 'types/auth.types';

interface AuthContextType {
  isLoading: boolean;
  logout: () => void;
  storeTokens: (tokens: TokenResponse, remember: boolean) => void;
  login: UseMutateFunction<TokenResponse, unknown, LoginParams, unknown>;
  socialLogin: UseMutateFunction<
    TokenResponse,
    unknown,
    SocialLoginParams,
    unknown
  >;
  isLoggedIn: boolean;
  loginError?: ApiError;
  socialLoginError?: ApiError;
}

export const AuthContext = createContext<AuthContextType>(
  {} as AuthContextType,
);

const login = async ({ remember, ...rest }: LoginParams) => {
  const { data } = await apiService.login(rest);

  return data;
};

const socialLogin = async ({
  provider,
  token,
  redirectUrl,
  inAuthId,
}: SocialLoginParams) => {
  const { data } = await apiService.socialLogin({
    provider,
    token,
    redirectUrl,
    inAuthId,
  });

  return data;
};

interface Props {
  children: ReactNode;
}

const AuthProvider = ({ children }: Props) => {
  const countries = useCountries();
  const { push, pathname } = useRouter();
  const queryClient = useQueryClient();
  const resetStore = useResetStore();
  const [isLoggedIn, setIsloggedIn] = useState(!!getTokens().accessToken);

  useEffect(() => {
    function logoutEventHandler() {
      setIsloggedIn(false);
    }
    if (typeof window !== 'undefined') {
      window.addEventListener(LOGOUT_EVENT, logoutEventHandler);
    }
    return () => {
      if (typeof window !== 'undefined') {
        window.removeEventListener(LOGOUT_EVENT, logoutEventHandler);
      }
    };
  }, []);
  const handleTrackLogin = async (method: MethodValueType) => {
    const user = await fetchUserProfile(queryClient);

    if (user) {
      trackLogin({
        method,
        email: user.email,
        userId_: user.uuid,
        language: user.language ?? 'en',
      });
    }
  };

  const {
    mutate: mutateLogin,
    // isLoading: isLoadingLogin,
    isPaused: isPausedLogin,
    isPending: isPendingLogin,
    error: loginError,
  } = useMutation<TokenResponse, AxiosApiErrorResponse, LoginParams>({
    mutationFn: login,
    onSuccess: (data, { remember = false }) => {
      storeTokens(data, remember);
      setIsloggedIn(!!getTokens().accessToken);
      handleTrackLogin('Email');
    },
    onError: (error) => {
      errorLogged({
        errorMessage: error.response?.data.message ?? '',
        errorCode: String(error.response?.status),
        errorCategory: 'login',
        autopayEnabled: undefined,
        itemId: undefined,
        paymentType: undefined,
      });
    },
  });

  const {
    mutate: mutateSocialLogin,
    // isLoading: isLoadingSocialLogin,
    isPaused: isPausedSocialLogin,
    isPending: isPendingSocialLogin,
    error: socialLoginError,
  } = useMutation<TokenResponse, AxiosApiErrorResponse, SocialLoginParams>({
    mutationFn: socialLogin,
    onSuccess: (data, { remember = false, provider, register }) => {
      storeTokens(data, remember);
      setIsloggedIn(!!getTokens().accessToken);

      const method = capitalize(provider) as MethodValueType;

      if (register) {
        signUp({
          method,
        });
      } else {
        handleTrackLogin(method);
      }
    },
    onError: (error) => {
      errorLogged({
        errorMessage: error.message,
        errorCode: String(error.code),
        errorCategory: 'login',
        autopayEnabled: undefined,
        itemId: undefined,
        paymentType: undefined,
      });
    },
  });

  const handleStoreTokens = useCallback(
    (tokens: TokenResponse, remember = false) => {
      storeTokens(tokens, remember);
    },
    [],
  );

  const handleLogout = useCallback(() => {
    clearTokens();
    setIsloggedIn(!!getTokens().accessToken);
    queryClient.clear();
    resetStore();
    if (pathname !== Routes.Root) {
      push({
        pathname: Routes.Root,
        query: {
          senderCountry: countries.senderCountry,
          receiverCountry: countries.receiverCountry,
        },
      });
    }
  }, [
    queryClient,
    resetStore,
    pathname,
    push,
    countries.senderCountry,
    countries.receiverCountry,
  ]);

  const memoedValue = useMemo(
    () => ({
      isLoggedIn,
      login: mutateLogin,
      socialLoginError: socialLoginError
        ? (socialLoginError?.response?.data ?? (socialLoginError as ApiError))
        : undefined,
      loginError: loginError
        ? (loginError?.response?.data ?? (loginError as ApiError))
        : undefined,
      socialLogin: mutateSocialLogin,
      isLoading:
        (isPendingLogin && !isPausedLogin) ||
        (isPendingSocialLogin && !isPausedSocialLogin),

      logout: handleLogout,
      storeTokens: handleStoreTokens,
    }),
    [
      isLoggedIn,
      mutateLogin,
      socialLoginError,
      loginError,
      mutateSocialLogin,
      isPendingLogin,
      isPausedLogin,
      isPendingSocialLogin,
      isPausedSocialLogin,
      handleLogout,
      handleStoreTokens,
    ],
  );

  return (
    <AuthContext.Provider value={memoedValue}>{children}</AuthContext.Provider>
  );
};

export default AuthProvider;
