import { MutationCache, QueryClient } from '@tanstack/react-query';
import { AxiosError } from 'axios';

const mutationCache = new MutationCache();

// Allows to easily create a query client with the right options
export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      retry: (failureCount: number, error: unknown) => {
        const status = (error as AxiosError)?.response?.status;
        const code = (error as AxiosError)?.code;
        if (status === 401 || status === 404 || code === 'ECONNABORTED') {
          return false;
        }
        return failureCount < 2;
      },
    },
  },
  mutationCache,
});
