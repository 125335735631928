import { Maintenance } from 'types/maintenance.types';

import RestApiService from '.';

export default {
  getMaintenance(this: RestApiService, country: string) {
    return this.api.get<Maintenance>(`/maintenance/${country}`, {
      authDisabled: true,
    });
  },
};
