import { Components, formLabelClasses, Theme } from '@mui/material';

export const MuiFormLabel: Components<Theme>['MuiFormLabel'] = {
  defaultProps: {},
  styleOverrides: {
    root: ({ theme }) => ({
      [`&.${formLabelClasses.disabled}`]: {
        color: theme.palette.brand.darkBlue,
      },
    }),
  },
};
