import { Components, Theme } from '@mui/material';

export const MuiDrawer: Components<Theme>['MuiDrawer'] = {
  defaultProps: {},
  styleOverrides: {
    paper: {
      borderRadius: 0,
    },
  },
};
