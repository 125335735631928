import { Components, Theme } from '@mui/material';

export const MuiFormHelperText: Components<Theme>['MuiFormHelperText'] = {
  styleOverrides: {
    root: {
      fontSize: 12,
      fontWeight: 400,
      marginLeft: 0,
    },
  },
};
