import { createTheme } from '@mui/material/styles';
import localFont from 'next/font/local';

const hurme = localFont({
  src: [
    {
      path: '../assets/fonts/HurmeGeometricSans-Regular.woff2',
      weight: '400',
      style: 'normal',
    },
    {
      path: '../assets/fonts/HurmeGeometricSans-Bold.woff2',
      weight: '700',
      style: 'normal',
    },
  ],
});

export const typography = createTheme({
  typography: {
    fontSize: 16,
    fontFamily: hurme.style.fontFamily,
    h1: {
      fontWeight: 700,
      fontSize: 40,
      lineHeight: '48px',
      letterSpacing: '0.15px',
    },
    h2: {
      fontWeight: 700,
      fontSize: 32,
      lineHeight: '40px',
      letterSpacing: '0.15px',
    },
    h3: {
      fontWeight: 700,
      fontSize: 26,
      lineHeight: '40px',
      letterSpacing: '0.15px',
    },
    h4: {
      fontWeight: 700,
      fontSize: 20,
      lineHeight: '30px',
      letterSpacing: '0.15px',
    },
    h5: {
      fontWeight: 700,
      fontSize: 16,
      lineHeight: '24px',
      letterSpacing: '0.15px',
    },
    h6: {
      fontWeight: 700,
      fontSize: 12,
      lineHeight: '24px',
      letterSpacing: '0.15px',
    },
    h7: {
      fontWeight: 700,
      fontSize: 11,
      lineHeight: '20px',
    },
    h8: {
      fontSize: 32,
      fontWeight: 700,
      lineHeight: '40px',
      letterSpacing: '0.15px',
    },
    body1: {
      fontSize: 16,
      lineHeight: '24px',
      letterSpacing: '0.15px',
    },
    body2: {
      fontSize: 12,
      lineHeight: '20px',
    },
    caption: {
      fontSize: 9,
      lineHeight: '20px',
    },
    button: {
      fontSize: 16,
      lineHeight: '24px',
      fontWeight: 700,
    },
  },
}).typography;
