import { RiaToken } from 'types/ria.types';

import RestApiService from '.';

export default {
  getRiaToken(this: RestApiService) {
    return this.api.get<RiaToken>('/remittance/token');
  },
  postRiaToken(this: RestApiService, params: RiaToken) {
    return this.api.post<RiaToken>('/remittance/token', params);
  },
};
