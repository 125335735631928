/* eslint-disable @typescript-eslint/no-var-requires */
import '@formatjs/intl-relativetimeformat/locale-data/en';
import '@formatjs/intl-relativetimeformat/locale-data/be';

import { setCookie } from 'cookies-next';
import { registerLocale } from 'i18n-iso-countries';
import React, { PropsWithChildren, useEffect } from 'react';
import { IntlProvider as ReactIntlProvider } from 'react-intl';

import {
  COOKIES_KEY_LOCALE,
  COOKIES_MAX_AGE_LANGUAGE,
} from 'constants/storage.constants';
import { useLocale } from 'hooks/useLocale';

import { intl } from './config';

registerLocale(require('i18n-iso-countries/langs/en.json'));
registerLocale(require('i18n-iso-countries/langs/fr.json'));
registerLocale(require('i18n-iso-countries/langs/nl.json'));
registerLocale(require('i18n-iso-countries/langs/es.json'));

const IntlProvider: React.FC<PropsWithChildren> = ({ children }) => {
  const locale = useLocale();

  useEffect(() => {
    // override next locale on intitialisation
    setCookie(COOKIES_KEY_LOCALE, locale, {
      maxAge: COOKIES_MAX_AGE_LANGUAGE,
      secure: true,
      sameSite: 'strict',
    });
  }, [locale]);

  return (
    <ReactIntlProvider locale={locale} messages={intl[locale].messages}>
      {children}
    </ReactIntlProvider>
  );
};

export default IntlProvider;
