import { E164Number } from 'libphonenumber-js';

import { TopupCountry } from 'types/topup.types';

const hasPrefix = (value: string) => value.charAt(0) === '+';

export const prefixDigits = (digits: string) => {
  if (hasPrefix(digits)) {
    return digits as E164Number;
  }

  return `+${digits}` as E164Number;
};

interface ExtendedTopupCountry extends TopupCountry {
  areaCodes?: Array<string>;
}

export const sanitizeInput = (value?: string) => {
  if (value) {
    return value.replace(/\+| |\-/g, '');
  }

  return '';
};

export function filterCountriesByAdaptiveDiallingCode(
  countries: Array<ExtendedTopupCountry>,
  userInput?: string,
) {
  if (!userInput) {
    return countries;
  }

  const sanitizedInput = sanitizeInput(userInput);

  const filteredCountries = countries.filter(
    ({ diallingCodes, areaCodes, countryDiallingCode }) => {
      const codes = areaCodes
        ? [...diallingCodes, ...areaCodes]
        : diallingCodes;
      const prefixPattern = '(1|\\+1)?';

      const pattern = new RegExp(`^${prefixPattern}${sanitizedInput}\\d*`);

      if (userInput) {
        return (
          (codes.some((code) => pattern.test(code)) ||
            codes.some((code) => sanitizedInput.startsWith(code))) &&
          countryDiallingCode.length >= sanitizedInput.length
        );
      }
    },
  );

  if (
    !filteredCountries.length ||
    (filteredCountries.length === 1 &&
      sanitizedInput.length ===
        filteredCountries[0].countryDiallingCode.length &&
      filteredCountries[0].codeAlpha2 !== 'GP')
  ) {
    return countries;
  }

  return filteredCountries;
}
