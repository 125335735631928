import axios, { AxiosRequestConfig } from 'axios';
import { camelizeKeys, decamelizeKeys } from 'humps';
import { stringify } from 'qs';

import { BASE_HEADERS } from 'constants/headers.constants';

import { requestInterceptors, responseInterceptors } from './interceptors';

const DEFAULT_CONFIG: AxiosRequestConfig = {
  headers: BASE_HEADERS,
  paramsSerializer: (params) => {
    return stringify(decamelizeKeys(params), {
      arrayFormat: 'comma',
      encode: false,
    });
  },
  transformRequest: [
    (data, headers) => {
      data = decamelizeKeys(data, { split: /(?=[A-Z0-9])/ });
      // convert all body objects to strings
      if (
        headers &&
        headers['Content-Type'] === 'application/x-www-form-urlencoded'
      ) {
        data = stringify(data);
      } else if (typeof data === 'object') {
        data = JSON.stringify(data);
      }
      return data;
    },
  ],
  transformResponse: [
    (data) => {
      try {
        // convert all body keys to camelCase
        if (typeof data !== 'object' && data) {
          data = JSON.parse(data);
        }
        if (!(data instanceof Blob)) {
          data = camelizeKeys(data);
        }
        return data;
      } catch (error) {
        return camelizeKeys(data);
      }
    },
  ],
};

const createManager = (requestConfig?: AxiosRequestConfig) => {
  const manager = axios.create({ ...DEFAULT_CONFIG, ...requestConfig });

  // Request interceptors
  requestInterceptors.forEach((interceptor) => {
    manager.interceptors.request.use(interceptor.success, interceptor.error);
  });

  // Response interceptor
  responseInterceptors.forEach((interceptor) => interceptor(manager));

  return manager;
};

export { createManager };
