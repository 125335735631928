import { Components, Theme } from '@mui/material';

export const MuiTabs: Components<Theme>['MuiTabs'] = {
  defaultProps: {
    variant: 'fullWidth',
    color: 'secondary',
  },
  styleOverrides: {
    root: ({ theme }) => ({
      padding: theme.palette.support.neutral01,
      borderRadius: 10,
      border: '2px solid #DDDDDD',
      background: theme.palette.support.neutral01,
    }),
    indicator: ({ theme }) => ({
      height: '100%',
      backgroundColor: theme.palette.brand.darkBlue,
      borderRadius: 10,
    }),
  },
};
