import { Components, Theme } from '@mui/material';

export const MuiSelect: Components<Theme>['MuiSelect'] = {
  defaultProps: {
    variant: 'outlined',
    MenuProps: {
      PaperProps: {
        elevation: undefined,
        variant: 'outlined',
      },
    },
  },
};
