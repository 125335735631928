import { create } from 'zustand';

import { allSteps, StepValues, TopUpStep } from 'types/topup.types';

import { StepType } from 'components/@stepper/MultiStep/types';

type Payload = Partial<StepValues>;

interface MultistepState {
  currentStep: StepType;
  actions: {
    back(payload?: Payload): void;
    reset(resetPayload?: boolean): void;
    proceed: (payload?: Payload) => void;
    to(step: StepType, payload?: Payload): void;
    setValues(values?: StepValues): void;
    setTotalSteps(totalSteps: number): void;
    getStepIndex(step: string): number;
    getCurrentStep: () => string;
  };
  totalSteps?: number;
  values?: StepValues;
}

const filteredSteps = (slice: MultistepState) => {
  const skipAddOn = slice.values?.skipAddOn;
  const skipEnableAutoPay = slice.values?.skipEnableAutoPay;
  const skipRecurrence = slice.values?.skipRecurrence;
  const skipEnableAutoPayJamaica = slice.values?.skipEnableAutoPayJamaica;
  const skipAuthentication = slice.values?.skipAuthentication;

  const steps = allSteps.filter((step) => {
    if (skipAddOn && step === 'addon') {
      return false;
    }

    if (skipEnableAutoPayJamaica && step === 'enableAutoPayJamaica') {
      return false;
    }

    if (skipEnableAutoPay && step === 'enableAutoPay') {
      return false;
    }

    if (skipRecurrence && step === 'recurrence') {
      return false;
    }

    if (skipAuthentication && step === 'authentication') {
      return false;
    }

    return true;
  });

  return steps;
};

const useMultiStepStore = create<MultistepState>((set, get) => ({
  currentStep: 0,
  actions: {
    back: () =>
      set((state) => {
        return { currentStep: state?.currentStep - 1 };
      }),
    reset: (resetPayload) => {
      const payload = resetPayload ? { values: {} } : {};

      set({ currentStep: 0, ...payload });
    },
    to: (step, payload) => {
      set((state) => ({
        currentStep: step,
        values: { ...state?.values, ...payload },
      }));
    },
    getStepIndex: (step: TopUpStep) => {
      return filteredSteps(get()).indexOf(step);
    },
    getCurrentStep: () => {
      return filteredSteps(get())[get().currentStep];
    },
    proceed: (payload) => {
      set((state) => ({
        currentStep: state?.currentStep + 1,
        values: { ...state?.values, ...payload },
      }));
    },
    setValues: (values) =>
      set((state) => ({ values: { ...state.values, ...values } })),
    setTotalSteps: (totalSteps) => set({ totalSteps }),
  },
  values: {
    skipEnableAutoPay: true,
    skipAddOn: true,
    skipRecurrence: true,
    skipEnableAutoPayJamaica: true,
    skipAuthentication: false,
  },
}));

export const useMultiStepActions = () =>
  useMultiStepStore((state) => state.actions);

export const useMultiStepValues = () =>
  useMultiStepStore((state) => state.values);

export const useMultiStepCurrentStep = () =>
  useMultiStepStore((state) => state.currentStep);

export const useMultiStepTotalSteps = () =>
  useMultiStepStore((state) => state.totalSteps);
