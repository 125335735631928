import { cardActionAreaClasses, Components, Theme } from '@mui/material';

export const MuiCardActionArea: Components<Theme>['MuiCardActionArea'] = {
  defaultProps: {
    disableTouchRipple: true,
    disableRipple: true,
  },

  styleOverrides: {
    root: ({ theme }) => ({
      [`&:hover .${cardActionAreaClasses.focusHighlight}`]: {
        opacity: 0,
        '@media (hover: none)': {
          opacity: 0,
        },
      },
      [`&.${cardActionAreaClasses.focusVisible} .${cardActionAreaClasses.focusHighlight}`]:
        {
          backgroundColor: theme.palette.brand.darkBlue,
          opacity: 0.1,
        },
    }),
    focusVisible: {},
    focusHighlight: {
      borderRadius: 10,
      top: -3,
      bottom: -3,
      right: -3,
      left: -3,
    },
  },
};
