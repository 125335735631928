import { Components } from '@mui/material';

export const MuiStep: Components['MuiStep'] = {
  styleOverrides: {
    root: {
      paddingLeft: 0,
      paddingRight: 0,
    },
  },
};
