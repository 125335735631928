import { Components, outlinedInputClasses, Theme } from '@mui/material';

export const MuiOutlinedInput: Components<Theme>['MuiOutlinedInput'] = {
  styleOverrides: {
    root: ({ theme }) => ({
      background: theme.palette.support.neutral01,
      borderColor: theme.palette.support.neutral05,
      borderRadius: 10,
      borderWidth: 1,
      color: theme.palette.support.neutral04,
      [`&.${outlinedInputClasses.focused} .${outlinedInputClasses.notchedOutline}`]:
        {
          borderColor: theme.palette.brand.darkBlue,
          borderWidth: 2,
        },
      [`&:hover .${outlinedInputClasses.notchedOutline}`]: {
        borderColor: theme.palette.brand.darkBlue,
        borderWidth: 2,
      },
      [`&.${outlinedInputClasses.disabled} .${outlinedInputClasses.notchedOutline}`]:
        {
          borderColor: theme.palette.support.neutral05,
          borderWidth: 1,
        },
    }),
    notchedOutline: ({ theme }) => ({
      borderColor: theme.palette.support.neutral05,
      borderWidth: 1,
      top: 0,
      [`& legend`]: {
        display: 'none',
      },
    }),
    input: ({ theme }) => ({
      height: 44,
      lineHeight: 1,
      fontSize: 16,
      boxSizing: 'border-box',
      borderRadius: 10,
      '&::placeholder': {
        color: theme.palette.support.neutral04,
        fontWeight: 400,
      },
      color: theme.palette.brand.darkBlue,
      fontWeight: 400,
      [`&:disabled`]: {
        backgroundColor: '#E6E6E6',
      },
    }),
  },
};
