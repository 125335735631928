import {
  LoginParams,
  RefreshParams,
  SocialLoginParams,
  TokenResponse,
} from 'types/auth.types';

import RestApiService from '.';

export default {
  login(this: RestApiService, params: Omit<LoginParams, 'remember'>) {
    return this.api.post<TokenResponse>(
      '/oauth/token',
      {
        ...params,
        grantType: 'password',
      },
      {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
        },
      },
    );
  },
  socialLogin(
    this: RestApiService,
    params: Omit<SocialLoginParams, 'remember'>,
  ) {
    return this.api.post<TokenResponse>('/oauth/social', {
      providerKey: params.provider,
      providerToken: params.token,
      inauthId: params.inAuthId,
      redirectUrl: params.redirectUrl,
    });
  },
  refresh(this: RestApiService, params: Omit<RefreshParams, 'grantType'>) {
    return this.api.post<TokenResponse>(
      '/oauth/token',
      { ...params, grantType: 'refresh_token' },
      {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
        },
        authDisabled: true,
      },
    );
  },
};
