import { Content } from '@prismicio/client';
import { Query } from '@prismicio/types';
import { getCookie } from 'cookies-next';

import { DEFAULT_LOCALE } from 'constants/i18n.constants';
import { COOKIES_KEY_LOCALE } from 'constants/storage.constants';
import { PromotionsParams } from 'types/promotion.types';

import RestApiService from '.';

export default {
  getPromotions(
    this: RestApiService,
    {
      receiverCountry,
      senderCountry,
      locale,
    }: PromotionsParams & { locale?: string },
  ) {
    let _locale = locale;

    if (!_locale) {
      _locale = getCookie(COOKIES_KEY_LOCALE) as string | undefined;
    }

    if (!_locale) {
      // Last resort fallback
      _locale = DEFAULT_LOCALE;
    }

    return this.api.get<Query<Content.PromotionsDocument>>(
      `/promotions/${receiverCountry}/${senderCountry}`,
      {
        headers: {
          'Accept-Language': _locale,
        },
      },
    );
  },
  getPromotion(this: RestApiService, slug: string) {
    return this.api.get<Query<Content.PromotionsDocument>>(
      `/promotions/${slug}`,
    );
  },
};
