import {
  createClient as createPrismicClient,
  getRepositoryName,
} from '@prismicio/client';
import { CreateClientConfig, enableAutoPreviews } from '@prismicio/next';

import sm from '../../../slicemachine.config.json';
import { routes } from './routes';

export const repositoryName = getRepositoryName(sm.apiEndpoint);

export const createClient = (config?: CreateClientConfig) => {
  const client = createPrismicClient(sm.apiEndpoint, {
    routes,
    accessToken: process.env.PRISMIC_API_ACCESS_TOKEN,
    ...config,
  });

  enableAutoPreviews({
    client,
    previewData: config?.previewData,
    req: config?.req,
  });

  return client;
};
