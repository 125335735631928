import { Components, Theme } from '@mui/material';

export const MuiTypography: Components<Theme>['MuiTypography'] = {
  defaultProps: {
    color: (theme) => theme.palette.brand.darkBlue,
  },
  styleOverrides: {
    root: ({ ownerState, theme }) => ({
      ...(ownerState.color === 'gradient.primary' && {
        background: theme.palette.gradient.primary,
        WebkitBackgroundClip: 'text',
        WebkitTextFillColor: 'transparent',
      }),
    }),
  },
};
