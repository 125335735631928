import { Components, paperClasses, Theme } from '@mui/material';

export const MuiAppBar: Components<Theme>['MuiAppBar'] = {
  defaultProps: {
    elevation: 1,
    color: 'default',
    position: 'sticky',
  },
  styleOverrides: {
    colorDefault: ({ theme }) => ({
      backgroundColor: theme.palette.support.neutral01,
    }),
    root: {
      borderRadius: 0,
      height: 80,
      [`&.${paperClasses.elevation1}`]: {
        boxShadow: '0px 1px 0px rgba(0, 0, 0, 0.1)',
      },
    },
  },
};
