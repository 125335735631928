import { Components, listItemButtonClasses, Theme } from '@mui/material';

export const MuiListItemButton: Components<Theme>['MuiListItemButton'] = {
  styleOverrides: {
    root: {
      [`&.${listItemButtonClasses.disabled}`]: {
        opacity: 1,
      },
    },
  },
};
