import { Components, stepClasses } from '@mui/material';

export const MuiStepper: Components['MuiStepper'] = {
  styleOverrides: {
    root: {
      [`& .${stepClasses.root}:not(:last-child)`]: {
        marginRight: '6px',
      },
    },
  },
};
