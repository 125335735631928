export const STORAGE_KEY_ANONYMOUS = 'digi-anonymous';
export const STORAGE_KEY_PRODUCT = 'digi-purchase-product';
export const STORAGE_KEY_MDA_PHONE = 'digi-mda-phone';
export const STORAGE_KEY_MDA = 'digi-mda';

export const COOKIES_KEY_LOCALE = 'NEXT_LOCALE';
export const COOKIES_KEY_SENDER_COUNTRY = 'NEXT_SENDER_COUNTRY';
export const COOKIES_KEY_RECEIVER_COUNTRY = 'NEXT_RECEIVER_COUNTRY';

export const MDA_AUTHENTICATION_COOKIE = 'Authentication-Cookie';
export const RIA_AUTHENTICATION_COOKIE = 'Authentication';

export const INITIAL_LOGIN_TRACKED = 'INITIAL_LOGIN_TRACKED';
export const INITIAL_VISIT_TRACKED = 'INITIAL_VISIT_TRACKED';

export const THIRD_PARTY_CONSENT = 'THIRD_PARTY_CONSENT';

export const SALESFORCE_TRACKING = 'salesforce-tracking';

export const SENTOO_COUNTRY = 'sentoo-country';
export const SENTOO_CALLBACK_URL = 'sentoo-callback';
export const SENTOO_PHONE = 'sentoo-phone';
export const CALLBACK_DESTINATION = 'callback-destination';

export const COOKIES_MAX_AGE_COUNTRY = 86400 * 365; // 1 year
export const COOKIES_MAX_AGE_LANGUAGE = 86400 * 365; // 1 year
export const COOKIES_MAX_AGE_REMEMBER = 86400 * 100; // 100 days
