import { NextRouter } from 'next/router';

import { DEFAULT_LOCALE } from 'constants/i18n.constants';
import { Locale } from 'types/i18n.types';

type NextRouterLocaleProps = Pick<NextRouter, 'locale' | 'defaultLocale'>;

export const getLocale = ({ locale, defaultLocale }: NextRouterLocaleProps) => {
  let currentLocale = locale ?? defaultLocale ?? DEFAULT_LOCALE;
  if (currentLocale === 'default') {
    currentLocale = DEFAULT_LOCALE;
  }
  return currentLocale as Locale;
};
