import { ItemListNameValueType, OriginValueType } from 'services/avo/avo';

import { TransactionStatus } from './transaction.types';

export type PromoCode = {
  name?: string;
  upsellValue?: number;
  code: string;
  productId?: string;
  valid?: boolean;
  applied?: boolean;
};

type MultistepError = {
  type: string;
  message: string;
};

export type StepValues = Partial<{
  product: Product;
  type: 'plan' | 'topup';
  autoTopup: number;
  autoPay: boolean;
  addOn: Product;
  skipAddOn: boolean;
  skipEnableAutoPay: boolean;
  skipEnableAutoPayJamaica: boolean;
  skipRecurrence: boolean;
  skipAuthentication: boolean;
  phone: string;
  country: TopupCountry;
  withTax: boolean;
  promo: PromoCode;
  isPurchase: boolean;
  purchaseId: string;
  paymentOption: PaymentOption;
  origin: OriginValueType;
  listName: ItemListNameValueType;
  isTopupAgain: boolean;
  currency: CurrencyAmount;
  guest: boolean;
  didTryMda: boolean;
  returnUrl: string;
  subscriber: string;
  shouldPurchaseAfterAuth: boolean;
  fromSendTopupWithProduct: boolean;
  fromSendTopupWithoutProduct: boolean;
  error?: MultistepError;
}>;

export type TopupCountry = {
  name: string;
  codeAlpha2: string;
  codeAlpha3: string;
  diallingCodes: Array<string>;
  countryDiallingCode: string;
  areaCodes?: Array<string>;
  flagIcon: string;
  flagIconSrc?: 'external' | 'local';
};

export type Price = {
  id?: string;
  localFeeAmount: string;
  localAmountIncFee: string;
  sourceFeeAmount: string;
  sourceAmountIncFee: string;
};

export type TaxFree = Omit<Price, 'id'> & {
  id: string;
  localAmountExcFee: string;
  sourceAmountExcFee: string;
};

export type PlanType = {
  type: string;
  displayName: string;
};

export type BonusProduct = {
  productId: string;
  bonusProductId: string;
  localAmountExcFee: string;
  localAmountIncFee: string;
  sourceAmountExcFee: string;
  sourceAmountIncFee: string;
};

export type Product = {
  isPreferred: boolean;
  autoPayInterval?: string;
  operatorBonusAmount: string;
  localFeeType: string;
  localCurrency: string;
  sourceFeeType: string;
  withoutTax: TaxFree;
  withTax: Price;
  sourceCurrency: string;
  planType: PlanType;
  displayAttributes: Array<string>;
  displayName: string;
  imageUrl?: string;
  bonusProduct?: BonusProduct;
};

export type TopupProduct = {
  preferredProductType: 'topup' | 'plan';
  topups: Array<Product>;
  plans: Array<Product>;
  planAddons: Array<Product>;
  topupAddons: Array<Product>;
};

export type TopupProductParams = {
  country: string;
  productConfig?: string;
  market?: string;
};

export type ProductUpsell = {
  title: string;
  subtext: string;
  amount: string;
  productIdWithoutTax: string;
  productIdWithTax: string;
  originalProductIdWithoutTax: string;
  originalProductIdWithTax: string;
  yesButtonText: string;
  noButtonText: string;
  type: string;
  promoCode: string;
};

export type ProductUpsellParams = {
  productId?: string;
  withTax: boolean;
};

export type PromocodeParams = {
  mobileNumber: string;
  promoCode: string;
  productIdWithoutTax: string;
  productIdWithTax?: string;
};

export type PurchaseParams = {
  uuid: string;
  mobileNumber: string;
  promoCode?: string;
  autoPayInterval?: string;
  productIdWithoutTax: string;
  productIdWithTax?: string;
  returnUrl?: string;
};

export type PurchaseQueryParams = {
  market?: string;
  productConfig?: string;
};

export type PurchasePaypalParams = {
  paymentReference: string;
  paypalToken: string;
  payerId?: string;
  purchaseCancelled: boolean;
};

export enum PaymentMethod {
  PAYPAL = 'PayPal',
  APPLE_PAY = 'ApplePay',
  GOOGLE_PAY = 'GooglePay',
}

export type PurchaseWalletPayParams = {
  paymentMethod: PaymentMethod.APPLE_PAY | PaymentMethod.GOOGLE_PAY;
  paymentReference: string;
  paymentToken: string;
  currency: string;
  amount: string;
};

export type CurrencyAmount = {
  currency: string;
  amount: string;
};

export type PaymentConfiguration = {
  cardAuthMethods: string;
  cardNetworks: string;
  countryCode: string;
  gatewayName: string;
  numericMerchantId: string;
  merchantId: string;
  merchantName: string;
};

export type PaymentOption = {
  description?: string;
  url: string;
  paymentMethod: string;
  paymentMethodReference: string;
  defaultCurrency: string;
  icon: string;
  expiryDate?: string;
  currencyAmounts: Array<CurrencyAmount>;
  paymentConfiguration?: PaymentConfiguration;
};

export type PurchaseCost = Partial<{
  amount: string;
  currency: string;
  discountAmount: string;
  discountType: string;
  feeAmount: string;
  feeType?: string;
  primaryAmount: string;
  totalAmount: string;
}>;

export type Purchase = {
  purchaseId: string;
  paymentOptions: Array<PaymentOption>;
  cost: PurchaseCost;
  paymentUrls?: Array<PaymentOption>;
};

export enum RawStatus {
  SentooIssued = 'sentoo-issued',
  SentooPending = 'sentoo-pending',
  SentooSuccess = 'sentoo-success',
  SentooFailed = 'sentoo-failed',
  SentooCancelled = 'sentoo-cancelled',
  SentooRejected = 'sentoo-rejected',
  SentooUnknown = 'sentoo-unknown',
  PrismCancelled = 'prism-cancelled',
  PrismCompleted = 'prism-completed',
  PrismConfirmed = 'prism-confirmed',
  PrismCreated = 'prism-created',
  PrismDeclined = 'prism-declined',
  PrismRejected = 'prism-rejected',
  PrismReversed = 'prism-reversed',
  PrismSubmitted = 'prism-submitted',
}

export type PurchaseStatus = {
  isComplete: boolean;
  status: TransactionStatus;
  rawStatus: RawStatus;
};

export type AutoPay = {
  id: string;
  accountNumber: string;
  productId: string;
  description: string;
  intervalDays: string;
  time: number;
  productType: string;
  cost: {
    currency: string;
    amount: string;
  };
  receive: {
    currency: string;
    amount: string;
  };
};

export type ApplePayMerchantValidationResponse = {
  result: string;
  subject: string;
};

export type GetPaypalUrlParams = {
  url: string;
  purchaseId?: string;
};

export const TopUpStepLoopkUp = {
  home: 'home',
  select: 'select',
  enableAutoPayJamaica: 'enableAutoPayJamaica',
  enableAutoPay: 'enableAutoPay',
  recurrence: 'recurrence',
  addon: 'addon',
  overview: 'overview',
  authentication: 'authentication',
  purchase: 'purchase',
  payment: 'payment',
  confirmation: 'confirmation',
} as const;

export type TopUpStep =
  (typeof TopUpStepLoopkUp)[keyof typeof TopUpStepLoopkUp];

export const allSteps = [
  TopUpStepLoopkUp.home,
  TopUpStepLoopkUp.select,
  TopUpStepLoopkUp.enableAutoPayJamaica,
  TopUpStepLoopkUp.enableAutoPay,
  TopUpStepLoopkUp.recurrence,
  TopUpStepLoopkUp.addon,
  TopUpStepLoopkUp.overview,
  TopUpStepLoopkUp.authentication,
  TopUpStepLoopkUp.purchase,
  TopUpStepLoopkUp.payment,
  TopUpStepLoopkUp.confirmation,
];
