import { Alert, AlertColor } from '@mui/material';
import {
  CustomContentProps,
  SnackbarKey,
  SnackbarProvider as NotistackProvider,
} from 'notistack';
import React, { forwardRef, memo, ReactNode } from 'react';

type CloseSnackbarAction = (key?: SnackbarKey) => void;

const ThemeResponsiveSnackbarComp = forwardRef<
  HTMLDivElement,
  CustomContentProps & { component: React.ReactNode }
>(function ThemeResponsiveSnackbarComp(props, forwardedRef) {
  const { message, variant, component, persist, action } = props;
  const closeAction = action as CloseSnackbarAction;
  return (
    <Alert
      ref={forwardedRef}
      severity={variant as AlertColor}
      onClose={persist ? () => closeAction() : undefined}
      sx={{ fontSize: 16 }}
      variant="filled"
    >
      {component ? component : message}
    </Alert>
  );
});

const ThemeResponsiveSnackbar = memo(ThemeResponsiveSnackbarComp);

interface Props {
  children: ReactNode;
}

const SnackbarProvider = ({ children }: Props) => {
  return (
    <NotistackProvider
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
      autoHideDuration={3000}
      preventDuplicate
      maxSnack={3}
      dense
      Components={{
        info: ThemeResponsiveSnackbar,
        success: ThemeResponsiveSnackbar,
        error: ThemeResponsiveSnackbar,
        warning: ThemeResponsiveSnackbar,
      }}
    >
      {children}
    </NotistackProvider>
  );
};

export default SnackbarProvider;
