import { Components, tabClasses, Theme } from '@mui/material';

export const MuiTab: Components<Theme>['MuiTab'] = {
  styleOverrides: {
    root: ({ theme }) => ({
      fontWeight: 700,
      fontSize: 20,
      letterSpacing: 0.15,
      textTransform: 'none',
      color: theme.palette.brand.darkBlue,
      '&:not(:first-of-type):not(:last-of-type)': {
        borderRadius: 10,
      },
      '&:not(:last-of-type)': {
        borderRadius: '0 10px 10px 0',
      },
      '&:not(:first-of-type)': {
        borderRadius: '10px 0 0 10px',
      },
      [`&.${tabClasses.selected}`]: {
        zIndex: 10,
        color: theme.palette.support.neutral01,
        '&:hover': {
          color: theme.palette.support.neutral01,

          '> #label': {
            background: 'unset',
            backgroundClip: 'unset',
            textFillColor: 'unset',
            color: theme.palette.support.neutral01,
          },
        },
      },
      '&:hover': {
        '> #label': {
          background: theme.palette.gradient.primary,
          backgroundClip: 'text',
          textFillColor: 'transparent',

          '@media (hover: none)': {
            background: 'unset',
            backgroundClip: 'unset',
            textFillColor: 'unset',
            color: theme.palette.brand.darkBlue,
          },
        },
      },
    }),
  },
};
