import { Components, Theme } from '@mui/material';

function getDialogHeight() {
  if (typeof window === 'undefined') return '100vh';
  return window.innerHeight + 'px';
}

export const MuiDialog: Components<Theme>['MuiDialog'] = {
  styleOverrides: {
    paper: ({ theme }) => ({
      padding: theme.spacing(8, 10, 10),
      borderRadius: 25,
      [theme.breakpoints.down('md')]: {
        borderRadius: 0,
        padding: theme.spacing(6),
        height: getDialogHeight(), // inner height to prevent certain mobile browsers from overlapping the bottom and top part
        width: '100vw',
        margin: 0,
        maxHeight: getDialogHeight(),
      },
    }),
  },
};
