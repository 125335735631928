import { Components, Theme } from '@mui/material';

export const MuiToolbar: Components<Theme>['MuiToolbar'] = {
  styleOverrides: {
    root: {
      height: 80,
    },
    gutters: ({ theme }) => ({
      padding: theme.spacing(0, 6),
    }),
  },
};
