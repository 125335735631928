import config from 'config';

import { IpOriginResponse } from 'types/ip.types';

import RestApiService from '.';

export default {
  getIpConfig(this: RestApiService, key?: string) {
    return this.api.get<IpOriginResponse>('https://api.ipregistry.co/', {
      params: { key: key ?? config.IP_REGISTERY_KEY },
    });
  },
};
