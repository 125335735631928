import { SENTRY_OPTIONS } from './sentry';
import { SentryOptions } from './types';

interface ENV_CONFIG {
  NAME: string;
  VERSION: string;
  API_BASE: string;
  CLIENT_URL: string;
  SERVER_URL: string;
  BUILD_ENV: string;
  RIA_SDK: string;
  GTM: {
    ID: string;
    ENV: string;
    AUTH: string;
  };
  PORT?: number;
  LOCAL_STORAGE_ID: string;
  DEFAULT_CURRENCY: string;
  DEFAULT_LOCALE: string;
  DEFAULT_LOCALE_DATE: string;
  API_MOCKING_ENABLED: boolean;
  GOOGLE_CLIENT_ID: string;
  FACEBOOK_CLIENT_ID: string;
  COOKIE_POLICY: {
    siteId: string;
    policyId: string;
  };
  SENTRY: SentryOptions;
  IP_REGISTERY_KEY: string;
  AVO_API_KEY: string;
  DIGI_LOGIN_URL: string;
  POSTHOG: {
    API_KEY: string;
    HOST: string;
  };
  TESTIMONIAL_BEARER_TOKEN: string;
  APPLE_PAY_MERCHANT_ID: string;
  GOOGLE_PAY_MERCHANT_ID: string;
}

const config: ENV_CONFIG = {
  // general
  NAME: process.env.NEXT_PUBLIC_NAME || '',
  VERSION: process.env.NEXT_PUBLIC_VERSION || '',
  BUILD_ENV: process.env.NEXT_PUBLIC_BUILD_ENV || '',

  // api
  API_BASE: process.env.API_BASE || '',
  CLIENT_URL: process.env.NEXT_PUBLIC_CLIENT_URL || '',
  SERVER_URL: process.env.NEXT_PUBLIC_SERVER_URL || '',

  // social logins
  GOOGLE_CLIENT_ID: process.env.GOOGLE_CLIENT_ID || '',
  FACEBOOK_CLIENT_ID: process.env.FACEBOOK_CLIENT_ID || '',

  // analytics
  GTM: {
    ID: process.env.GTM_ID || '',
    ENV: process.env.GTM_ENV || '',
    AUTH: process.env.GTM_AUTH || '',
  },
  AVO_API_KEY: process.env.AVO_API_KEY || '',

  // RIA
  RIA_SDK: process.env.NEXT_PUBLIC_RIA_SDK || '',

  // error tracking
  SENTRY: SENTRY_OPTIONS,

  COOKIE_POLICY: {
    siteId: process.env.NEXT_PUBLIC_COOKIE_SITE_ID ?? '',
    policyId: process.env.NEXT_PUBLIC_COOKIE_POLICY_ID ?? '',
  },

  IP_REGISTERY_KEY: process.env.NEXT_PUBLIC_IP_REGISTERY_KEY ?? '',

  // dev
  PORT: Number(process.env.NEXT_PUBLIC_PORT ?? 3000),
  LOCAL_STORAGE_ID: process.env.NEXT_PUBLIC_LOCAL_STORAGE_ID ?? '',
  DEFAULT_CURRENCY: process.env.NEXT_PUBLIC_DEFAULT_CURRENCY ?? 'EUR',
  DEFAULT_LOCALE: process.env.NEXT_PUBLIC_DEFAULT_LOCALE ?? 'en',
  DEFAULT_LOCALE_DATE: process.env.NEXT_PUBLIC_DEFAULT_LOCALE_DATE ?? 'nl',

  DIGI_LOGIN_URL: `${process.env.NEXT_PUBLIC_DIGI_BASE_URL}/networkAuthentication.do?client_id=556&response_type=code&scope=GET_ACCOUNT&redirect_uri=${process.env.NEXT_PUBLIC_CLIENT_URL}auth/callback-digid`,

  // mocking
  API_MOCKING_ENABLED: process.env.NEXT_PUBLIC_API_MOCKING === 'true',

  // posthog
  POSTHOG: {
    API_KEY: process.env.POSTHOG_KEY || '',
    HOST: process.env.POSTHOG_HOST || '',
  },

  TESTIMONIAL_BEARER_TOKEN: process.env.TESTIMONIAL_BEARER_TOKEN || '',
  APPLE_PAY_MERCHANT_ID: process.env.APPLE_PAY_MERCHANT_ID || '',
  GOOGLE_PAY_MERCHANT_ID: process.env.GOOGLE_PAY_MERCHANT_ID || '',
};

export default config;
