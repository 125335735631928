import _isEmpty from 'lodash/isEmpty';
import _pickBy from 'lodash/pickBy';
import { useRouter } from 'next/router';
import { parse } from 'qs';
import type { ParsedUrlQuery } from 'querystring';

import { useCountries } from './useCountries';

type QueryStringResult<T = object> = [T, (values: T) => void];

export function useQueryString<T>(): QueryStringResult<T> {
  const { senderCountry, receiverCountry } = useCountries();

  const { push, pathname, asPath, query } = useRouter();
  const parsedQuery = parse(
    asPath.includes('?') ? (asPath.split('?').pop() ?? '') : '',
  );

  const setQuery = (values: T) => {
    const newQuery = _pickBy({
      ...query,
      ...parsedQuery,
      ...values,
      senderCountry,
      receiverCountry,
    });

    push({ pathname, query: newQuery as ParsedUrlQuery }, undefined, {
      shallow: true,
    });
  };

  return [parsedQuery as T, setQuery];
}
