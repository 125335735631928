import { create } from 'zustand';

import { ApiError } from 'types/api.types';
import { Purchase } from 'types/topup.types';

interface PurchaseState extends Purchase {
  purchaseError?: ApiError;
  actions: {
    setPurchaseState(state: Purchase): void;
    resetPurchase(): void;
    setPurchaseError(error?: ApiError): void;
  };
}

const usePurchaseStore = create<PurchaseState>((set) => ({
  purchaseId: '',
  paymentOptions: [],
  cost: {},
  actions: {
    setPurchaseState: (payload) => set((state) => ({ ...state, ...payload })),
    resetPurchase: () =>
      set({
        purchaseId: '',
        paymentOptions: [],
        cost: {},
        purchaseError: undefined,
      }),
    setPurchaseError: (error) => set({ purchaseError: error }),
  },
}));

export const usePurchaseActions = () =>
  usePurchaseStore((state) => state.actions);

export const usePurchaseId = () =>
  usePurchaseStore((state) => state.purchaseId);

export const usePurchaseCost = () => usePurchaseStore((state) => state.cost);

export const usePurchaseError = () =>
  usePurchaseStore((state) => state.purchaseError);

export const usePurchasePaymentOptions = () =>
  usePurchaseStore((state) => state.paymentOptions);
