import config from 'config';

import { isDev, isProd, isStaging } from 'utils/env.utils';

import { AvoEnv, initAvo, Platform, WebDebuggerPosition } from './avo';
import { gaDestination, posthogDestination } from './destination';

const getEnv = () => {
  switch (true) {
    case isProd():
      return 'prod';
    case isStaging():
      return 'staging';
    default:
      return 'dev';
  }
};

export const initializeAvo = async () => {
  const Inspector = await import('avo-inspector');

  const inspector = new Inspector.AvoInspector({
    apiKey: config.AVO_API_KEY,
    env: getEnv(),
    version: config.VERSION,
    appName: `Digicel Top Up - ${getEnv()}`,
  });

  initAvo(
    {
      env: isProd() ? AvoEnv.Prod : AvoEnv.Dev,
      inspector: inspector,
      webDebugger: false,
      webDebuggerOptions: {
        position: WebDebuggerPosition.BottomLeft({ bottom: 60, left: 8 }),
      },
      strict: isDev(),
      reportFailureAs: 'warn',

      avoLogger: {
        logDebug: () => false,
        logError: () => false,
        logWarn: () => false,
      },
    },
    {
      platform: Platform.WEBSITE,
    },
    {},
    posthogDestination,
    gaDestination,
  );

  if (typeof window !== 'undefined') {
    window.avoIsReady = true;
  }
};

export const waitForAvo = async () => {
  return await new Promise((resolve, reject) => {
    let totalWaitTime = 0;
    const interval = setInterval(() => {
      totalWaitTime += 100;
      if (typeof window !== 'undefined' && !!window.avoIsReady) {
        clearInterval(interval);
        resolve(true);
      } else if (totalWaitTime >= 5000) {
        clearInterval(interval);
        reject();
      }
    }, 100);
  });
};
