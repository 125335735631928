import { QueryKey } from '@tanstack/react-query';

import { Purchase } from 'types/topup.types';
import { TransactionDetail } from 'types/transaction.types';

enum Keys {
  Ip = 'ip',
  Topup = 'topup',
  Countries = 'countries',
  Products = 'products',
  Upsell = 'upsell',
  Bonus = 'bonus',
  User = 'user',
  Profile = 'profile',
  Transactions = 'transactions',
  Status = 'status',
  AutoPay = 'autopay',
  Promotions = 'promotions',
  Purchase = 'purchase',
  Maintenance = 'maintenance',
  Ria = 'ria',
  Legal = 'legal',
  PrivacyPolicy = 'privacy-policy',
  TermsOfUse = 'terms-of-use',
  TermsAndConditions = 'terms-and-conditions',
  Faq = 'faq',
  Contact = 'contact',
  CallInternational = 'call-international',
  Support = 'support',
  CookieNotice = 'cookie-notice',
  Testimonials = 'testimonials',
  OptIn = 'opt-in',
  All = 'all',
  PaypalUrl = 'paypal-url',
}

const QueryKeys = {
  ip: (): QueryKey => [Keys.Ip],
  maintenance: (country: string): QueryKey => [Keys.Maintenance, country],
  user: {
    profile: (): QueryKey => [Keys.User, Keys.Profile],
  },
  topup: {
    countries: (): QueryKey => [Keys.Topup, Keys.Countries],
    products: (...rest: Array<unknown>): QueryKey => [
      Keys.Topup,
      Keys.Products,
      ...rest,
    ],
    upsell: (...rest: Array<unknown>): QueryKey => [
      Keys.Topup,
      Keys.Products,
      Keys.Upsell,
      ...rest,
    ],
    bonus: (productId: string): QueryKey => [
      Keys.Topup,
      Keys.Products,
      Keys.Bonus,
      productId,
    ],
    status: (purchaseId: Purchase['purchaseId']): QueryKey => [
      Keys.Topup,
      Keys.Status,
      purchaseId,
    ],
    autoPays: (): QueryKey => [Keys.Topup, Keys.AutoPay],
    purchase: (): QueryKey => [Keys.Topup, Keys.Purchase],
  },
  transactions: {
    all: (...rest: Array<unknown>): QueryKey => [Keys.Transactions, ...rest],
    byId: (transactionId: TransactionDetail['id']): QueryKey => [
      Keys.Transactions,
      transactionId,
    ],
  },
  promotions: {
    all: (...rest: Array<unknown>): QueryKey => [Keys.Promotions, ...rest],
    byUID: (uid: string, ...rest: Array<unknown>): QueryKey => [
      Keys.Promotions,
      uid,
      ...rest,
    ],
  },
  ria: {
    token: (): QueryKey => [Keys.Ria],
  },
  legal: {
    privacyPolicy: [Keys.Legal, Keys.PrivacyPolicy],
    termsOfUse: [Keys.Legal, Keys.TermsOfUse],
    termsAndConditions: [Keys.Legal, Keys.TermsAndConditions],
    support: [Keys.Legal, Keys.Support],
    cookieNotice: [Keys.Legal, Keys.CookieNotice],
  } as Record<string, QueryKey>,
  faq: {
    overview: [Keys.Faq] as QueryKey,
    byUID: (uid: string): QueryKey => [Keys.Faq, uid],
    all: [Keys.Faq, Keys.All],
  },
  contact: [Keys.Contact] as QueryKey,
  callInternational: [Keys.CallInternational] as QueryKey,
  testimonials: {
    all: (): QueryKey => [Keys.Testimonials],
  },
  optIn: {
    form: (): QueryKey => [Keys.OptIn],
  },
  paypalUrl: (purchaseId?: string) => [Keys.PaypalUrl, purchaseId] as QueryKey,
};

export default QueryKeys;
