import { isProd } from 'utils/env.utils';

import { OriginalException, SentryOptions } from './types';

export const SENTRY_OPTIONS: SentryOptions = {
  dsn: 'https://372dff61ef13435b83172bde0ce867e6@o190235.ingest.sentry.io/6768930',
  environment: process.env.NEXT_PUBLIC_BUILD_ENV,
  release: isProd()
    ? `${process.env.NEXT_PUBLIC_NAME}@${process.env.NEXT_PUBLIC_VERSION}`
    : process.env.GIT_COMMIT?.length
      ? `${process.env.NEXT_PUBLIC_NAME}@${process.env.NEXT_PUBLIC_VERSION}-${process.env.NEXT_PUBLIC_BUILD_ENV}-${process.env.GIT_COMMIT}`
      : `${process.env.NEXT_PUBLIC_NAME}@${process.env.NEXT_PUBLIC_VERSION}-${process.env.NEXT_PUBLIC_BUILD_ENV}`,
  beforeSend(event, hint) {
    const error = hint.originalException as OriginalException;
    if (error && error.message) {
      if (error.message.match(/hasLimitReached/i)) {
        return null;
      }
      if (
        error.message.match(
          /this suspense boundary received an update before it finished hydrating/i,
        )
      ) {
        return null;
      }
    }

    return event;
  },
  denyUrls: [
    // Google Adsense
    /pagead\/js/i,
    /pagead2\.googlesyndication\.com/i,
    // Facebook flakiness
    /graph\.facebook\.com/i,
    // Facebook blocked
    /connect\.facebook\.net\/en_US\/all\.js/i,
    // Chrome extensions
    /extensions\//i,
    /^chrome:\/\//i,
    // Other plugins
    /127\.0\.0\.1:4001\/isrunning/i, // Cacaoweb
    /webappstoolbarba\.texthelp\.com\//i,
    /metrics\.itunes\.apple\.com\.edgesuite\.net\//i,
    // ignore errors from scripts
    /onsite.optimonk.com/i,
    /avo.app/i,
    /posthog.com/i,
    /^app:\/\//i,
  ],
  ignoreErrors: [
    // Random plugins/extensions
    'top.GLOBALS',
    // See: http://blog.errorception.com/2012/03/tale-of-unfindable-js-error.html
    'originalCreateNotification',
    'canvas.contentDocument',
    'MyApp_RemoveAllHighlights',
    'http://tt.epicplay.com',
    "Can't find variable: ZiteReader",
    'jigsaw is not defined',
    'ComboSearch is not defined',
    'http://loading.retry.widdit.com/',
    'atomicFindClose',
    // Facebook borked
    'fb_xd_fragment',
    'fbq is not defined',
    // ISP "optimizing" proxy - `Cache-Control: no-transform` seems to reduce this. (thanks @acdha)
    // See http://stackoverflow.com/questions/4113268/how-to-stop-javascript-injection-from-vodafone-proxy
    'bmi_SafeAddOnload',
    'EBCallBackMessageReceived',
    // See http://toolbar.conduit.com/Developer/HtmlAndGadget/Methods/JSInjection.aspx
    'conduitPage',
    // Generic error code from errors outside the security sandbox
    // You can delete this if using raven.js > 1.0, which ignores these automatically.
    'Script error.',

    // errors from sentry that are not from our code
    "Can't find variable: _AutofillCallbackHandler",
    `Unexpected token 'j', "javascript"... is not valid JSON`,
    'Non-Error promise rejection captured with keys: revert, silent',
    `undefined is not an object (evaluating 'a.current.requestCode')`,
    'Load failed',
    `No mark named 'beforeRender' exists`,
    `Failed to execute 'insertBefore' on 'Node': The node before which the new node is to be inserted is not a child of this node.`,
    `Failed to execute 'measure' on 'Performance': The mark 'beforeRender' does not exist.`,
    "Cannot read properties of null (reading 'getItem')",
    'Object captured as promise rejection with keys: revert, silent',
    'Non-Error promise rejection captured with value: undefined',

    // see: https://www.reddit.com/r/nextjs/comments/yxa87v/im_glad_im_not_the_only_one_that_thinks_this/
    'This Suspense boundary received an update before it finished hydrating',
    // see: https://github.com/getsentry/sentry-javascript/issues/6295#issuecomment-1437100010 and https://github.com/getsentry/sentry-javascript/issues/6295#issuecomment-1518425078
    'https://reactjs.org/docs/error-decoder.html?invariant=423', // There was an error while hydrating.
    'https://reactjs.org/docs/error-decoder.html?invariant=425', // Text content does not match server-rendered HTML...
    'https://reactjs.org/docs/error-decoder.html?invariant=421', // This Suspense boundary received an update before it finished hydrating...
    // ChunkLoadErrors are only noise. After a new deploy, the old chunks on the server are no longer available and this error is thrown by browsers that have old chunks chached. It is not a 'real' problem and a simple reload will fix it.
    'ChunkLoadError',

    // Errors due to outdated browser version
    "Can't find variable: IntersectionObserver",

    // Errors caused by optimonk
    'timeout (waited 5000 ms)',

    // Security errors
    'Blocked a frame with origin "https://topup.digicelgroup.com" from accessing a cross-origin frame. Protocols, domains, and ports must match.',

    // Webview related errors
    "undefined is not an object (evaluating 'window.webkit.messageHandlers')",

    // Browser related errors
    'Permission denied to access property "dispatchEvent" on cross-origin object',
    'The operation is insecure.',

    // GTM related errors
    "undefined is not an object (evaluating 'b.container[a]')",
  ],
};
