import { AxiosInstance } from 'axios';

import authApi from './auth.api';
import ipApi from './ip.api';
import maintenanceApi from './maintenance.api';
import promotionApi from './promotion.api';
import riaApi from './ria.api';
import topupApi from './topup.api';
import transactionApi from './transaction.api';
import userApi from './user.api';

interface Options {
  api: AxiosInstance;
}

class RestApiService {
  public api: AxiosInstance;

  constructor(options: Options) {
    this.api = options.api;
  }

  // USER
  public register = userApi.register;
  public activateUser = userApi.activateUser;
  public forgotPassword = userApi.forgotPassword;
  public changePassword = userApi.changePassword;
  public getUserProfile = userApi.getUserProfile;
  public updateUserProfile = userApi.updateUserProfile;
  public signupGuest = userApi.signupGuest;

  // IP
  public getIpConfig = ipApi.getIpConfig;

  // AUTH
  public login = authApi.login;
  public socialLogin = authApi.socialLogin;
  public refresh = authApi.refresh;

  // TOP UP
  public getTopupCountries = topupApi.getAllCountries;
  public getTopupProducts = topupApi.getProducts;
  public getProductUpsell = topupApi.getProductUpsell;
  public getProductBonus = topupApi.getProductBonus;
  public validatePromo = topupApi.validatePromo;
  public purchase = topupApi.purchase;
  public purchasePaypal = topupApi.purchasePaypal;
  public purchaseDigitalWallet = topupApi.purchaseDigitalWallet;
  public merchantValidation = topupApi.merchantValidation;
  public getPurchaseStatus = topupApi.getPurchaseStatus;
  public getAutoPays = topupApi.getAutoPays;
  public removeAutoPay = topupApi.removeAutoPay;
  public removeSavedPayment = topupApi.removeSavedPayment;
  public getPaypalUrl = topupApi.getPaypalUrl;

  // TRANSACTIONS
  public getTransactions = transactionApi.getTransactions;
  public getTransaction = transactionApi.getTransaction;

  // PROMOTIONS
  public getPromotions = promotionApi.getPromotions;
  public getPromotion = promotionApi.getPromotion;

  // MAINTENANCE
  public getMaintenance = maintenanceApi.getMaintenance;

  // RIA
  public getRiaToken = riaApi.getRiaToken;
  public postRiaToken = riaApi.postRiaToken;
}

export default RestApiService;
