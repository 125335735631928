import { alpha, Components, menuItemClasses, Theme } from '@mui/material';

export const MuiMenuItem: Components<Theme>['MuiMenuItem'] = {
  styleOverrides: {
    root: ({ theme }) => ({
      wordWrap: 'break-word',
      whiteSpace: 'normal',
      [`&.${menuItemClasses.selected}`]: {
        backgroundColor: alpha(theme.palette.brand.darkBlue, 0.1),
        '&:focus': {
          backgroundColor: alpha(theme.palette.brand.darkBlue, 0.1),
        },
        '&:hover': {
          backgroundColor: alpha(theme.palette.brand.darkBlue, 0.2),
        },
      },
    }),
  },
};
