import {
  compile as regexpCompile,
  ParseOptions,
  TokensToFunctionOptions,
} from 'path-to-regexp';

const cache: { [key: string]: ReturnType<typeof regexpCompile> } = {};
const cacheLimit = 10000;
let cacheCount = 0;

function compilePath(
  path: string,
  options: ParseOptions & TokensToFunctionOptions = {},
) {
  // Keep cache of PathFunctions
  if (cache[path]) return cache[path];

  const generator = regexpCompile(path, options);

  if (cacheCount < cacheLimit) {
    // populate cache
    cache[path] = generator;
    cacheCount++;
  }

  return generator;
}

/**
 * Generates a valid path you can use for manually pushing to routes.
 * e.g. "/users/:id" > generatePath("/users/:id", { id: 1 }) > "/users/1"
 * e.g. "/users/[id]" > generatePath("/users/[id]", { id: 1 }) > "/users/1"
 */
export function generatePath(path = '/', params = {}) {
  return path === '/'
    ? path
    : compilePath(
        path
          .replace('[[', '[')
          .replace(']]', ']')
          .replace('...', '')
          .replace(/\[(\w+)\]/g, ':$1'),
      )(params);
}

type LocalePath = {
  params: { [key: string]: string | Array<string> | undefined };
  locale?: string;
};

export function enhancePathsWithLocales(
  paths: Array<{
    params: LocalePath['params'];
  }>,
  locales?: Array<string>,
): Array<LocalePath> {
  if (!locales) {
    return paths;
  }

  const localePaths: Array<LocalePath> = [];

  paths.forEach((p) => {
    locales
      .filter((l) => l !== 'default')
      .forEach((l) => {
        localePaths.push({ params: p.params, locale: l });
      });
  });

  return localePaths;
}
