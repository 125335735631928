import { Components, inputLabelClasses, Theme } from '@mui/material';

export const MuiInputLabel: Components<Theme>['MuiInputLabel'] = {
  styleOverrides: {
    root: ({ theme }) => ({
      position: 'relative',
      transform: 'none',
      marginBottom: 4,
      fontWeight: 700,
      color: theme.palette.brand.darkBlue,
      [`&.${inputLabelClasses.focused}`]: {
        color: theme.palette.brand.darkBlue,
      },
    }),
  },
};
