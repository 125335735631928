import { sendToGTM } from '@elgorditosalsero/react-gtm-hook';
import { posthog } from 'posthog-js';

import { CustomDestination } from './avo';
import { dataLayerName, eCommerceEvents } from './constants';

export const gaDestination: CustomDestination = {
  logEvent: (eventName, eventProperties) => {
    if (eCommerceEvents.includes(eventName)) {
      sendToGTM({ dataLayerName, data: { items: null } });
    }
    sendToGTM({
      dataLayerName,
      data: { event: eventName, ...eventProperties },
    });
  },
  // Nothing happening in these methods, ga4 does not have these types of events,
  // but they have to be here so no errors will be logged
  identify: (userId) => {
    sendToGTM({
      dataLayerName,
      data: { event: 'identify', userId },
    });
  },
  setUserProperties: (userId, userProperties) => {
    sendToGTM({
      dataLayerName,
      data: { event: 'update_user_properties', userId, ...userProperties },
    });
  },
};

export const posthogDestination: CustomDestination = {
  logEvent: (eventName, eventProperties) => {
    posthog.capture(eventName, eventProperties);
  },
  identify: (userId) => {
    posthog.identify(userId);
  },
  setUserProperties: (_, userProperties) => {
    posthog.setPersonProperties(userProperties);
  },
};
