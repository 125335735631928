import { Components, Theme } from '@mui/material';

export const MuiAvatar: Components<Theme>['MuiAvatar'] = {
  styleOverrides: {
    root: ({ ownerState, theme }) => ({
      ...(ownerState.variant === 'profile' && {
        fontSize: 26,
        fontWeight: 700,
        backgroundColor: theme.palette.support.neutral01,
        margin: '2px',
        border: '2px solid transparent',
        position: 'relative',
        boxSizing: 'border-box',
        color: theme.palette.brand.darkBlue,
        transition: 'none',
        borderRadius: '50%',
        overflow: 'visible',
        backgroundClip: 'padding-box',
        paddingTop: 4,
        '&:after': {
          position: 'absolute',
          content: '""',
          top: 0,
          right: 0,
          bottom: 0,
          left: 0,
          zIndex: -1,
          margin: '-3px',
          borderRadius: '50%',
          background: theme.palette.gradient.primary,
          backgroundClip: 'padding-box',
        },
      }),
    }),
  },
};
