import { useMultiStepActions } from './multistep';
import { usePurchaseActions } from './purchase';

export const useResetStore = () => {
  const { resetPurchase } = usePurchaseActions();
  const { reset } = useMultiStepActions();

  const resetStore = () => {
    resetPurchase();
    reset(true);
  };

  return resetStore;
};
