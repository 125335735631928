import { createIntl, createIntlCache, IntlShape } from 'react-intl';

import MESSAGES_EN from './translations/en.json';
import MESSAGES_ES from './translations/es.json';
import MESSAGES_FR from './translations/fr.json';
import MESSAGES_NL from './translations/nl.json';

const intlCache = createIntlCache();

const intlEN = createIntl(
  {
    locale: 'en',
    messages: MESSAGES_EN,
  },
  intlCache,
);

const intlFR = createIntl(
  {
    locale: 'fr',
    messages: MESSAGES_FR,
  },
  intlCache,
);

const intlES = createIntl(
  {
    locale: 'es',
    messages: MESSAGES_ES,
  },
  intlCache,
);

const intlNL = createIntl(
  {
    locale: 'nl',
    messages: MESSAGES_NL,
  },
  intlCache,
);

export const intl: { [key: string]: IntlShape } = {
  en: intlEN,
  fr: intlFR,
  es: intlES,
  nl: intlNL,
};
