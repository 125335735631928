import { Box } from '@mui/material';
import React, { PropsWithChildren } from 'react';

interface Props {
  fixedHeight?: boolean;
}

const PageContainer: React.FC<PropsWithChildren<Props>> = ({
  children,
  fixedHeight,
}) => {
  return (
    <Box
      minHeight="100svh"
      height={fixedHeight ? '100svh' : undefined}
      display="flex"
      flexDirection="column"
    >
      <Box
        component="main"
        flex="1 auto"
        bgcolor="common.white"
        color="common.black"
        display="flex"
        flexDirection="column"
      >
        {children}
      </Box>
    </Box>
  );
};

export default PageContainer;
