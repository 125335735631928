import { QueryClient, useQuery } from '@tanstack/react-query';

import QueryKeys from 'queries/QueryKeys';
import { StaleTime, UseCommonQueryOptions } from 'queries/types';
import { apiService } from 'services';
import { TopupCountry } from 'types/topup.types';

const getTopupCountries = async () => {
  const { data } = await apiService.getTopupCountries();

  return data.data;
};

export const prefetchTopupCountries = async (queryClient: QueryClient) => {
  await queryClient.prefetchQuery({
    queryKey: QueryKeys.topup.countries(),
    queryFn: () => getTopupCountries(),
  });
};

export const fetchTopupCountries = (queryClient: QueryClient) =>
  queryClient.ensureQueryData({
    queryKey: QueryKeys.topup.countries(),
    queryFn: () => getTopupCountries(),
  });

export const useTopupCountries = (
  config?: UseCommonQueryOptions<Array<TopupCountry>>,
) => {
  const {
    data,
    isLoading,
    error,
    refetch,
    isError,
    isFetching,
    isFetchedAfterMount,
  } = useQuery({
    queryKey: QueryKeys.topup.countries(),
    queryFn: getTopupCountries,
    staleTime: StaleTime.FIFTEEN_MIN,
    gcTime: StaleTime.ONE_HOUR,
    ...config,
  });

  return {
    data: data ?? [],
    isLoading,
    error,
    isError,
    isFetching,
    isFetchedAfterMount,
    refetch,
  };
};
