import { Components, containerClasses, Theme } from '@mui/material';

export const MuiContainer: Components<Theme>['MuiContainer'] = {
  styleOverrides: {
    root: ({ theme }) => ({
      [theme.breakpoints.up('xs')]: {
        paddingLeft: theme.spacing(6),
        paddingRight: theme.spacing(6),
        [`&.${containerClasses.disableGutters}`]: {
          paddingLeft: 0,
          paddingRight: 0,
        },
      },
      [theme.breakpoints.up('xl')]: {
        paddingLeft: 0,
        paddingRight: 0,
      },
    }),
    maxWidthSm: () => ({
      maxWidth: 502,
    }),
  },
};
