import { QueryClient } from '@tanstack/react-query';

import QueryKeys from 'queries/QueryKeys';
import { apiService } from 'services';

const getUserProfile = async () => {
  const { data } = await apiService.getUserProfile();
  return data;
};

export const fetchUserProfile = (queryClient: QueryClient) =>
  queryClient.ensureQueryData({
    queryKey: QueryKeys.user.profile(),
    queryFn: () => getUserProfile(),
  });
