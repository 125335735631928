import { Components, Theme } from '@mui/material';

export const MuiAlert: Components<Theme>['MuiAlert'] = {
  styleOverrides: {
    root: {
      fontSize: 14,
    },
    standard: {
      borderRadius: 0,
    },
    standardError: ({ theme }) => ({
      color: theme.palette.error.main,
    }),
    filled: {
      borderRadius: 4,
    },
    icon: {
      alignItems: 'center',
    },
  },
};
