export enum Routes {
  Root = '/[receiverCountry]/[[...senderCountry]]',
  Promotions = '/[receiverCountry]/[senderCountry]/promotions',
  PromotionDetail = '/promotions/[slug]',

  // auth
  Login = '/auth/login',
  Guest = '/auth/guest',
  Activate = '/auth/activate/[[...token]]',
  Register = '/auth/register',
  ForgotPassword = '/auth/forgot-password',
  ResetPassword = '/auth/reset-password/[[...token]]',
  AuthCallbackDigid = '/auth/callback-digid',

  // Payment
  PaymentPaypal = '/payment/paypal/[[...purchaseId]]',

  // Profile
  Profile = '/profile',
  Transactions = '/profile/transactions',
  TransactionDetail = '/profile/transactions/[transactionId]',
  AutoPays = '/profile/autopays',

  // RIA
  SendMoney = '/sendmoney',

  // Opt-In
  OptIn = '/opt-in',

  // Request or send topup
  RequestTopup = '/topup/request',
  SendTopup = '/topup/send',

  // Legal
  PrivacyPolicy = '/privacy-policy',
  TermsOfUse = '/terms-of-use',
  TermsAndConditions = '/terms-and-conditions',
  Support = '/support',
  CookieNotice = '/cookie-notice',

  //FAQ
  Faq = '/faq',

  // Sentoo
  Sentoo = '/sentoo/[market]',
  Resubmit = '/sentoo/[market]/resubmit',

  NotFound = '/404',
}
