import { isValid } from 'i18n-iso-countries';

export const isValidCountry = (countryCode: string) => {
  if (countryCode.length === 2) {
    return isValid(countryCode.toUpperCase());
  }

  return false;
};

// add more as needed
export enum CountryCodeLowercase {
  Jamaica = 'jm',
  TrinidadAndTobago = 'tt',
  SaintLucia = 'lc',
}

export const countryCurrency: Record<CountryCodeLowercase, string> = {
  [CountryCodeLowercase.Jamaica]: 'JMD',
  [CountryCodeLowercase.TrinidadAndTobago]: 'TTD',
  [CountryCodeLowercase.SaintLucia]: 'XCD',
};
