import { Components, Theme } from '@mui/material';

export const MuiButton: Components<Theme>['MuiButton'] = {
  defaultProps: {
    disableElevation: true,
    size: 'large',
    disableTouchRipple: true,
    color: 'secondary',
  },
  styleOverrides: {
    root: {
      fontSize: 16,
      borderRadius: 25,
      textTransform: 'none',
      fontWeight: 700,
      lineHeight: '24px',
    },
    sizeSmall: ({ theme }) => ({
      padding: theme.spacing(2, 6),
    }),
    containedSizeLarge: {
      padding: '6px 48px',
      height: 48,
    },
    outlinedSizeLarge: {
      padding: '6px 48px',
      height: 42,
    },
    outlinedSizeSmall: {
      padding: '5px 24px',
    },
    contained: ({ theme }) => ({
      background: theme.palette.gradient.primary,
      color: theme.palette.support.neutral01,
      '&:hover': {
        boxShadow: '0px 3px 10px rgba(183, 28, 93, 0.5)',
      },
      '&:disabled': {
        background: '#D9DAE2',
        color: theme.palette.support.neutral01,
      },
    }),
    outlinedSecondary: ({ theme }) => ({
      backgroundClip: 'padding-box',
      background: theme.palette.support.neutral01,
      border: 'none',
      position: 'relative',
      boxSizing: 'border-box',
      color: theme.palette.brand.darkBlue,
      transition: 'none',

      '&:before': {
        position: 'absolute',
        content: '""',
        top: -3,
        right: -3,
        bottom: -3,
        left: -3,
        zIndex: -1,
        borderRadius: 28,
        background: theme.palette.gradient.primary,
      },

      '&:hover': {
        background: theme.palette.support.neutral01,
        border: 'none',
        boxShadow: '0px 6px 10px rgba(183, 28, 93, 0.5)',
      },
      '&:disabled': {
        background: theme.palette.support.neutral01,
        border: 'none',
        color: '#D9DAE2',
        '&:after': {
          background: '#D9DAE2',
        },
      },
    }),
    outlinedError: ({ theme }) => ({
      border: `3px solid ${theme.palette.brand.red}`,
      color: theme.palette.brand.red,

      '&:hover': {
        border: `3px solid ${theme.palette.brand.red}`,
        background: theme.palette.support.neutral01,
      },
    }),
    text: ({ theme }) => ({
      padding: 0,
      '&:hover': {
        background: 'none',
        textDecoration: 'underline',
      },
      '&:disabled': {
        color: theme.palette.support.neutral05,
      },
    }),
    textSizeSmall: {
      padding: 0,
    },
  },
};
