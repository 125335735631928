import { Components, Theme } from '@mui/material';

export const MuiPaper: Components<Theme>['MuiPaper'] = {
  styleOverrides: {
    root: {
      zIndex: 0,
      borderRadius: 25,
    },
  },
};
