export type LoginParams = {
  username: string;
  password: string;
  activationUrl: string;
  inauthId?: string;
  remember?: boolean;
};

export type RegisterParams = {
  username: string;
  password: string;
  inauthId?: string;
  activationUrl: string;
};

export type SocialLoginParams = {
  token: string;
  provider:
    | 'google-authcode'
    | 'facebook'
    | 'mydigicel-access'
    | 'mydigicel-authcode';
  remember?: boolean;
  register?: boolean;
  inAuthId?: string;
  redirectUrl?: string;
};

export type RefreshParams = {
  refreshToken: string;
};

export type Tokens = Partial<
  Pick<TokenResponse, 'accessToken' | 'refreshToken'>
>;

export enum TokenStorage {
  RefreshToken = 'refreshToken',
  AccessToken = 'accessToken',
  Remember = 'remember',
  Authentication = 'Authentication',
}

export type TokenResponse = {
  tokenType: string;
  expiresIn: number;
  accessToken: string;
  refreshToken: string;
  csrfToken?: string;
};
