import { getCookie } from 'cookies-next';
import { useRouter } from 'next/router';
import { createContext, ReactNode, useEffect, useMemo, useState } from 'react';

import { isInIframe } from 'utils/device.utils';
import { prefixDigits } from 'utils/phone-input.utils';

import { Routes } from 'constants/routes.constants';
import {
  MDA_AUTHENTICATION_COOKIE,
  STORAGE_KEY_MDA,
  STORAGE_KEY_MDA_PHONE,
} from 'constants/storage.constants';
import { useQueryString } from 'hooks/useQueryString';

export const MdaContext = createContext<{
  phone?: string;
  auth?: string;
  resetPhone: () => void;
  isMda: boolean;
}>({ isMda: false, resetPhone: () => {} });

interface Props {
  children?: ReactNode;
}

const EXCLUDED_PATHS: Array<string> = [Routes.Sentoo, Routes.SendTopup];

export const MdaProvider = ({ children }: Props) => {
  const { query: routerQuery, replace, pathname, route } = useRouter();

  const [query] = useQueryString<{
    phone?: string;
    authCookie?: string;
  }>();

  const [savedPhoneValue, setSavedPhoneValue] = useState<
    string | null | undefined
  >(() => {
    if (typeof window !== 'undefined' && sessionStorage) {
      return sessionStorage.getItem(STORAGE_KEY_MDA_PHONE);
    }
  });

  const phoneValue = savedPhoneValue ?? query.phone;

  const authCookie =
    (getCookie(MDA_AUTHENTICATION_COOKIE) as string | undefined) ??
    query.authCookie;

  useEffect(() => {
    if (!EXCLUDED_PATHS.includes(route)) {
      if (routerQuery['phone']) {
        const { phone, authCookie, ...rest } = routerQuery;

        replace({ pathname, query: rest }, undefined, { shallow: true });
      }
    }
  }, [pathname, replace, route, routerQuery]);

  useEffect(() => {
    if (!EXCLUDED_PATHS.includes(route)) {
      if (!!query.phone) {
        const value = prefixDigits(query.phone.replace(/\s/g, ''));
        sessionStorage.setItem(STORAGE_KEY_MDA_PHONE, value);
        setSavedPhoneValue(value);
        sessionStorage.setItem(STORAGE_KEY_MDA, '1');
      } else if (isInIframe) {
        sessionStorage.setItem(STORAGE_KEY_MDA, '1');
      }
    }
  }, [query.phone, route]);

  const isMda = useMemo(() => {
    if (EXCLUDED_PATHS.includes(route)) {
      return false;
    }

    return !!phoneValue || isInIframe;
  }, [phoneValue, route]);

  const values = useMemo(
    () => ({
      phone: phoneValue
        ? prefixDigits(phoneValue.replace(/\s/g, ''))
        : undefined,
      auth: authCookie,
      resetPhone: () => {
        sessionStorage.removeItem(STORAGE_KEY_MDA_PHONE);
        setSavedPhoneValue(undefined);
      },
      isMda,
    }),
    [authCookie, isMda, phoneValue],
  );

  return <MdaContext.Provider value={values}>{children}</MdaContext.Provider>;
};
