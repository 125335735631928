const isClient = typeof navigator !== 'undefined';

const deviceType = () => {
  if (!isClient) return 'unknown';
  const ua = navigator.userAgent;
  if (/(tablet|ipad|playbook|silk)|(android(?!.*mobi))/i.test(ua)) {
    return 'tablet';
  } else if (
    /Mobile|Android|iP(hone|od)|IEMobile|BlackBerry|Kindle|Silk-Accelerated|(hpw|web)OS|Opera M(obi|ini)/.test(
      ua,
    )
  ) {
    return 'mobile';
  }
  return 'desktop';
};

export const isDesktop = () => {
  return deviceType() === 'desktop';
};

export const isIos = () => {
  if (isClient) {
    return /iPad|iPhone|iPod/i.test(navigator.userAgent);
  }
  return false;
};

export const isAndroid = () => {
  if (isClient) {
    return navigator.userAgent.toLowerCase().indexOf('android') > -1;
  }
  return false;
};

export const isInIframe =
  typeof window !== 'undefined' ? window.self !== window.top : false;
