import { Components, Theme } from '@mui/material';

export const MuiCheckbox: Components<Theme>['MuiCheckbox'] = {
  defaultProps: {
    disableRipple: true,
  },
  styleOverrides: {
    root: ({ theme }) => ({
      color: theme.palette.brand.darkBlue,
      padding: 12,

      '&& svg': {
        fontSize: 30,
      },
    }),
  },
};
