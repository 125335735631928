// Generated by Avo VERSION 124.42.0. You should never have to make changes to this file.
// If you find yourself in the situation where you have to edit the file please contact us at hi@avo.app.
// If you encounter a git conflict in this file run `avo pull` and it will be resolved automatically.
/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// fetch() polyfill
(function () {
  if (typeof window === 'undefined') {
    return;
  }
  var support = {
    searchParams: 'URLSearchParams' in self,
    iterable: 'Symbol' in self && 'iterator' in Symbol,
    blob:
      'FileReader' in self &&
      'Blob' in self &&
      (function () {
        try {
          new Blob();
          return true
        } catch (e) {
          return false
        }
      })(),
    formData: 'FormData' in self,
    arrayBuffer: 'ArrayBuffer' in self
  };

  function isDataView(obj: any) {
    return obj && DataView.prototype.isPrototypeOf(obj)
  }

  if (support.arrayBuffer) {
    var viewClasses = [
      '[object Int8Array]',
      '[object Uint8Array]',
      '[object Uint8ClampedArray]',
      '[object Int16Array]',
      '[object Uint16Array]',
      '[object Int32Array]',
      '[object Uint32Array]',
      '[object Float32Array]',
      '[object Float64Array]'
    ];

    var isArrayBufferView =
      ArrayBuffer.isView ||
      function (obj) {
        return obj && viewClasses.indexOf(Object.prototype.toString.call(obj)) > -1
      };
  }

  function normalizeName(name: any) {
    if (typeof name !== 'string') {
      name = String(name);
    }
    if (/[^a-z0-9\-#$%&'*+.^_`|~]/i.test(name)) {
      throw new TypeError('Invalid character in header field name')
    }
    return name.toLowerCase()
  }

  function normalizeValue(value: any) {
    if (typeof value !== 'string') {
      value = String(value);
    }
    return value
  }

  // Build a destructive iterator for the value list
  function iteratorFor(items: any) {
    var iterator: any = {
      next: function () {
        var value = items.shift();
        return { done: value === undefined, value: value }
      }
    };

    if (support.iterable) {
      iterator[Symbol.iterator] = function () {
        return iterator
      };
    }

    return iterator
  }

  function Headers(headers: any) {
    // @ts-ignore
    (this as any).map = {};

    if (headers instanceof Headers) {
      (headers as any).forEach(function (value: any, name: any) {
        // @ts-ignore
        this.append(name, value);
        // @ts-ignore
      }, this);
    } else if (Array.isArray(headers)) {
      headers.forEach(function (header: any) {
        // @ts-ignore
        this.append(header[0], header[1]);
        // @ts-ignore
      }, this);
    } else if (headers) {
      Object.getOwnPropertyNames(headers).forEach(function (name: any) {
        // @ts-ignore
        this.append(name, headers[name]);
        // @ts-ignore
      }, this);
    }
  }

  Headers.prototype.append = function (name: any, value: any) {
    name = normalizeName(name);
    value = normalizeValue(value);
    var oldValue = this.map[name];
    this.map[name] = oldValue ? oldValue + ', ' + value : value;
  };

  Headers.prototype['delete'] = function (name: any) {
    delete this.map[normalizeName(name)];
  };

  Headers.prototype.get = function (name: any) {
    name = normalizeName(name);
    return this.has(name) ? this.map[name] : null
  };

  Headers.prototype.has = function (name: any) {
    return this.map.hasOwnProperty(normalizeName(name))
  };

  Headers.prototype.set = function (name: any, value: any) {
    this.map[normalizeName(name)] = normalizeValue(value);
  };

  Headers.prototype.forEach = function (callback: any, thisArg: any) {
    for (var name in this.map) {
      if (this.map.hasOwnProperty(name)) {
        callback.call(thisArg, this.map[name], name, this);
      }
    }
  };

  Headers.prototype.keys = function () {
    var items: any = [];
    this.forEach(function (_value: any, name: any) {
      items.push(name);
    });
    return iteratorFor(items)
  };

  Headers.prototype.values = function () {
    var items: any = [];
    this.forEach(function (value: any) {
      items.push(value);
    });
    return iteratorFor(items)
  };

  Headers.prototype.entries = function () {
    var items: any = [];
    this.forEach(function (value: any, name: any) {
      items.push([name, value]);
    });
    return iteratorFor(items)
  };

  if (support.iterable) {
    Headers.prototype[Symbol.iterator] = Headers.prototype.entries;
  }

  function consumed(body: any) {
    if (body.bodyUsed) {
      return true;
    }
    body.bodyUsed = true;
    return false;
  }

  function fileReaderReady(reader: any) {
    return new Promise(function (resolve: any, reject: any) {
      reader.onload = function () {
        resolve(reader.result);
      };
      reader.onerror = function () {
        reject(reader.error);
      };
    })
  }

  function readBlobAsArrayBuffer(blob: any) {
    var reader = new FileReader();
    var promise = fileReaderReady(reader);
    reader.readAsArrayBuffer(blob);
    return promise
  }

  function readBlobAsText(blob: any) {
    var reader = new FileReader();
    var promise = fileReaderReady(reader);
    reader.readAsText(blob);
    return promise
  }

  function readArrayBufferAsText(buf: any) {
    var view = new Uint8Array(buf);
    var chars = new Array(view.length);

    for (var i = 0; i < view.length; i++) {
      chars[i] = String.fromCharCode(view[i]!);
    }
    return chars.join('')
  }

  function bufferClone(buf: any) {
    if (buf.slice) {
      return buf.slice(0)
    } else {
      var view = new Uint8Array(buf.byteLength);
      view.set(new Uint8Array(buf));
      return view.buffer
    }
  }

  function Body() {
    // @ts-ignore
    (this as any).bodyUsed = false;

    // @ts-ignore
    (this as any)._initBody = function (body: any) {
      this._bodyInit = body;
      if (!body) {
        this._bodyText = '';
      } else if (typeof body === 'string') {
        this._bodyText = body;
      } else if (support.blob && Blob.prototype.isPrototypeOf(body)) {
        this._bodyBlob = body;
      } else if (support.formData && FormData.prototype.isPrototypeOf(body)) {
        this._bodyFormData = body;
      } else if (support.searchParams && URLSearchParams.prototype.isPrototypeOf(body)) {
        this._bodyText = body.toString();
      } else if (support.arrayBuffer && support.blob && isDataView(body)) {
        this._bodyArrayBuffer = bufferClone(body.buffer);
        // IE 10-11 can't handle a DataView body.
        this._bodyInit = new Blob([this._bodyArrayBuffer]);
      } else if (support.arrayBuffer && (ArrayBuffer.prototype.isPrototypeOf(body) || isArrayBufferView(body))) {
        this._bodyArrayBuffer = bufferClone(body);
      } else {
        this._bodyText = body = Object.prototype.toString.call(body);
      }

      if (!this.headers.get('content-type')) {
        if (typeof body === 'string') {
          this.headers.set('content-type', 'text/plain;charset=UTF-8');
        } else if (this._bodyBlob && this._bodyBlob.type) {
          this.headers.set('content-type', this._bodyBlob.type);
        } else if (support.searchParams && URLSearchParams.prototype.isPrototypeOf(body)) {
          this.headers.set('content-type', 'application/x-www-form-urlencoded;charset=UTF-8');
        }
      }
    };

    if (support.blob) {
      // @ts-ignore
      (this as any).blob = function () {
        var rejected = consumed(this);
        if (rejected) {
          return Promise.reject(new TypeError('Already read'));
        }

        if (this._bodyBlob) {
          return Promise.resolve(this._bodyBlob);
        } else if (this._bodyArrayBuffer) {
          return Promise.resolve(new Blob([this._bodyArrayBuffer]));
        } else if (this._bodyFormData) {
          throw new Error('could not read FormData body as blob');
        } else {
          return Promise.resolve(new Blob([this._bodyText]));
        }
      };

      // @ts-ignore
      (this as any).arrayBuffer = function () {
        if (this._bodyArrayBuffer) {
          if (consumed(this)) {
            return Promise.reject(new TypeError('Already read'));
          } else {
            return Promise.resolve(this._bodyArrayBuffer);
          }
        } else {
          return this.blob().then(readBlobAsArrayBuffer);
        }
      };
    }

    // @ts-ignore
    (this as any).text = function () {
      var rejected = consumed(this);
      if (rejected) {
        return Promise.reject(new TypeError('Already read'));
      }

      if (this._bodyBlob) {
        return readBlobAsText(this._bodyBlob);
      } else if (this._bodyArrayBuffer) {
        return Promise.resolve(readArrayBufferAsText(this._bodyArrayBuffer));
      } else if (this._bodyFormData) {
        throw new Error('could not read FormData body as text');
      } else {
        return Promise.resolve(this._bodyText);
      }
    };

    if (support.formData) {
      // @ts-ignore
      (this as any).formData = function () {
        return this.text().then(decode)
      };
    }

    // @ts-ignore
    (this as any).json = function () {
      return this.text().then(JSON.parse)
    };

    // @ts-ignore
    return this
  }

  // HTTP methods whose capitalization should be normalized
  var methods = ['DELETE', 'GET', 'HEAD', 'OPTIONS', 'POST', 'PUT'];

  function normalizeMethod(method: any) {
    var upcased = method.toUpperCase();
    return methods.indexOf(upcased) > -1 ? upcased : method
  }

  function Request(input: any, options: any) {
    options = options || {};
    var body = options.body;

    if (input instanceof Request) {
      if ((input as any).bodyUsed) {
        throw new TypeError('Already read')
      }
      // @ts-ignore
      (this as any).url = (input as any).url;
      // @ts-ignore
      this.credentials = (input as any).credentials;
      if (!options.headers) {
        // @ts-ignore
        this.headers = new Headers((input as any).headers);
      }
      // @ts-ignore
      this.method = (input as any).method;
      // @ts-ignore
      this.mode = (input as any).mode;
      // @ts-ignore
      this.signal = (input as any).signal;
      if (!body && (input as any)._bodyInit != null) {
        body = (input as any)._bodyInit;
        (input as any).bodyUsed = true;
      }
    } else {
      // @ts-ignore
      this.url = String(input);
    }

    // @ts-ignore
    this.credentials = options.credentials || this.credentials || 'same-origin';
    // @ts-ignore
    if (options.headers || !this.headers) {
      // @ts-ignore
      this.headers = new Headers(options.headers);
    }
    // @ts-ignore
    this.method = normalizeMethod(options.method || this.method || 'GET');
    // @ts-ignore
    this.mode = options.mode || this.mode || null;
    // @ts-ignore
    this.signal = options.signal || this.signal;
    // @ts-ignore
    this.referrer = null;

    // @ts-ignore
    if ((this.method === 'GET' || this.method === 'HEAD') && body) {
      throw new TypeError('Body not allowed for GET or HEAD requests')
    }
    // @ts-ignore
    this._initBody(body);
  }

  Request.prototype.clone = function () {
    // @ts-ignore
    return new Request(this, { body: this._bodyInit })
  };

  function decode(body: any) {
    var form = new FormData();
    body
      .trim()
      .split('&')
      .forEach(function (bytes: any) {
        if (bytes) {
          var split = bytes.split('=');
          var name = split.shift().replace(/\+/g, ' ');
          var value = split.join('=').replace(/\+/g, ' ');
          form.append(decodeURIComponent(name), decodeURIComponent(value));
        }
      });
    return form
  }

  function parseHeaders(rawHeaders: any) {
    // @ts-ignore
    var headers = new Headers();
    // Replace instances of \r\n and \n followed by at least one space or horizontal tab with a space
    // https://tools.ietf.org/html/rfc7230#section-3.2
    var preProcessedHeaders = rawHeaders.replace(/\r?\n[\t ]+/g, ' ');
    preProcessedHeaders.split(/\r?\n/).forEach(function (line: any) {
      var parts = line.split(':');
      var key = parts.shift().trim();
      if (key) {
        var value = parts.join(':').trim();
        headers.append(key, value);
      }
    });
    return headers
  }

  Body.call(Request.prototype);

  function Response(bodyInit: any, options: any) {
    if (!options) {
      options = {};
    }

    // @ts-ignore
    this.type = 'default';
    // @ts-ignore
    this.status = options.status === undefined ? 200 : options.status;
    // @ts-ignore
    this.ok = this.status >= 200 && this.status < 300;
    // @ts-ignore
    this.statusText = 'statusText' in options ? options.statusText : 'OK';
    // @ts-ignore
    this.headers = new Headers(options.headers);
    // @ts-ignore
    this.url = options.url || '';
    // @ts-ignore
    this._initBody(bodyInit);
  }

  Body.call(Response.prototype);

  Response.prototype.clone = function () {
    // @ts-ignore
    return new Response(this._bodyInit, {
      status: this.status,
      statusText: this.statusText,
      // @ts-ignore
      headers: new Headers(this.headers),
      url: this.url
    })
  };

  Response.error = function () {
    // @ts-ignore
    var response = new Response(null, { status: 0, statusText: '' });
    response.type = 'error';
    return response
  };

  var redirectStatuses = [301, 302, 303, 307, 308];

  Response.redirect = function (url: any, status: any) {
    if (redirectStatuses.indexOf(status) === -1) {
      throw new RangeError('Invalid status code')
    }

    // @ts-ignore
    return new Response(null, { status: status, headers: { location: url } })
  };

  (self as any).DOMException = (self as any).DOMException;
  try {
    new (self as any).DOMException();
  } catch (err) {
    (self as any).DOMException = function (message: any, name: any) {
      this.message = message;
      this.name = name;
      var error = Error(message);
      this.stack = error.stack;
    };
    (self as any).DOMException.prototype = Object.create(Error.prototype);
    (self as any).DOMException.prototype.constructor = (self as any).DOMException;
  }

  function fetch(input: any, init: any) {
    return new Promise(function (resolve, reject) {
      // @ts-ignore
      var request = new Request(input, init);

      if (request.signal && request.signal.aborted) {
        return reject(new (self as any).DOMException('Aborted', 'AbortError'))
      }

      var xhr = new XMLHttpRequest();

      function abortXhr() {
        xhr.abort();
      }

      xhr.onload = function () {
        var options = {
          status: xhr.status,
          statusText: xhr.statusText,
          headers: parseHeaders(xhr.getAllResponseHeaders() || '')
        };
        (options as any).url = 'responseURL' in xhr ? xhr.responseURL : options.headers.get('X-Request-URL');
        var body = 'response' in xhr ? xhr.response : (xhr as any).responseText;
        // @ts-ignore
        resolve(new Response(body, options));
      };

      xhr.onerror = function () {
        reject(new TypeError('Network request failed'));
      };

      xhr.ontimeout = function () {
        reject(new TypeError('Network request failed'));
      };

      xhr.onabort = function () {
        reject(new (self as any).DOMException('Aborted', 'AbortError'));
      };

      xhr.open(request.method, request.url, true);

      if (request.credentials === 'include') {
        xhr.withCredentials = true;
      } else if (request.credentials === 'omit') {
        xhr.withCredentials = false;
      }

      if ('responseType' in xhr && support.blob) {
        xhr.responseType = 'blob';
      }

      request.headers.forEach(function (value: any, name: any) {
        xhr.setRequestHeader(name, value);
      });

      if (request.signal) {
        request.signal.addEventListener('abort', abortXhr);

        xhr.onreadystatechange = function () {
          // DONE (success or failure)
          if (xhr.readyState === 4) {
            request.signal.removeEventListener('abort', abortXhr);
          }
        };
      }

      xhr.send(typeof request._bodyInit === 'undefined' ? null : request._bodyInit);
    })
  }

  fetch.polyfill = true;

  if (!self.fetch) {
    (self as any).fetch = fetch;
    (self as any).Headers = Headers;
    (self as any).Request = Request;
    (self as any).Response = Response;
  }
})();

export enum AvoEnv {
  Prod = "prod",
  Staging = "staging",
  Dev = "dev",
}

export interface CustomDestination {
  make?(env: AvoEnv, apiKey: string): void;
  logEvent?: (eventName: string, eventProperties: Record<string, any>) => void;
  setUserProperties?: (userId: string, userProperties: Record<string, any>) => void;
  identify?: (userId: string) => void;
  unidentify?: () => void;
  logPage?: (pageName: string, eventProperties: Record<string, any>) => void;
  revenue?: (amount: number, eventProperties: Record<string, any>) => void;
  setGroupProperties?: (
    groupType: string,
    groupId: string,
    groupProperties: Record<string, any>,
  ) => void;
  addCurrentUserToGroup?: (
    groupType: string,
    groupId: string,
    groupProperties: Record<string, any>,
  ) => void;
  logEventWithGroups?: (
    eventName: string,
    eventProperties: any,
    groupTypesToGroupIds: Record<string, string>,
  ) => void;
}

// @ts-ignore
interface AvoAssertMessage {
  eventName?: string;
  tag?: string;
  propertyId?: string;
  message?: string;
  additionalProperties?: string[],
  shape?: any,
  shapeUserProps?: any,
  actualType?: string
}

let __AVO_ENV__: AvoEnv | null = null;
// @ts-ignore
let __AVO_NOOP__: boolean = false;
// @ts-ignore
let __AVO_LOGGER__: AvoLogger | null = null;
// @ts-ignore
let __STRICT__: boolean | null = null;
// @ts-ignore
let __REPORT_FAILURE_AS__: 'error' | 'warn' | 'log' | null = null;

// @ts-ignore
let __WEB_DEBUGGER__: boolean = true;
export const avoInspectorApiKey = "39ub1Z4Qa5sYATtV0tHG";
// @ts-ignore
interface AvoInspector {}
let __INSPECTOR__: AvoInspector | null = null;


// polyfill Object.assign
// @ts-ignore
declare interface ObjectConstructor {
  assign: any;
}
// @ts-ignore
if (typeof Object.assign !== 'function') {
  // Must be writable: true, enumerable: false, configurable: true
  Object.defineProperty(Object, "assign", {
    value: function assign(target: any, _varArgs: any) { // .length of function is 2
      if (target == null) { // TypeError if undefined or null
        throw new TypeError('Cannot convert undefined or null to object');
      }

      let to = Object(target);

      for (let index = 1; index < arguments.length; index++) {
        let nextSource = arguments[index];

        if (nextSource != null) { // Skip over if undefined or null
          for (let nextKey in nextSource) {
            // Avoid bugs when hasOwnProperty is shadowed
            if (Object.prototype.hasOwnProperty.call(nextSource, nextKey)) {
              to[nextKey] = nextSource[nextKey];
            }
          }
        }
      }
      return to;
    },
    writable: true,
    configurable: true
  });
}

interface AvoLogger {
  logDebug(env: AvoEnv | null, message: string): boolean;
  logWarn(env: AvoEnv | null, message: string): boolean;
  logError(env: AvoEnv | null, error: string): boolean;
}

enum webDebuggerArea {
  BottomRight = "BottomRight",
  BottomLeft = "BottomLeft",
  TopRight = "TopRight",
  TopLeft = "TopLeft"
}

interface bottomRightParameters {
  bottom: number;
  right: number;
}

interface bottomLeftParameters {
  bottom: number;
  left: number;
}

interface topRightParameters {
  top: number;
  right: number;
}

interface topLeftParameters {
  top: number;
  left: number;
}

interface webDebuggerPosition {
  position: webDebuggerArea;
  top?: number;
  right?: number;
  bottom?: number;
  left?: number;
}

interface webDebuggerOptions {
  position?: webDebuggerPosition;
}

interface WebDebuggerPositionSetter {
  BottomRight(p: bottomRightParameters): webDebuggerPosition;
  BottomLeft(p: bottomLeftParameters): webDebuggerPosition;
  TopRight(p: topRightParameters): webDebuggerPosition;
  TopLeft(p: topLeftParameters): webDebuggerPosition;
}

export const WebDebuggerPosition: WebDebuggerPositionSetter = {
  BottomRight: ({ bottom, right }) => ({
    position: webDebuggerArea.BottomRight,
    bottom,
    right,
  }),
  BottomLeft: ({ bottom, left }) => ({
    position: webDebuggerArea.BottomLeft,
    bottom,
    left,
  }),
  TopRight: ({ top, right }) => ({
    position: webDebuggerArea.TopRight,
    top,
    right,
  }),
  TopLeft: ({ top, left }) => ({
    position: webDebuggerArea.TopLeft,
    top,
    left,
  }),
}


let InternalAvoLogger: any = {
  logEventSent: function logEventSent(eventName: string, eventProperties: any, userProperties: any) {
    const message = "Event Sent:" + eventName + "Event Props:" + JSON.stringify(eventProperties) + "User Props:" + JSON.stringify(userProperties);

    if (__AVO_LOGGER__ && __AVO_LOGGER__.logDebug && __AVO_LOGGER__.logDebug(__AVO_ENV__, message)) {
      return
    }
    typeof console !== 'undefined' && console.log("[avo] Event Sent:", eventName, "Event Props:", eventProperties, "User Props:", userProperties);
  },

  log: function log(message: string) {
    if (__AVO_LOGGER__ && __AVO_LOGGER__.logDebug && __AVO_LOGGER__.logDebug(__AVO_ENV__, message)) {
      return
    }
    typeof console !== 'undefined' && console.log("[avo] " + message);
  },

  warn: function warn(message: string) {
    if (__AVO_LOGGER__ && __AVO_LOGGER__.logWarn && __AVO_LOGGER__.logWarn(__AVO_ENV__, message)) {
      return
    }
    typeof console !== 'undefined' && console.warn("[avo] " + message);
  },

  error: function error(message: string, error: string) {
    if (__AVO_LOGGER__ && __AVO_LOGGER__.logError && __AVO_LOGGER__.logError(__AVO_ENV__, message + error)) {
      return
    }
    typeof console !== 'undefined' && console.error("[avo] " + message, error);
  }
};

function convertPropertiesArrayToMap(propertiesArray: [{id: string, name: string, value: string}]): {string: string | null | undefined} {
    let result: {string: string} = {}

    propertiesArray.forEach(value => {
      result[value.name] = value.value
    })

    return result
}

// @ts-ignore
let array_difference: any;
// @ts-ignore
let AvoAssert: any;
array_difference = function array_difference(a1: any[], a2: any[]) {
  let result: any[] = [];
  for (let i = 0; i < a1.length; i++) {
    if (a2.indexOf(a1[i]) === -1) {
      result.push(a1[i]);
    }
  }
  return result;
}

AvoAssert = {
  assertObject: function assertObject(propertyId: string, propName: string, obj: any) {
    if (typeof obj !== 'object') {
      let message = propName +
          ' should be of type object but you provided type ' +
          typeof obj +
          ' with value ' +
          JSON.stringify(obj);
      return [{tag: 'expectedObjectType', propertyId, message, actualType: typeof obj}];
    } else {
      return [];
    }
  },

  assertString: function assertString(propertyId: string, propName: string, str: string) {
    if (typeof str !== 'string') {
      let message = propName +
          ' should be of type string but you provided type ' +
          typeof str +
          ' with value ' +
          JSON.stringify(str);
      return [{tag: 'expectedStringType', propertyId, message, actualType: typeof str}];
    } else {
      return [];
    }
  },

  assertInt: function assertInt(propertyId: string, propName: string, int: number) {
    if (typeof int === 'number' && int !== Math.round(int)) {
      let message = propName +
          ' should be of type int but you provided type float with value ' +
          JSON.stringify(int);
      return [{tag: 'expectedIntType', propertyId, message, actualType: 'float'}];
    } else if (typeof int !== 'number') {
      let message = propName +
          ' should be of type int but you provided type ' +
          typeof int +
          ' with value ' +
          JSON.stringify(int);
      return [{tag: 'expectedIntType', propertyId, message, actualType: typeof int}];
    } else {
      return [];
    }
  },

  assertLong: function assertLong(propertyId: string, propName: string, long: number) {
    if (typeof long === 'number' && long !== Math.round(long)) {
      let message = propName +
          ' should be of type long but you provided type float with value ' +
          JSON.stringify(long);
      return [{tag: 'expectedLongType', propertyId, message, actualType: 'float'}];
    } else if (typeof long !== 'number') {
      let message = propName +
          ' should be of type long but you provided type ' +
          typeof long +
          ' with value ' +
          JSON.stringify(long);
      return [{tag: 'expectedLongType', propertyId, message, actualType: typeof long}];
    } else {
      return [];
    }
  },

  assertFloat: function assertFloat(propertyId: string, propName: string, float: number) {
    if (typeof float !== 'number') {
      let message = propName +
          ' should be of type float but you provided type ' +
          typeof float +
          ' with value ' +
          JSON.stringify(float);
      return [{tag: 'expectedFloatType', propertyId, message, actualType: typeof float}];
    } else {
      return [];
    }
  },

  assertBool: function assertBool(propertyId: string, propName: string, bool: boolean) {
    if (typeof bool !== 'boolean') {
      let message = propName +
          ' should be of type boolean but you provided type ' +
          typeof bool +
          ' with value ' +
          JSON.stringify(bool);
      return [{tag: 'expectedBoolType', propertyId, message, actualType: typeof bool}];
    } else {
      return [];
    }
  },

  assertMax: function assertMax(
    propertyId: string,
    propName: string,
    max: number,
    value: number
  ) {
    if (value > max) {
      let message = propName +
        ' has a maximum value of ' +
        max +
        ' but you provided the value ' +
        JSON.stringify(value);
      return [{tag: 'expectedMax', propertyId, message}];
    } else {
      return [];
    }
  },

  assertMin: function assertMin(
    propertyId: string,
    propName: string,
    min: number,
    value: number
  ) {
    if (value < min) {
      let message = propName +
        ' has a minimum value of ' +
        min +
        ' but you provided the value ' +
        JSON.stringify(value);
      return [{tag: 'expectedMin', propertyId, message}];
    } else {
      return [];
    }
  },

  assertList: function assertList(propertyId: string, propName: string, value: any) {
    if (!Array.isArray(value)) {
      let message = propName + ' should be of type list but you provided type ' + typeof value;
      return [{tag: 'expectedList', propertyId, message}];
    } else {
      return [];
    }
  },

  assertNoAdditionalProperties: function assertNoAdditionalProperties(eventName: string, input: string[], spec: string[]) {
    let additionalKeys = array_difference(input, spec);
    if (additionalKeys.length) {
      let message = "Additional properties when sending event " + eventName + ": " + JSON.stringify(additionalKeys);
      return [{tag: 'expectedNoAdditionalProperties', additionalProperties: additionalKeys, message: message}];
    } else {
      return [];
    }
  },

  assertNoAdditionalUserProperties: function assertNoAdditionalProperties(eventName: string, input: string[], spec: string[]) {
    let additionalKeys = array_difference(input, spec);
    if (additionalKeys.length) {
      let message = "Additional user properties when sending event " + eventName + ": " + JSON.stringify(additionalKeys);
      return [{tag: 'expectedNoAdditionalUserProperties', additionalProperties: additionalKeys, message: message}];
    } else {
      return [];
    }
  },
};

let _avo_invoke: any;
let _avo_invoke_meta: any;
let _avo_sampling_rate = 1.0;
_avo_invoke = function _avo_invoke(env: AvoEnv, eventId: string, hash: string, messages: {tag: string, propertyId: string}[], origin: string) {
  // @ts-ignore
  if (typeof (window as any) === 'undefined') { return; }
  if (_avo_sampling_rate > 0) {
    if (Math.random() < _avo_sampling_rate) {
      // @ts-ignore
      fetch("https://api.avo.app/i", {
        method: "POST",
        headers: {"Content-Type": "application/json"},
        body: JSON.stringify({
          "ac": "ls1Up8l1dRAGdRIbLvsC",
          "br": "lE4oRzTE7",
          "en": env,
          "ev": eventId,
          "ha": hash,
          "sc": "Vw4DbNQPc4e5WGVEh2nX",
          "se": (new Date()).toISOString(),
          "so": "ReJjcGBLZ",
          "va": messages.length === 0,
          "me": messages,
          "or": origin
        })
      }).then(function(res: any) { return res.json(); }).then(function(data: any) { _avo_sampling_rate = data.sa; }).catch(function() {});
    }
  }
}

_avo_invoke_meta = function _avo_invoke_meta(env: AvoEnv, type: string, messages: {tag: string, propertyId: string}[], origin: string) {
  // @ts-ignore
  if (typeof (window as any) === 'undefined') { return; }
  if (_avo_sampling_rate > 0) {
    if (Math.random() < _avo_sampling_rate) {
      // @ts-ignore
      fetch("https://api.avo.app/i", {
        method: "POST",
        headers: {"Content-Type": "application/json"},
        body: JSON.stringify({
          "ac": "ls1Up8l1dRAGdRIbLvsC",
          "br": "lE4oRzTE7",
          "en": env,
          "ty": type,
          "sc": "Vw4DbNQPc4e5WGVEh2nX",
          "se": (new Date()).toISOString(),
          "so": "ReJjcGBLZ",
          "va": messages.length === 0,
          "me": messages,
          "or": origin
        })
      }).then(function(res: any) { return res.json(); }).then(function(data: any) { _avo_sampling_rate = data.sa; }).catch(function() {});
    }
  }
}


let _avo_debugger_log: any;
let _avo_debugger_events_during_boot: any = [];
let _avo_debugger_ready = false;

if (typeof (window as any) !== 'undefined') {
  window.addEventListener("message", function(event) {
    if (event.origin !== "https://www.avo.app") {
      return;
    }
    let iframe: any = document.getElementById("avo-debugger");
    if (iframe && event && event.data && event.data.type_ === "avo-debugger-update-style") {
      iframe.style = event.data.style;
    } else if (iframe && event && event.data && event.data.type_ === "avo-debugger-ready") {
      let message = {
        type_: "avo-debugger-boot-events",
        schemaId: "Vw4DbNQPc4e5WGVEh2nX",
        href: window.location.href,
        events: _avo_debugger_events_during_boot
      };
      _avo_debugger_events_during_boot = [];
      _avo_debugger_ready = true;
      iframe.contentWindow.postMessage(message, "https://www.avo.app/_debugger")
    }
  });
}

_avo_debugger_log = function _avo_debugger_log(eventId: string, eventName: string, messages: any[], eventProperties: any[], userProperties: any[], groupProperties: any[]) {
  if (typeof (window as any) === 'undefined') { return; }
  let event = {
    eventId: eventId,
    eventName: eventName,
    messages: messages,
    timestamp: Date.now(),
    eventProperties,
    userProperties,
    groupProperties
  };

  if (_avo_debugger_ready) {
    let message = {type_: "avo-debugger-events", events: [event]};
    (document.getElementById("avo-debugger") as any).contentWindow.postMessage(message, "https://www.avo.app/_debugger")
  } else {
    _avo_debugger_events_during_boot.push(event);
  }
}

function _avo_debugger_send_position(position: webDebuggerPosition) {
  if (typeof window === 'undefined') { return; }
  window.addEventListener("message", function(event) {
    if (event.origin !== "https://www.avo.app") {
      return;
    }
    if (event.data.type_ === "avo-debugger-ready" && position !== null) {
      var message = {type_: "avo-debugger-position", position: position};
      document.getElementById("avo-debugger").contentWindow.postMessage(message, "https://www.avo.app/_debugger")
    }
  })
}


export interface Items {
  itemId: string
  itemName: string
  price: number
  preSelected: boolean
  index: number
  itemCategory: ItemCategoryValueType | null | undefined
  itemCategory2: ItemCategory2ValueType | null | undefined
  itemListName: ItemListNameValueType
  itemListId: string
}

export const Platform = {
  'WEBSITE': 'website',
  'APP': 'app',
  'AUTOPAY': 'autopay',
  'MYDIGICELAPP': 'mydigicelapp',
} as const;
export type PlatformType = typeof Platform;
export type PlatformValueType = PlatformType[keyof PlatformType];

export const Button = {
  'REMOVE_FREQUENT_PAYMENT': 'remove frequent payment',
} as const;
export type ButtonType = typeof Button;
export type ButtonValueType = ButtonType[keyof ButtonType];

export const Status = {
  'SUCCESSFUL': 'successful',
  'IN_PROGRESS': 'in-progress',
  'FAILED': 'failed',
} as const;
export type StatusType = typeof Status;
export type StatusValueType = StatusType[keyof StatusType];

export const ErrorCategory = {
  'LOGIN': 'login',
  'REGISTER': 'register',
  'PAYMENT': 'payment',
  'GUEST': 'guest',
  'TRANSACTION': 'transaction',
} as const;
export type ErrorCategoryType = typeof ErrorCategory;
export type ErrorCategoryValueType = ErrorCategoryType[keyof ErrorCategoryType];

export const Method = {
  'EMAIL': 'Email',
  'GOOGLE': 'Google',
  'FACEBOOK': 'Facebook',
  'APPLE': 'Apple',
} as const;
export type MethodType = typeof Method;
export type MethodValueType = MethodType[keyof MethodType];

export const ItemCategory2 = {
  'STAY_CONNECTED': 'Stay Connected',
  'STANDARD': 'Standard',
} as const;
export type ItemCategory2Type = typeof ItemCategory2;
export type ItemCategory2ValueType = ItemCategory2Type[keyof ItemCategory2Type];

export const CustomerType = {
  'DOMESTIC': 'domestic',
  'INTERNATIONAL': 'international',
} as const;
export type CustomerTypeType = typeof CustomerType;
export type CustomerTypeValueType = CustomerTypeType[keyof CustomerTypeType];

export const ItemListName = {
  'TRANSACTION_HISTORY_HOME': 'transaction history home',
  'TRANSACTION_HISTORY': 'transaction history',
  'PRODUCT_SELECTION': 'product selection',
  'REQUEST_TOPUP': 'request topup',
  'SEND_TOPUP': 'send topup',
} as const;
export type ItemListNameType = typeof ItemListName;
export type ItemListNameValueType = ItemListNameType[keyof ItemListNameType];

export const Origin = {
  'MDA': 'mda',
  'PROMOTION_DETAILS': 'promotion details',
  'HOME_NEW_TOPUP': 'home new topup',
  'TRANSACTION_HISTORY_DETAIL': 'transaction history detail',
  'TRANSACTION_HISTORY_OVERVIEW': 'transaction history overview',
  'HOME_TRANSACTION_HISTORY': 'home transaction history',
  'PROMOTION_OVERVIEW': 'promotion overview',
  'FREQUENT_PAYMENTS': 'frequent payments',
  'REQUEST': 'request',
} as const;
export type OriginType = typeof Origin;
export type OriginValueType = OriginType[keyof OriginType];

export const ItemCategory = {
  'PLAN': 'plan',
  'TOPUP': 'topup',
  'ADDON': 'addon',
} as const;
export type ItemCategoryType = typeof ItemCategory;
export type ItemCategoryValueType = ItemCategoryType[keyof ItemCategoryType];

export const CreativeSlot = {
  'UPSELL': 'upsell',
  'PROMOTION_OVERVIEW': 'promotion overview',
  'PROMOTION_DETAIL': 'promotion detail',
} as const;
export type CreativeSlotType = typeof CreativeSlot;
export type CreativeSlotValueType = CreativeSlotType[keyof CreativeSlotType];

export const PaymentType = {
  'VISA': 'Visa',
  'MASTERCARD': 'Mastercard',
  'MAESTRO': 'Maestro',
  'DINERS': 'Diners',
  'DISCOVER': 'Discover',
  'NCBKEYCARD': 'NCBKeycard',
  'PAY_PAL': 'PayPal',
  'AMEX': 'Amex',
  'ALI_PAY': 'AliPay',
  'UNION_PAY': 'UnionPay',
  'SAFETY_PAY': 'SafetyPay',
  'SOFORT_BANKING': 'SofortBanking',
  'QIWI': 'Qiwi',
  'GIRO_PAY': 'GiroPay',
  'IDEAL': 'Ideal',
  'INTERAC': 'Interac',
  'CB': 'CB',
  'CARRIER_BILLING': 'CarrierBilling',
  'GOOGLE_PAY': 'GooglePay',
  'APPLE_PAY': 'ApplePay',
  'FREE': 'Free',
  'UNKNOWN': 'Unknown',
} as const;
export type PaymentTypeType = typeof PaymentType;
export type PaymentTypeValueType = PaymentTypeType[keyof PaymentTypeType];

let sysPlatform: any;

export function setSystemProperties(properties: {platform: PlatformValueType}
  ) {
  if (properties.platform !== null && properties.platform !== undefined) {
    sysPlatform = properties.platform;
  }
}

let PostHog: any;
let GoogleTagManagerFront: any;

export function initAvo(options: {env: AvoEnv; webDebugger?: boolean;
  webDebuggerOptions?: webDebuggerOptions; strict?: boolean; noop?: boolean;
  reportFailureAs?: 'error' | 'warn' | 'log'; inspector?: AvoInspector;
  avoLogger?: AvoLogger}, systemProperties: {platform: PlatformValueType},
  destinationOptions: any, PostHogDestination: CustomDestination,
  GoogleTagManagerFrontDestination: CustomDestination) {
  if (__AVO_ENV__ !== null) {
    return;
  }
  __AVO_ENV__ = options.env;
  if (options.avoLogger !== undefined) {
    __AVO_LOGGER__ = options.avoLogger;
  }
  if (options.noop === true) {
    __AVO_NOOP__ = true;
  }
  if (__AVO_NOOP__ && __AVO_ENV__ == AvoEnv.Prod) {
    InternalAvoLogger.warn("[avo] ****************************************************");
    InternalAvoLogger.warn("[avo] WARNING Avo cannot be initialized in noop mode in production:");
    InternalAvoLogger.warn("[avo] - Overwriting configuration with noop=false.");
    InternalAvoLogger.warn("[avo] - Please reach out if you want to be able to run Avo in production mode with noop=true");
    InternalAvoLogger.warn("[avo] ****************************************************");
    __AVO_NOOP__ = false;
  }
  if (__AVO_NOOP__) {
    InternalAvoLogger.log("[avo] ****************************************************");
    InternalAvoLogger.log("[avo] Avo is now initialized in noop mode. This means:");
    InternalAvoLogger.log("[avo] - No events will be sent");
    InternalAvoLogger.log("[avo] - No network requests are made");
    InternalAvoLogger.log("[avo] ****************************************************");
  }
  if (options.strict !== undefined) {
    __STRICT__ = options.strict !== false;
  }
  if (options.reportFailureAs !== undefined) {
    __REPORT_FAILURE_AS__ = options.reportFailureAs;
  }
  __WEB_DEBUGGER__ = !__AVO_NOOP__ && ((typeof window !== 'undefined' && (window as any).location.search.indexOf("avo_debug=1") > -1) || (options.webDebugger !== false && __AVO_ENV__ !== AvoEnv.Prod));
  if (!__AVO_NOOP__ && options.inspector !== undefined) {
    __INSPECTOR__ = options.inspector;
  } else if (__AVO_ENV__ !== 'prod') {
    InternalAvoLogger.warn("[avo] Avo Inspector not provided in initAvo() call");
  }

  setSystemProperties(systemProperties);

  destinationOptions = destinationOptions || {};

  if (__WEB_DEBUGGER__ && !__AVO_NOOP__) {
    if (options.webDebuggerOptions?.position) {
      _avo_debugger_send_position(options.webDebuggerOptions.position)
    }

(function() {
  if (typeof (window as any) === 'undefined') { return; }
  let init = function() {
    let iframe: any = document.createElement("iframe");
    document.body.appendChild(iframe);
    iframe.id = "avo-debugger";
    iframe.src = "https://www.avo.app/_debugger";
    iframe.style = "display: none;";
  };

  if (document.body) {
    init();
  } else {
    document.addEventListener('DOMContentLoaded', init);
  }
})();

  }
  if (!__AVO_NOOP__) {
    if (__AVO_ENV__ === AvoEnv.Prod) {
    }
    if (__AVO_ENV__ === AvoEnv.Dev) {
    }
    if (__AVO_ENV__ === AvoEnv.Staging) {
    }

    PostHog = PostHogDestination;
    if (__AVO_ENV__ === 'prod') {
      PostHog && PostHog.make && PostHog.make(__AVO_ENV__, "phc_OJofdyzylb2XtatmyedeHj8xQSkc8cPsG9FL2LzyCd6");
    } else if (__AVO_ENV__ === 'dev') {
      PostHog && PostHog.make && PostHog.make(__AVO_ENV__, "phc_xF7vb6hNMBu7ismnjaFqp4eCYPoqzkuHRrcrw5iGICT");
    } else {
      PostHog && PostHog.make && PostHog.make(__AVO_ENV__, "phc_xF7vb6hNMBu7ismnjaFqp4eCYPoqzkuHRrcrw5iGICT");
    }

    GoogleTagManagerFront = GoogleTagManagerFrontDestination;
    if (__AVO_ENV__ === 'prod') {
      GoogleTagManagerFront && GoogleTagManagerFront.make && GoogleTagManagerFront.make(__AVO_ENV__, null);
    } else if (__AVO_ENV__ === 'dev') {
      GoogleTagManagerFront && GoogleTagManagerFront.make && GoogleTagManagerFront.make(__AVO_ENV__, null);
    } else {
      console[__REPORT_FAILURE_AS__ || 'error']("[avo] No staging key is set for Google Tag Manager front. Head to destination settings in Avo to set a staging key.");
      GoogleTagManagerFront && GoogleTagManagerFront.make && GoogleTagManagerFront.make(__AVO_ENV__, null);
    }
    if (__AVO_ENV__ === AvoEnv.Dev) {
      // debug console in Avo
      _avo_invoke_meta(__AVO_ENV__, 'init', [], 'init');
    }
  }
}

function assertItems(items: Items[], _label?: string) {
  let messages: AvoAssertMessage[] = [];
  messages = messages.concat(AvoAssert.assertList("EL97nwQpFG", "items", items));
  if (items !== null && items !== undefined && items.forEach) {
    items.forEach(function (value, index) {
      messages = messages.concat(AvoAssert.assertObject("EL97nwQpFG", "item at index " + index + " in items", value));
    });
  }
  return messages;
}

function assertItemsOptional(items: Items[] | null | undefined,
  _label?: string) {
  let messages: AvoAssertMessage[] = [];
  if (items !== undefined && items !== null) {
    messages = messages.concat(AvoAssert.assertList("EL97nwQpFG", "items", items));
    items.forEach(function (value, index) {
      messages = messages.concat(AvoAssert.assertObject("EL97nwQpFG", "item at index " + index + " in items", value));
    });
  }
  return messages;
}

export function setAvoLogger(avoLogger: AvoLogger | null) {
  __AVO_LOGGER__ = avoLogger;
}

export interface PurchaseProperties {
  currency: string;
  value: number;
  transactionId: string;
  coupon: string | null | undefined;
  tax: number;
  items: Items[];
  autoPaymentSetup: boolean;
  receiverCountry: string;
  senderCountry: string;
  paymentType: PaymentTypeValueType;
  savedPayment: boolean;
  autoPayInterval: string | null | undefined;
  guestCheckout: boolean;
  origin: OriginValueType;
  optionalAddOn: boolean;
  addonId: string | null | undefined;
  addonValue: number | null | undefined;
  customerType: CustomerTypeValueType;
  promotionName: string | null | undefined;
  promotionId: string | null | undefined;
  promotionUpsellValue: number | null | undefined;
  subscriber: string;
}
/**
 * purchase: When a user has successfully made a purchase.

 *
 * When to trigger this event:
 * 1. Should be fired everytime a customer has successfully made a purchase.
 * View in Avo: https://www.avo.app/schemas/Vw4DbNQPc4e5WGVEh2nX/branches/lE4oRzTE7/events/rwNXNX7jdK/trigger/Nqf2vlDzT
 *
 * @param properties the properties associatied with this event
 * @param properties.currency: Currency of the items associated with the event, in 3-letter ISO 4217 format.
 * @param properties.value: The monetary value of the event.  The value that digicel will receive. If senders doesn't pay for tax send value without tax if senders pays tax include tax.

The value is determined by the product selected by the customer. The /topups/products API returns the list of products. Each product has two IDs. with_tax.id and without_tax.id. If the customer selects to include tax use the with_tax.id, otherwise use the other.
 * @param properties.transactionId: The unique identifier of a transaction.  'purchase_id'

'
 * @param properties.coupon: The coupon name/code is associated with the event.   'promo_code'
 * @param properties.tax: Tax cost associated with a transaction.  will be zero if the senders don't pay the taxes.
 * @param properties.items: array of the items
 * @param properties.autoPaymentSetup: If an auto payment has been setup
 * @param properties.receiverCountry: The receiver country in ISO 3166-2 format.
 * @param properties.senderCountry: The sender country in ISO 3166-2 format.
 * @param properties.paymentType: The chosen method of payment.    'payment_method'
 * @param properties.savedPayment: If the payment option was a previously saved card
 * @param properties.autoPayInterval: The frequency for a recurring payment in days.  for plans 'plan'  'auto_pay_interval' for the topups we should use the value from the UI in days.
 * @param properties.guestCheckout: If the purchase is done via guest checkout
 * @param properties.origin: Where the event originated from
 * @param properties.optionalAddOn: if the there was an addon added to the purchase
 * @param properties.addonId: The id for the add on. 'id'
 * @param properties.addonValue: The value of the addon.
 * @param properties.customerType: The customer type indicates if the purchase is da domestic or international purchase. domestic is sending money from and to the same country.
 * @param properties.promotionName: The name of the promotion associated with the event.
 * @param properties.promotionId: The ID of the promotion associated with the event.

 * @param properties.promotionUpsellValue: The monetary uplift value of the upsell = Value of the purchased item - the value of the original selected item.  No impact of discount codes

Value should be send as USD
 * @param properties.subscriber: Hashed phone number of the receiver via SHA-256
 *
 * @see {@link https://www.avo.app/schemas/Vw4DbNQPc4e5WGVEh2nX/branches/lE4oRzTE7/events/rwNXNX7jdK}
 */
export function purchase(properties: PurchaseProperties) {
  // @ts-ignore
  let eventPropertiesArray: array = [];
  eventPropertiesArray.push({id: "GHJ57MO8Fg", name: "currency", value: properties.currency});
  eventPropertiesArray.push({id: "JnJy5h18EQ", name: "value", value: properties.value});
  eventPropertiesArray.push({id: "nOs1YKNvUQ", name: "transaction_id", value: properties.transactionId});
  properties.coupon !== undefined && properties.coupon !== null ?
    eventPropertiesArray.push({id: "QDGih4Q6J", name: "coupon", value: properties.coupon}) :
    eventPropertiesArray.push({id: "QDGih4Q6J", name: "coupon", value: null});
  eventPropertiesArray.push({id: "b5U64EzuP6", name: "tax", value: properties.tax});
  eventPropertiesArray.push({id: "EL97nwQpFG", name: "items", value: properties.items.map(function(value) { return {
      "item_id": value.itemId,
      "item_name": value.itemName,
      "price": value.price,
      "pre_selected": value.preSelected,
      "index": value.index,
      "item_category": value.itemCategory,
      "item_category2": value.itemCategory2,
      "item_list_name": value.itemListName,
      "item_list_id": value.itemListId,
    }
  })});
  eventPropertiesArray.push({id: "FRJUi6G5_X", name: "auto_payment_setup", value: properties.autoPaymentSetup});
  eventPropertiesArray.push({id: "VrFz3T9HK", name: "receiver_country", value: properties.receiverCountry});
  eventPropertiesArray.push({id: "hl2iGjzQGU", name: "sender_country", value: properties.senderCountry});
  eventPropertiesArray.push({id: "rBA97j-2pA", name: "payment_type", value: properties.paymentType});
  eventPropertiesArray.push({id: "QkvYGRZ1kZ", name: "saved_payment", value: properties.savedPayment});
  properties.autoPayInterval !== undefined && properties.autoPayInterval !== null ?
    eventPropertiesArray.push({id: "bMqhWZe9Bc", name: "auto_pay_interval", value: properties.autoPayInterval}) :
    eventPropertiesArray.push({id: "bMqhWZe9Bc", name: "auto_pay_interval", value: null});
  eventPropertiesArray.push({id: "VCsnaKD3uW", name: "guest_checkout", value: properties.guestCheckout});
  eventPropertiesArray.push({id: "T_WshweU6t", name: "origin", value: properties.origin});
  eventPropertiesArray.push({id: "MrRxWcx_T7", name: "optional_add_on", value: properties.optionalAddOn});
  properties.addonId !== undefined && properties.addonId !== null ?
    eventPropertiesArray.push({id: "qq5P3a0jE6", name: "addon_id", value: properties.addonId}) :
    eventPropertiesArray.push({id: "qq5P3a0jE6", name: "addon_id", value: null});
  properties.addonValue !== undefined && properties.addonValue !== null ?
    eventPropertiesArray.push({id: "0vTmnx5pYc", name: "addon_value", value: properties.addonValue}) :
    eventPropertiesArray.push({id: "0vTmnx5pYc", name: "addon_value", value: null});
  eventPropertiesArray.push({id: "G9u21geSv", name: "customer_type", value: properties.customerType});
  properties.promotionName !== undefined && properties.promotionName !== null ?
    eventPropertiesArray.push({id: "MxXP1Plj-B", name: "promotion_name", value: properties.promotionName}) :
    eventPropertiesArray.push({id: "MxXP1Plj-B", name: "promotion_name", value: null});
  properties.promotionId !== undefined && properties.promotionId !== null ?
    eventPropertiesArray.push({id: "psQY9ByUFx", name: "promotion_id", value: properties.promotionId}) :
    eventPropertiesArray.push({id: "psQY9ByUFx", name: "promotion_id", value: null});
  properties.promotionUpsellValue !== undefined && properties.promotionUpsellValue !== null ?
    eventPropertiesArray.push({id: "JTYIN7Xdj", name: "promotion_upsell_value", value: properties.promotionUpsellValue}) :
    eventPropertiesArray.push({id: "JTYIN7Xdj", name: "promotion_upsell_value", value: null});
  eventPropertiesArray.push({id: "wZ-ybTsXU", name: "subscriber", value: properties.subscriber});
  eventPropertiesArray.push({id: "0DRCc5VQqM", name: "platform", value: sysPlatform});
  let eventProperties = convertPropertiesArrayToMap(eventPropertiesArray)
  // @ts-ignore
  let userPropertiesArray: array = [];
  let userProperties = convertPropertiesArrayToMap(userPropertiesArray)
  // assert properties
  if (__AVO_ENV__ !== AvoEnv.Prod || __WEB_DEBUGGER__) {
    let messages: AvoAssertMessage[] = [];
    messages = messages.concat(assertItems(properties.items));
    // debug console in Avo
    if (!__AVO_NOOP__) {
      _avo_invoke(__AVO_ENV__, "rwNXNX7jdK", "275393488965eefa9416f2eeb92ba49a16a0c4924b10a6a9b8d3fa607485afc2", messages.map(m => Object.assign({}, {tag: m.tag, propertyId: m.propertyId, additionalProperties: m.additionalProperties, actualType: m.actualType})), 'event');
    }
    InternalAvoLogger.logEventSent("purchase", eventProperties, userProperties);
    if (__WEB_DEBUGGER__) {
      // Avo web debugger
      _avo_debugger_log("rwNXNX7jdK", "purchase", messages, eventPropertiesArray, userPropertiesArray, []);
    }
    // @ts-ignore
    if (__AVO_ENV__ !== AvoEnv.Prod && (__STRICT__ === null || __STRICT__)) {
      // throw exception if messages is not empty
      if (messages.length !== 0) {
        throw new Error("Error sending event 'purchase': " + messages[0]!.message)
      }
    } else {
      messages.forEach(function(m) {
        console[__REPORT_FAILURE_AS__ || 'error']("[avo] " + m.message);
      });
    }
  }
  if (!__AVO_NOOP__) {
    if (__INSPECTOR__ != null) {
      // @ts-ignore
      __INSPECTOR__._avoFunctionTrackSchemaFromEvent("purchase", eventProperties, "rwNXNX7jdK", "275393488965eefa9416f2eeb92ba49a16a0c4924b10a6a9b8d3fa607485afc2");
    }
    // destination PostHog
    PostHog.logEvent("purchase", (Object as any).assign({}, eventProperties));

    // destination GoogleTagManagerFront
    GoogleTagManagerFront.logEvent("purchase", (Object as any).assign({}, eventProperties));
  } else {
    // do nothing
  }
}

export interface AddPaymentInfoProperties {
  currency: string;
  value: number;
  coupon: string | null | undefined;
  paymentType: PaymentTypeValueType;
  items: Items[];
  savedPayment: boolean;
  senderCountry: string;
  receiverCountry: string;
}
/**
 * add_payment_info: Signifies that a user has successfully entered their payment info and the payment info was validated.
 *
 * When to trigger this event:
 * 1. When the user has successfully completed this ding webview.
 * View in Avo: https://www.avo.app/schemas/Vw4DbNQPc4e5WGVEh2nX/branches/lE4oRzTE7/events/nc-Cit_FU3/trigger/F95O0DSFn
 *
 * @param properties the properties associatied with this event
 * @param properties.currency: Currency of the items associated with the event, in 3-letter ISO 4217 format.
 * @param properties.value: The monetary value of the event.  The value that digicel will receive. If senders doesn't pay for tax send value without tax if senders pays tax include tax.

The value is determined by the product selected by the customer. The /topups/products API returns the list of products. Each product has two IDs. with_tax.id and without_tax.id. If the customer selects to include tax use the with_tax.id, otherwise use the other.
 * @param properties.coupon: The coupon name/code is associated with the event.   'promo_code'
 * @param properties.paymentType: The chosen method of payment.    'payment_method'
 * @param properties.items: array of the items
 * @param properties.savedPayment: If the payment option was a previously saved card
 * @param properties.senderCountry: The sender country in ISO 3166-2 format.
 * @param properties.receiverCountry: The receiver country in ISO 3166-2 format.
 *
 * @see {@link https://www.avo.app/schemas/Vw4DbNQPc4e5WGVEh2nX/branches/lE4oRzTE7/events/nc-Cit_FU3}
 */
export function addPaymentInfo(properties: AddPaymentInfoProperties) {
  // @ts-ignore
  let eventPropertiesArray: array = [];
  eventPropertiesArray.push({id: "GHJ57MO8Fg", name: "currency", value: properties.currency});
  eventPropertiesArray.push({id: "JnJy5h18EQ", name: "value", value: properties.value});
  properties.coupon !== undefined && properties.coupon !== null ?
    eventPropertiesArray.push({id: "QDGih4Q6J", name: "coupon", value: properties.coupon}) :
    eventPropertiesArray.push({id: "QDGih4Q6J", name: "coupon", value: null});
  eventPropertiesArray.push({id: "rBA97j-2pA", name: "payment_type", value: properties.paymentType});
  eventPropertiesArray.push({id: "EL97nwQpFG", name: "items", value: properties.items.map(function(value) { return {
      "item_id": value.itemId,
      "item_name": value.itemName,
      "price": value.price,
      "pre_selected": value.preSelected,
      "index": value.index,
      "item_category": value.itemCategory,
      "item_category2": value.itemCategory2,
      "item_list_name": value.itemListName,
      "item_list_id": value.itemListId,
    }
  })});
  eventPropertiesArray.push({id: "QkvYGRZ1kZ", name: "saved_payment", value: properties.savedPayment});
  eventPropertiesArray.push({id: "hl2iGjzQGU", name: "sender_country", value: properties.senderCountry});
  eventPropertiesArray.push({id: "VrFz3T9HK", name: "receiver_country", value: properties.receiverCountry});
  eventPropertiesArray.push({id: "0DRCc5VQqM", name: "platform", value: sysPlatform});
  let eventProperties = convertPropertiesArrayToMap(eventPropertiesArray)
  // @ts-ignore
  let userPropertiesArray: array = [];
  let userProperties = convertPropertiesArrayToMap(userPropertiesArray)
  // assert properties
  if (__AVO_ENV__ !== AvoEnv.Prod || __WEB_DEBUGGER__) {
    let messages: AvoAssertMessage[] = [];
    messages = messages.concat(assertItems(properties.items));
    // debug console in Avo
    if (!__AVO_NOOP__) {
      _avo_invoke(__AVO_ENV__, "nc-Cit_FU3", "e8f362dabeacabb16bf5fafea0ba8d9f48d56384bd5a4ceba797a20e951c7040", messages.map(m => Object.assign({}, {tag: m.tag, propertyId: m.propertyId, additionalProperties: m.additionalProperties, actualType: m.actualType})), 'event');
    }
    InternalAvoLogger.logEventSent("add_payment_info", eventProperties, userProperties);
    if (__WEB_DEBUGGER__) {
      // Avo web debugger
      _avo_debugger_log("nc-Cit_FU3", "add_payment_info", messages, eventPropertiesArray, userPropertiesArray, []);
    }
    // @ts-ignore
    if (__AVO_ENV__ !== AvoEnv.Prod && (__STRICT__ === null || __STRICT__)) {
      // throw exception if messages is not empty
      if (messages.length !== 0) {
        throw new Error("Error sending event 'add_payment_info': " + messages[0]!.message)
      }
    } else {
      messages.forEach(function(m) {
        console[__REPORT_FAILURE_AS__ || 'error']("[avo] " + m.message);
      });
    }
  }
  if (!__AVO_NOOP__) {
    if (__INSPECTOR__ != null) {
      // @ts-ignore
      __INSPECTOR__._avoFunctionTrackSchemaFromEvent("add_payment_info", eventProperties, "nc-Cit_FU3", "e8f362dabeacabb16bf5fafea0ba8d9f48d56384bd5a4ceba797a20e951c7040");
    }
    // destination PostHog
    PostHog.logEvent("add_payment_info", (Object as any).assign({}, eventProperties));

    // destination GoogleTagManagerFront
    GoogleTagManagerFront.logEvent("add_payment_info", (Object as any).assign({}, eventProperties));
  } else {
    // do nothing
  }
}

export interface BeginCheckoutProperties {
  currency: string;
  value: number;
  coupon: string | null | undefined;
  items: Items[];
  receiverCountry: string;
  senderCountry: string;
}
/**
 * begin_checkout: Signifies that a user has begun a checkout. Checkout starts when they proceed to start the payment.
 *
 * When to trigger this event:
 * 1. When the user proceeds to checkout by clicking on the pay now button.
 * View in Avo: https://www.avo.app/schemas/Vw4DbNQPc4e5WGVEh2nX/branches/lE4oRzTE7/events/3Ry3Jk-ZMR/trigger/wTPQYjW1r
 * 2.
 * View in Avo: https://www.avo.app/schemas/Vw4DbNQPc4e5WGVEh2nX/branches/lE4oRzTE7/events/3Ry3Jk-ZMR/trigger/BTFsjSqYH
 *
 * @param properties the properties associatied with this event
 * @param properties.currency: Currency of the items associated with the event, in 3-letter ISO 4217 format.
 * @param properties.value: The monetary value of the event.  The value that digicel will receive. If senders doesn't pay for tax send value without tax if senders pays tax include tax.

The value is determined by the product selected by the customer. The /topups/products API returns the list of products. Each product has two IDs. with_tax.id and without_tax.id. If the customer selects to include tax use the with_tax.id, otherwise use the other.
 * @param properties.coupon: The coupon name/code is associated with the event.   'promo_code'
 * @param properties.items: array of the items
 * @param properties.receiverCountry: The receiver country in ISO 3166-2 format.
 * @param properties.senderCountry: The sender country in ISO 3166-2 format.
 *
 * @see {@link https://www.avo.app/schemas/Vw4DbNQPc4e5WGVEh2nX/branches/lE4oRzTE7/events/3Ry3Jk-ZMR}
 */
export function beginCheckout(properties: BeginCheckoutProperties) {
  // @ts-ignore
  let eventPropertiesArray: array = [];
  eventPropertiesArray.push({id: "GHJ57MO8Fg", name: "currency", value: properties.currency});
  eventPropertiesArray.push({id: "JnJy5h18EQ", name: "value", value: properties.value});
  properties.coupon !== undefined && properties.coupon !== null ?
    eventPropertiesArray.push({id: "QDGih4Q6J", name: "coupon", value: properties.coupon}) :
    eventPropertiesArray.push({id: "QDGih4Q6J", name: "coupon", value: null});
  eventPropertiesArray.push({id: "EL97nwQpFG", name: "items", value: properties.items.map(function(value) { return {
      "item_id": value.itemId,
      "item_name": value.itemName,
      "price": value.price,
      "pre_selected": value.preSelected,
      "index": value.index,
      "item_category": value.itemCategory,
      "item_category2": value.itemCategory2,
      "item_list_name": value.itemListName,
      "item_list_id": value.itemListId,
    }
  })});
  eventPropertiesArray.push({id: "VrFz3T9HK", name: "receiver_country", value: properties.receiverCountry});
  eventPropertiesArray.push({id: "hl2iGjzQGU", name: "sender_country", value: properties.senderCountry});
  eventPropertiesArray.push({id: "0DRCc5VQqM", name: "platform", value: sysPlatform});
  let eventProperties = convertPropertiesArrayToMap(eventPropertiesArray)
  // @ts-ignore
  let userPropertiesArray: array = [];
  let userProperties = convertPropertiesArrayToMap(userPropertiesArray)
  // assert properties
  if (__AVO_ENV__ !== AvoEnv.Prod || __WEB_DEBUGGER__) {
    let messages: AvoAssertMessage[] = [];
    messages = messages.concat(assertItems(properties.items));
    // debug console in Avo
    if (!__AVO_NOOP__) {
      _avo_invoke(__AVO_ENV__, "3Ry3Jk-ZMR", "640f23719b36508ea618579fbb828e16dc8a5c2cb7c8d4a7cd7b0770b93851c8", messages.map(m => Object.assign({}, {tag: m.tag, propertyId: m.propertyId, additionalProperties: m.additionalProperties, actualType: m.actualType})), 'event');
    }
    InternalAvoLogger.logEventSent("begin_checkout", eventProperties, userProperties);
    if (__WEB_DEBUGGER__) {
      // Avo web debugger
      _avo_debugger_log("3Ry3Jk-ZMR", "begin_checkout", messages, eventPropertiesArray, userPropertiesArray, []);
    }
    // @ts-ignore
    if (__AVO_ENV__ !== AvoEnv.Prod && (__STRICT__ === null || __STRICT__)) {
      // throw exception if messages is not empty
      if (messages.length !== 0) {
        throw new Error("Error sending event 'begin_checkout': " + messages[0]!.message)
      }
    } else {
      messages.forEach(function(m) {
        console[__REPORT_FAILURE_AS__ || 'error']("[avo] " + m.message);
      });
    }
  }
  if (!__AVO_NOOP__) {
    if (__INSPECTOR__ != null) {
      // @ts-ignore
      __INSPECTOR__._avoFunctionTrackSchemaFromEvent("begin_checkout", eventProperties, "3Ry3Jk-ZMR", "640f23719b36508ea618579fbb828e16dc8a5c2cb7c8d4a7cd7b0770b93851c8");
    }
    // destination PostHog
    PostHog.logEvent("begin_checkout", (Object as any).assign({}, eventProperties));

    // destination GoogleTagManagerFront
    GoogleTagManagerFront.logEvent("begin_checkout", (Object as any).assign({}, eventProperties));
  } else {
    // do nothing
  }
}

export interface ViewCartProperties {
  currency: string;
  value: number;
  items: Items[];
  receiverCountry: string;
  senderCountry: string;
  savedPayment: boolean;
}
/**
 * view_cart: This event signifies that a user viewed their cart. Not sent when users skip this step by choosing to redo a topup from their history.
 *
 * When to trigger this event:
 * 1. When a logged-in user sees the order overview
 * View in Avo: https://www.avo.app/schemas/Vw4DbNQPc4e5WGVEh2nX/branches/lE4oRzTE7/events/bnpq_imVrn/trigger/X1dhhGHJx
 * 2. When a not logged-in users sees the cart overview.
 * View in Avo: https://www.avo.app/schemas/Vw4DbNQPc4e5WGVEh2nX/branches/lE4oRzTE7/events/bnpq_imVrn/trigger/tcFYSKkFF
 *
 * @param properties the properties associatied with this event
 * @param properties.currency: Currency of the items associated with the event, in 3-letter ISO 4217 format.
 * @param properties.value: The monetary value of the event.  The value that digicel will receive. If senders doesn't pay for tax send value without tax if senders pays tax include tax.

The value is determined by the product selected by the customer. The /topups/products API returns the list of products. Each product has two IDs. with_tax.id and without_tax.id. If the customer selects to include tax use the with_tax.id, otherwise use the other.
 * @param properties.items: array of the items
 * @param properties.receiverCountry: The receiver country in ISO 3166-2 format.
 * @param properties.senderCountry: The sender country in ISO 3166-2 format.
 * @param properties.savedPayment: If the payment option was a previously saved card
 *
 * @see {@link https://www.avo.app/schemas/Vw4DbNQPc4e5WGVEh2nX/branches/lE4oRzTE7/events/bnpq_imVrn}
 */
export function viewCart(properties: ViewCartProperties) {
  // @ts-ignore
  let eventPropertiesArray: array = [];
  eventPropertiesArray.push({id: "GHJ57MO8Fg", name: "currency", value: properties.currency});
  eventPropertiesArray.push({id: "JnJy5h18EQ", name: "value", value: properties.value});
  eventPropertiesArray.push({id: "EL97nwQpFG", name: "items", value: properties.items.map(function(value) { return {
      "item_id": value.itemId,
      "item_name": value.itemName,
      "price": value.price,
      "pre_selected": value.preSelected,
      "index": value.index,
      "item_category": value.itemCategory,
      "item_category2": value.itemCategory2,
      "item_list_name": value.itemListName,
      "item_list_id": value.itemListId,
    }
  })});
  eventPropertiesArray.push({id: "VrFz3T9HK", name: "receiver_country", value: properties.receiverCountry});
  eventPropertiesArray.push({id: "hl2iGjzQGU", name: "sender_country", value: properties.senderCountry});
  eventPropertiesArray.push({id: "QkvYGRZ1kZ", name: "saved_payment", value: properties.savedPayment});
  eventPropertiesArray.push({id: "0DRCc5VQqM", name: "platform", value: sysPlatform});
  let eventProperties = convertPropertiesArrayToMap(eventPropertiesArray)
  // @ts-ignore
  let userPropertiesArray: array = [];
  let userProperties = convertPropertiesArrayToMap(userPropertiesArray)
  // assert properties
  if (__AVO_ENV__ !== AvoEnv.Prod || __WEB_DEBUGGER__) {
    let messages: AvoAssertMessage[] = [];
    messages = messages.concat(assertItems(properties.items));
    // debug console in Avo
    if (!__AVO_NOOP__) {
      _avo_invoke(__AVO_ENV__, "bnpq_imVrn", "eab40c99697a705dbaa86ead6f11bd987edaf20918fbf772d6967c63b7925167", messages.map(m => Object.assign({}, {tag: m.tag, propertyId: m.propertyId, additionalProperties: m.additionalProperties, actualType: m.actualType})), 'event');
    }
    InternalAvoLogger.logEventSent("view_cart", eventProperties, userProperties);
    if (__WEB_DEBUGGER__) {
      // Avo web debugger
      _avo_debugger_log("bnpq_imVrn", "view_cart", messages, eventPropertiesArray, userPropertiesArray, []);
    }
    // @ts-ignore
    if (__AVO_ENV__ !== AvoEnv.Prod && (__STRICT__ === null || __STRICT__)) {
      // throw exception if messages is not empty
      if (messages.length !== 0) {
        throw new Error("Error sending event 'view_cart': " + messages[0]!.message)
      }
    } else {
      messages.forEach(function(m) {
        console[__REPORT_FAILURE_AS__ || 'error']("[avo] " + m.message);
      });
    }
  }
  if (!__AVO_NOOP__) {
    if (__INSPECTOR__ != null) {
      // @ts-ignore
      __INSPECTOR__._avoFunctionTrackSchemaFromEvent("view_cart", eventProperties, "bnpq_imVrn", "eab40c99697a705dbaa86ead6f11bd987edaf20918fbf772d6967c63b7925167");
    }
    // destination PostHog
    PostHog.logEvent("view_cart", (Object as any).assign({}, eventProperties));

    // destination GoogleTagManagerFront
    GoogleTagManagerFront.logEvent("view_cart", (Object as any).assign({}, eventProperties));
  } else {
    // do nothing
  }
}

export interface AddToCartProperties {
  currency: string;
  value: number;
  items: Items[];
  receiverCountry: string;
  senderCountry: string;
}
/**
 * add_to_cart: This event signifies that an item was added to a cart for purchase. Also when a topup starts from a redo topup flow, the items should be added to the cart.
 *
 * When to trigger this event:
 * 1. When a user has selected an option and goes to the next step. Not when they select no add ons.
 * View in Avo: https://www.avo.app/schemas/Vw4DbNQPc4e5WGVEh2nX/branches/lE4oRzTE7/events/NfIPCsFya1/trigger/b8diVay3u
 * 2. When a user has selected a plan and continues to the next step.
 * View in Avo: https://www.avo.app/schemas/Vw4DbNQPc4e5WGVEh2nX/branches/lE4oRzTE7/events/NfIPCsFya1/trigger/cbG9ucELc
 * 3. When the user has selected a top up amount and continues to the next step.
 * View in Avo: https://www.avo.app/schemas/Vw4DbNQPc4e5WGVEh2nX/branches/lE4oRzTE7/events/NfIPCsFya1/trigger/jayaZa5Nn
 * 4. When a user clicks the topup again button, the item will be added to the cart.
 * View in Avo: https://www.avo.app/schemas/Vw4DbNQPc4e5WGVEh2nX/branches/lE4oRzTE7/events/NfIPCsFya1/trigger/-Os7nm0ck
 * 5. When the user clicks top up again from the home screen. The item should be added to the cart.
 * View in Avo: https://www.avo.app/schemas/Vw4DbNQPc4e5WGVEh2nX/branches/lE4oRzTE7/events/NfIPCsFya1/trigger/0D3gs1djD
 *
 * @param properties the properties associatied with this event
 * @param properties.currency: Currency of the items associated with the event, in 3-letter ISO 4217 format.
 * @param properties.value: The monetary value of the event.  The value that digicel will receive. If senders doesn't pay for tax send value without tax if senders pays tax include tax.

The value is determined by the product selected by the customer. The /topups/products API returns the list of products. Each product has two IDs. with_tax.id and without_tax.id. If the customer selects to include tax use the with_tax.id, otherwise use the other.
 * @param properties.items: array of the items
 * @param properties.receiverCountry: The receiver country in ISO 3166-2 format.
 * @param properties.senderCountry: The sender country in ISO 3166-2 format.
 *
 * @see {@link https://www.avo.app/schemas/Vw4DbNQPc4e5WGVEh2nX/branches/lE4oRzTE7/events/NfIPCsFya1}
 */
export function addToCart(properties: AddToCartProperties) {
  // @ts-ignore
  let eventPropertiesArray: array = [];
  eventPropertiesArray.push({id: "GHJ57MO8Fg", name: "currency", value: properties.currency});
  eventPropertiesArray.push({id: "JnJy5h18EQ", name: "value", value: properties.value});
  eventPropertiesArray.push({id: "EL97nwQpFG", name: "items", value: properties.items.map(function(value) { return {
      "item_id": value.itemId,
      "item_name": value.itemName,
      "price": value.price,
      "pre_selected": value.preSelected,
      "index": value.index,
      "item_category": value.itemCategory,
      "item_category2": value.itemCategory2,
      "item_list_name": value.itemListName,
      "item_list_id": value.itemListId,
    }
  })});
  eventPropertiesArray.push({id: "VrFz3T9HK", name: "receiver_country", value: properties.receiverCountry});
  eventPropertiesArray.push({id: "hl2iGjzQGU", name: "sender_country", value: properties.senderCountry});
  eventPropertiesArray.push({id: "0DRCc5VQqM", name: "platform", value: sysPlatform});
  let eventProperties = convertPropertiesArrayToMap(eventPropertiesArray)
  // @ts-ignore
  let userPropertiesArray: array = [];
  let userProperties = convertPropertiesArrayToMap(userPropertiesArray)
  // assert properties
  if (__AVO_ENV__ !== AvoEnv.Prod || __WEB_DEBUGGER__) {
    let messages: AvoAssertMessage[] = [];
    messages = messages.concat(assertItems(properties.items));
    // debug console in Avo
    if (!__AVO_NOOP__) {
      _avo_invoke(__AVO_ENV__, "NfIPCsFya1", "ee2344dac443de8cd5d82f45506aa8385a8a0239a2b358c41ca359f8e9b1cf33", messages.map(m => Object.assign({}, {tag: m.tag, propertyId: m.propertyId, additionalProperties: m.additionalProperties, actualType: m.actualType})), 'event');
    }
    InternalAvoLogger.logEventSent("add_to_cart", eventProperties, userProperties);
    if (__WEB_DEBUGGER__) {
      // Avo web debugger
      _avo_debugger_log("NfIPCsFya1", "add_to_cart", messages, eventPropertiesArray, userPropertiesArray, []);
    }
    // @ts-ignore
    if (__AVO_ENV__ !== AvoEnv.Prod && (__STRICT__ === null || __STRICT__)) {
      // throw exception if messages is not empty
      if (messages.length !== 0) {
        throw new Error("Error sending event 'add_to_cart': " + messages[0]!.message)
      }
    } else {
      messages.forEach(function(m) {
        console[__REPORT_FAILURE_AS__ || 'error']("[avo] " + m.message);
      });
    }
  }
  if (!__AVO_NOOP__) {
    if (__INSPECTOR__ != null) {
      // @ts-ignore
      __INSPECTOR__._avoFunctionTrackSchemaFromEvent("add_to_cart", eventProperties, "NfIPCsFya1", "ee2344dac443de8cd5d82f45506aa8385a8a0239a2b358c41ca359f8e9b1cf33");
    }
    // destination PostHog
    PostHog.logEvent("add_to_cart", (Object as any).assign({}, eventProperties));

    // destination GoogleTagManagerFront
    GoogleTagManagerFront.logEvent("add_to_cart", (Object as any).assign({}, eventProperties));
  } else {
    // do nothing
  }
}

export interface SelectPromotionProperties {
  creativeSlot: CreativeSlotValueType;
  promotionId: string;
  senderCountry: string;
  receiverCountry: string;
  promotionName: string;
}
/**
 * select_promotion: This event signifies a promotion was selected from a list.
 *
 * When to trigger this event:
 * 1. When the user selects the promotion by clicking the continue button.
 * View in Avo: https://www.avo.app/schemas/Vw4DbNQPc4e5WGVEh2nX/branches/lE4oRzTE7/events/FLG10WpQhN/trigger/44IGcwSak
 * 2. When someone selects the promotion by clicking the top up now button

promotion id: uid
promotion name: data.name
 * View in Avo: https://www.avo.app/schemas/Vw4DbNQPc4e5WGVEh2nX/branches/lE4oRzTE7/events/FLG10WpQhN/trigger/kzgdjqepg
 * 3. When a users clicks the top up. now button on a detail of a promotion.
creative slot & origin "promotion detail"

promotion id: uid
promotion name: data.name
 * View in Avo: https://www.avo.app/schemas/Vw4DbNQPc4e5WGVEh2nX/branches/lE4oRzTE7/events/FLG10WpQhN/trigger/ZRHsMUJLj
 *
 * @param properties the properties associatied with this event
 * @param properties.creativeSlot: The name of the promotional creative slot associated with the event.
 * @param properties.promotionId: The ID of the promotion associated with the event.

 * @param properties.senderCountry: The sender country in ISO 3166-2 format.
 * @param properties.receiverCountry: The receiver country in ISO 3166-2 format.
 * @param properties.promotionName: The name of the promotion associated with the event.
 *
 * @see {@link https://www.avo.app/schemas/Vw4DbNQPc4e5WGVEh2nX/branches/lE4oRzTE7/events/FLG10WpQhN}
 */
export function selectPromotion(properties: SelectPromotionProperties) {
  // @ts-ignore
  let eventPropertiesArray: array = [];
  eventPropertiesArray.push({id: "fGv8qjSz13", name: "creative_slot", value: properties.creativeSlot});
  eventPropertiesArray.push({id: "psQY9ByUFx", name: "promotion_id", value: properties.promotionId});
  eventPropertiesArray.push({id: "hl2iGjzQGU", name: "sender_country", value: properties.senderCountry});
  eventPropertiesArray.push({id: "VrFz3T9HK", name: "receiver_country", value: properties.receiverCountry});
  eventPropertiesArray.push({id: "MxXP1Plj-B", name: "promotion_name", value: properties.promotionName});
  eventPropertiesArray.push({id: "0DRCc5VQqM", name: "platform", value: sysPlatform});
  let eventProperties = convertPropertiesArrayToMap(eventPropertiesArray)
  // @ts-ignore
  let userPropertiesArray: array = [];
  let userProperties = convertPropertiesArrayToMap(userPropertiesArray)
  // assert properties
  if (__AVO_ENV__ !== AvoEnv.Prod || __WEB_DEBUGGER__) {
    let messages: AvoAssertMessage[] = [];
    // debug console in Avo
    if (!__AVO_NOOP__) {
      _avo_invoke(__AVO_ENV__, "FLG10WpQhN", "baed075da417d64e71bd83f285b6bb5349f7337c147847872c577e44b5233aff", messages.map(m => Object.assign({}, {tag: m.tag, propertyId: m.propertyId, additionalProperties: m.additionalProperties, actualType: m.actualType})), 'event');
    }
    InternalAvoLogger.logEventSent("select_promotion", eventProperties, userProperties);
    if (__WEB_DEBUGGER__) {
      // Avo web debugger
      _avo_debugger_log("FLG10WpQhN", "select_promotion", messages, eventPropertiesArray, userPropertiesArray, []);
    }
  }
  if (!__AVO_NOOP__) {
    if (__INSPECTOR__ != null) {
      // @ts-ignore
      __INSPECTOR__._avoFunctionTrackSchemaFromEvent("select_promotion", eventProperties, "FLG10WpQhN", "baed075da417d64e71bd83f285b6bb5349f7337c147847872c577e44b5233aff");
    }
    // destination PostHog
    PostHog.logEvent("select_promotion", (Object as any).assign({}, eventProperties));

    // destination GoogleTagManagerFront
    GoogleTagManagerFront.logEvent("select_promotion", (Object as any).assign({}, eventProperties));
  } else {
    // do nothing
  }
}

export interface HomeViewProperties {
  senderCountry: string;
  receiverCountry: string | null | undefined;
}
/**
 * home_view: Signifies that the users has viewed the home page after that the sender country is available
 *
 * When to trigger this event:
 * 1. When the user visits the homepage on the website and the sender country is available.
 * View in Avo: https://www.avo.app/schemas/Vw4DbNQPc4e5WGVEh2nX/branches/lE4oRzTE7/events/p_I3-fEJwp/trigger/8kwKdYusl
 *
 * @param properties the properties associatied with this event
 * @param properties.senderCountry: The sender country in ISO 3166-2 format.
 * @param properties.receiverCountry: The receiver country in ISO 3166-2 format.
 *
 * @see {@link https://www.avo.app/schemas/Vw4DbNQPc4e5WGVEh2nX/branches/lE4oRzTE7/events/p_I3-fEJwp}
 */
export function homeView(properties: HomeViewProperties) {
  // @ts-ignore
  let eventPropertiesArray: array = [];
  eventPropertiesArray.push({id: "hl2iGjzQGU", name: "sender_country", value: properties.senderCountry});
  properties.receiverCountry !== undefined && properties.receiverCountry !== null ?
    eventPropertiesArray.push({id: "VrFz3T9HK", name: "receiver_country", value: properties.receiverCountry}) :
    eventPropertiesArray.push({id: "VrFz3T9HK", name: "receiver_country", value: null});
  eventPropertiesArray.push({id: "0DRCc5VQqM", name: "platform", value: sysPlatform});
  let eventProperties = convertPropertiesArrayToMap(eventPropertiesArray)
  // @ts-ignore
  let userPropertiesArray: array = [];
  let userProperties = convertPropertiesArrayToMap(userPropertiesArray)
  // assert properties
  if (__AVO_ENV__ !== AvoEnv.Prod || __WEB_DEBUGGER__) {
    let messages: AvoAssertMessage[] = [];
    // debug console in Avo
    if (!__AVO_NOOP__) {
      _avo_invoke(__AVO_ENV__, "p_I3-fEJwp", "a34643f39c952ca1eaf6fcf02f66f6d56dbcb51da7514ee18505029f6b443487", messages.map(m => Object.assign({}, {tag: m.tag, propertyId: m.propertyId, additionalProperties: m.additionalProperties, actualType: m.actualType})), 'event');
    }
    InternalAvoLogger.logEventSent("home_view", eventProperties, userProperties);
    if (__WEB_DEBUGGER__) {
      // Avo web debugger
      _avo_debugger_log("p_I3-fEJwp", "home_view", messages, eventPropertiesArray, userPropertiesArray, []);
    }
  }
  if (!__AVO_NOOP__) {
    if (__INSPECTOR__ != null) {
      // @ts-ignore
      __INSPECTOR__._avoFunctionTrackSchemaFromEvent("home_view", eventProperties, "p_I3-fEJwp", "a34643f39c952ca1eaf6fcf02f66f6d56dbcb51da7514ee18505029f6b443487");
    }
    // destination PostHog
    PostHog.logEvent("home_view", (Object as any).assign({}, eventProperties));

    // destination GoogleTagManagerFront
    GoogleTagManagerFront.logEvent("home_view", (Object as any).assign({}, eventProperties));
  } else {
    // do nothing
  }
}

export interface SelectReceiverCountryProperties {
  receiverCountry: string;
}
/**
 * select_receiver_country: When the user selects a country from the list. A default receiver country could be selected upfront. This event is not triggered when the receiver country is prefilled.
 *
 * When to trigger this event:
 * 1. When the user has selected a sender country from the dropdown list.
 * View in Avo: https://www.avo.app/schemas/Vw4DbNQPc4e5WGVEh2nX/branches/lE4oRzTE7/events/DrxDpOskdJ/trigger/uFfpvYDsR
 *
 * @param properties the properties associatied with this event
 * @param properties.receiverCountry: The receiver country in ISO 3166-2 format.
 *
 * @see {@link https://www.avo.app/schemas/Vw4DbNQPc4e5WGVEh2nX/branches/lE4oRzTE7/events/DrxDpOskdJ}
 */
export function selectReceiverCountry(
  properties: SelectReceiverCountryProperties) {
  // @ts-ignore
  let eventPropertiesArray: array = [];
  eventPropertiesArray.push({id: "VrFz3T9HK", name: "receiver_country", value: properties.receiverCountry});
  eventPropertiesArray.push({id: "0DRCc5VQqM", name: "platform", value: sysPlatform});
  let eventProperties = convertPropertiesArrayToMap(eventPropertiesArray)
  // @ts-ignore
  let userPropertiesArray: array = [];
  let userProperties = convertPropertiesArrayToMap(userPropertiesArray)
  // assert properties
  if (__AVO_ENV__ !== AvoEnv.Prod || __WEB_DEBUGGER__) {
    let messages: AvoAssertMessage[] = [];
    // debug console in Avo
    if (!__AVO_NOOP__) {
      _avo_invoke(__AVO_ENV__, "DrxDpOskdJ", "239c9ae32a2f3259341ed510d3975f7087f3a18cf796c26675aa84aa49730c86", messages.map(m => Object.assign({}, {tag: m.tag, propertyId: m.propertyId, additionalProperties: m.additionalProperties, actualType: m.actualType})), 'event');
    }
    InternalAvoLogger.logEventSent("select_receiver_country", eventProperties, userProperties);
    if (__WEB_DEBUGGER__) {
      // Avo web debugger
      _avo_debugger_log("DrxDpOskdJ", "select_receiver_country", messages, eventPropertiesArray, userPropertiesArray, []);
    }
  }
  if (!__AVO_NOOP__) {
    if (__INSPECTOR__ != null) {
      // @ts-ignore
      __INSPECTOR__._avoFunctionTrackSchemaFromEvent("select_receiver_country", eventProperties, "DrxDpOskdJ", "239c9ae32a2f3259341ed510d3975f7087f3a18cf796c26675aa84aa49730c86");
    }
    // destination PostHog
    PostHog.logEvent("select_receiver_country", (Object as any).assign({}, eventProperties));

    // destination GoogleTagManagerFront
    GoogleTagManagerFront.logEvent("select_receiver_country", (Object as any).assign({}, eventProperties));
  } else {
    // do nothing
  }
}

export interface SelectSenderCountryProperties {
  senderCountry: string;
}
/**
 * select_sender_country: Signifies that the users has selected a sender country. By default a sender country is already selected.
 *
 * When to trigger this event:
 * 1. When the user selects a country from the dropdown list.
 * View in Avo: https://www.avo.app/schemas/Vw4DbNQPc4e5WGVEh2nX/branches/lE4oRzTE7/events/Zu1695OsS7/trigger/5DTmcUuvI
 *
 * @param properties the properties associatied with this event
 * @param properties.senderCountry: The sender country in ISO 3166-2 format.
 *
 * @see {@link https://www.avo.app/schemas/Vw4DbNQPc4e5WGVEh2nX/branches/lE4oRzTE7/events/Zu1695OsS7}
 */
export function selectSenderCountry(properties: SelectSenderCountryProperties
  ) {
  // @ts-ignore
  let eventPropertiesArray: array = [];
  eventPropertiesArray.push({id: "hl2iGjzQGU", name: "sender_country", value: properties.senderCountry});
  eventPropertiesArray.push({id: "0DRCc5VQqM", name: "platform", value: sysPlatform});
  let eventProperties = convertPropertiesArrayToMap(eventPropertiesArray)
  // @ts-ignore
  let userPropertiesArray: array = [];
  let userProperties = convertPropertiesArrayToMap(userPropertiesArray)
  // assert properties
  if (__AVO_ENV__ !== AvoEnv.Prod || __WEB_DEBUGGER__) {
    let messages: AvoAssertMessage[] = [];
    // debug console in Avo
    if (!__AVO_NOOP__) {
      _avo_invoke(__AVO_ENV__, "Zu1695OsS7", "ca944bd431e0d3f5fbfca924bc5d90fe2886ae2792ab7ce67ec8d79a9f718edf", messages.map(m => Object.assign({}, {tag: m.tag, propertyId: m.propertyId, additionalProperties: m.additionalProperties, actualType: m.actualType})), 'event');
    }
    InternalAvoLogger.logEventSent("select_sender_country", eventProperties, userProperties);
    if (__WEB_DEBUGGER__) {
      // Avo web debugger
      _avo_debugger_log("Zu1695OsS7", "select_sender_country", messages, eventPropertiesArray, userPropertiesArray, []);
    }
  }
  if (!__AVO_NOOP__) {
    if (__INSPECTOR__ != null) {
      // @ts-ignore
      __INSPECTOR__._avoFunctionTrackSchemaFromEvent("select_sender_country", eventProperties, "Zu1695OsS7", "ca944bd431e0d3f5fbfca924bc5d90fe2886ae2792ab7ce67ec8d79a9f718edf");
    }
    // destination PostHog
    PostHog.logEvent("select_sender_country", (Object as any).assign({}, eventProperties));

    // destination GoogleTagManagerFront
    GoogleTagManagerFront.logEvent("select_sender_country", (Object as any).assign({}, eventProperties));
  } else {
    // do nothing
  }
}

export interface LoginProperties {
  userId_: string;
  method: MethodValueType;
  language: string;
  email: string;
}
/**
 * login: Signifies the users has logged in
 *
 * @param properties the properties associatied with this event
 * @param properties.userId_: The value used to identify the user. Make sure it's a unique sequence of characters used to identify the user.
 * @param properties.method: the authentication method of the user. 'provider_key' for social login and for email
 * @param properties.language: The preferred language of the sender
 * @param properties.email: email of the user
 *
 * @see {@link https://www.avo.app/schemas/Vw4DbNQPc4e5WGVEh2nX/branches/lE4oRzTE7/events/baURirMsS5}
 */
export function login(properties: LoginProperties) {
  // @ts-ignore
  let eventPropertiesArray: array = [];
  eventPropertiesArray.push({id: "8sWXOlu-q2", name: "method", value: properties.method});
  eventPropertiesArray.push({id: "0DRCc5VQqM", name: "platform", value: sysPlatform});
  let eventProperties = convertPropertiesArrayToMap(eventPropertiesArray)
  // @ts-ignore
  let userPropertiesArray: array = [];
  userPropertiesArray.push({id: "Q-dqBktZOD", name: "language", value: properties.language});
  userPropertiesArray.push({id: "NzJrBer2fV", name: "email", value: properties.email});
  let userProperties = convertPropertiesArrayToMap(userPropertiesArray)
  // assert properties
  if (__AVO_ENV__ !== AvoEnv.Prod || __WEB_DEBUGGER__) {
    let messages: AvoAssertMessage[] = [];
    // debug console in Avo
    if (!__AVO_NOOP__) {
      _avo_invoke(__AVO_ENV__, "baURirMsS5", "7fdf208be25979fa14d18577b5cf1de6a792efe68601e5c8c264485cbaaba952", messages.map(m => Object.assign({}, {tag: m.tag, propertyId: m.propertyId, additionalProperties: m.additionalProperties, actualType: m.actualType})), 'event');
    }
    InternalAvoLogger.logEventSent("login", eventProperties, userProperties);
    if (__WEB_DEBUGGER__) {
      // Avo web debugger
      _avo_debugger_log("baURirMsS5", "login", messages, eventPropertiesArray, userPropertiesArray, []);
    }
  }
  if (!__AVO_NOOP__) {
    if (__INSPECTOR__ != null) {
      // @ts-ignore
      __INSPECTOR__._avoFunctionTrackSchemaFromEvent("login", eventProperties, "baURirMsS5", "7fdf208be25979fa14d18577b5cf1de6a792efe68601e5c8c264485cbaaba952");
    }
    // destination PostHog
    PostHog.identify(properties.userId_);
    PostHog.setUserProperties(properties.userId_, (Object as any).assign({}, userProperties));
    PostHog.logEvent("login", (Object as any).assign({}, eventProperties));

    // destination GoogleTagManagerFront
    GoogleTagManagerFront.identify(properties.userId_);
    GoogleTagManagerFront.setUserProperties(properties.userId_, (Object as any).assign({}, userProperties));
    GoogleTagManagerFront.logEvent("login", (Object as any).assign({}, eventProperties));
  } else {
    // do nothing
  }
}

export interface TopupStartProperties {
  senderCountry: string;
  receiverCountry: string;
  origin: OriginValueType;
}
/**
 * topup_start: Signifies that the user has started the topup flow.
 *
 * When to trigger this event:
 * 1. When the users has clicked on start a top up and is able to move to the next step. So all validation is validated.
 * View in Avo: https://www.avo.app/schemas/Vw4DbNQPc4e5WGVEh2nX/branches/lE4oRzTE7/events/Y7uhsCETz1/trigger/yPoge1hji
 *
 * @param properties the properties associatied with this event
 * @param properties.senderCountry: The sender country in ISO 3166-2 format.
 * @param properties.receiverCountry: The receiver country in ISO 3166-2 format.
 * @param properties.origin: Where the event originated from
 *
 * @see {@link https://www.avo.app/schemas/Vw4DbNQPc4e5WGVEh2nX/branches/lE4oRzTE7/events/Y7uhsCETz1}
 */
export function topupStart(properties: TopupStartProperties) {
  // @ts-ignore
  let eventPropertiesArray: array = [];
  eventPropertiesArray.push({id: "hl2iGjzQGU", name: "sender_country", value: properties.senderCountry});
  eventPropertiesArray.push({id: "VrFz3T9HK", name: "receiver_country", value: properties.receiverCountry});
  eventPropertiesArray.push({id: "T_WshweU6t", name: "origin", value: properties.origin});
  eventPropertiesArray.push({id: "0DRCc5VQqM", name: "platform", value: sysPlatform});
  let eventProperties = convertPropertiesArrayToMap(eventPropertiesArray)
  // @ts-ignore
  let userPropertiesArray: array = [];
  let userProperties = convertPropertiesArrayToMap(userPropertiesArray)
  // assert properties
  if (__AVO_ENV__ !== AvoEnv.Prod || __WEB_DEBUGGER__) {
    let messages: AvoAssertMessage[] = [];
    // debug console in Avo
    if (!__AVO_NOOP__) {
      _avo_invoke(__AVO_ENV__, "Y7uhsCETz1", "3c804bcd937324647548789072492c8a09b5bb08654eada3e969b0ef894d64fc", messages.map(m => Object.assign({}, {tag: m.tag, propertyId: m.propertyId, additionalProperties: m.additionalProperties, actualType: m.actualType})), 'event');
    }
    InternalAvoLogger.logEventSent("topup_start", eventProperties, userProperties);
    if (__WEB_DEBUGGER__) {
      // Avo web debugger
      _avo_debugger_log("Y7uhsCETz1", "topup_start", messages, eventPropertiesArray, userPropertiesArray, []);
    }
  }
  if (!__AVO_NOOP__) {
    if (__INSPECTOR__ != null) {
      // @ts-ignore
      __INSPECTOR__._avoFunctionTrackSchemaFromEvent("topup_start", eventProperties, "Y7uhsCETz1", "3c804bcd937324647548789072492c8a09b5bb08654eada3e969b0ef894d64fc");
    }
    // destination PostHog
    PostHog.logEvent("topup_start", (Object as any).assign({}, eventProperties));

    // destination GoogleTagManagerFront
    GoogleTagManagerFront.logEvent("topup_start", (Object as any).assign({}, eventProperties));
  } else {
    // do nothing
  }
}

export interface ViewItemListProperties {
  itemListId: string;
  itemListName: ItemListNameValueType;
  items: Items[];
  senderCountry: string;
  receiverCountry: string;
}
/**
 * view_item_list: Signifies that the users has seen a list of products.
 *
 * @param properties the properties associatied with this event
 * @param properties.itemListId: The ID of the list in which the item was presented to the user.
 * @param properties.itemListName: Where the list of products was shown.
 * @param properties.items: array of the items
 * @param properties.senderCountry: The sender country in ISO 3166-2 format.
 * @param properties.receiverCountry: The receiver country in ISO 3166-2 format.
 *
 * @see {@link https://www.avo.app/schemas/Vw4DbNQPc4e5WGVEh2nX/branches/lE4oRzTE7/events/6ePkWJ0GoT}
 */
export function viewItemList(properties: ViewItemListProperties) {
  // @ts-ignore
  let eventPropertiesArray: array = [];
  eventPropertiesArray.push({id: "zMFClw3wVX", name: "item_list_id", value: properties.itemListId});
  eventPropertiesArray.push({id: "OoZ9-N0h5p", name: "item_list_name", value: properties.itemListName});
  eventPropertiesArray.push({id: "EL97nwQpFG", name: "items", value: properties.items.map(function(value) { return {
      "item_id": value.itemId,
      "item_name": value.itemName,
      "price": value.price,
      "pre_selected": value.preSelected,
      "index": value.index,
      "item_category": value.itemCategory,
      "item_category2": value.itemCategory2,
      "item_list_name": value.itemListName,
      "item_list_id": value.itemListId,
    }
  })});
  eventPropertiesArray.push({id: "hl2iGjzQGU", name: "sender_country", value: properties.senderCountry});
  eventPropertiesArray.push({id: "VrFz3T9HK", name: "receiver_country", value: properties.receiverCountry});
  eventPropertiesArray.push({id: "0DRCc5VQqM", name: "platform", value: sysPlatform});
  let eventProperties = convertPropertiesArrayToMap(eventPropertiesArray)
  // @ts-ignore
  let userPropertiesArray: array = [];
  let userProperties = convertPropertiesArrayToMap(userPropertiesArray)
  // assert properties
  if (__AVO_ENV__ !== AvoEnv.Prod || __WEB_DEBUGGER__) {
    let messages: AvoAssertMessage[] = [];
    messages = messages.concat(assertItems(properties.items));
    // debug console in Avo
    if (!__AVO_NOOP__) {
      _avo_invoke(__AVO_ENV__, "6ePkWJ0GoT", "5fbae3c9cdca65ef0ace03bf3811aa27144b241b2c8daefe81233f9f966be697", messages.map(m => Object.assign({}, {tag: m.tag, propertyId: m.propertyId, additionalProperties: m.additionalProperties, actualType: m.actualType})), 'event');
    }
    InternalAvoLogger.logEventSent("view_item_list", eventProperties, userProperties);
    if (__WEB_DEBUGGER__) {
      // Avo web debugger
      _avo_debugger_log("6ePkWJ0GoT", "view_item_list", messages, eventPropertiesArray, userPropertiesArray, []);
    }
    // @ts-ignore
    if (__AVO_ENV__ !== AvoEnv.Prod && (__STRICT__ === null || __STRICT__)) {
      // throw exception if messages is not empty
      if (messages.length !== 0) {
        throw new Error("Error sending event 'view_item_list': " + messages[0]!.message)
      }
    } else {
      messages.forEach(function(m) {
        console[__REPORT_FAILURE_AS__ || 'error']("[avo] " + m.message);
      });
    }
  }
  if (!__AVO_NOOP__) {
    if (__INSPECTOR__ != null) {
      // @ts-ignore
      __INSPECTOR__._avoFunctionTrackSchemaFromEvent("view_item_list", eventProperties, "6ePkWJ0GoT", "5fbae3c9cdca65ef0ace03bf3811aa27144b241b2c8daefe81233f9f966be697");
    }
    // destination PostHog
    PostHog.logEvent("view_item_list", (Object as any).assign({}, eventProperties));

    // destination GoogleTagManagerFront
    GoogleTagManagerFront.logEvent("view_item_list", (Object as any).assign({}, eventProperties));
  } else {
    // do nothing
  }
}

export interface TypeProductSwitchProperties {
  itemCategory: ItemCategoryValueType;
}
/**
 * type_product_switch: Signifies that the users has switched between topup and plans
 *
 * When to trigger this event:
 * 1. When the users switches between a type of product. Topup or Plan
 * View in Avo: https://www.avo.app/schemas/Vw4DbNQPc4e5WGVEh2nX/branches/lE4oRzTE7/events/R8fcgI-4KU/trigger/ffM6S0DZI
 *
 * @param properties the properties associatied with this event
 * @param properties.itemCategory: First category to describe the item. Plan, Topup
 *
 * @see {@link https://www.avo.app/schemas/Vw4DbNQPc4e5WGVEh2nX/branches/lE4oRzTE7/events/R8fcgI-4KU}
 */
export function typeProductSwitch(properties: TypeProductSwitchProperties) {
  // @ts-ignore
  let eventPropertiesArray: array = [];
  eventPropertiesArray.push({id: "YPVCKfjhSc", name: "item_category", value: properties.itemCategory});
  eventPropertiesArray.push({id: "0DRCc5VQqM", name: "platform", value: sysPlatform});
  let eventProperties = convertPropertiesArrayToMap(eventPropertiesArray)
  // @ts-ignore
  let userPropertiesArray: array = [];
  let userProperties = convertPropertiesArrayToMap(userPropertiesArray)
  // assert properties
  if (__AVO_ENV__ !== AvoEnv.Prod || __WEB_DEBUGGER__) {
    let messages: AvoAssertMessage[] = [];
    // debug console in Avo
    if (!__AVO_NOOP__) {
      _avo_invoke(__AVO_ENV__, "R8fcgI-4KU", "241094b46ef0b94904170c7fed621cb024504f6fc96343626bfe07a2fa26e218", messages.map(m => Object.assign({}, {tag: m.tag, propertyId: m.propertyId, additionalProperties: m.additionalProperties, actualType: m.actualType})), 'event');
    }
    InternalAvoLogger.logEventSent("type_product_switch", eventProperties, userProperties);
    if (__WEB_DEBUGGER__) {
      // Avo web debugger
      _avo_debugger_log("R8fcgI-4KU", "type_product_switch", messages, eventPropertiesArray, userPropertiesArray, []);
    }
  }
  if (!__AVO_NOOP__) {
    if (__INSPECTOR__ != null) {
      // @ts-ignore
      __INSPECTOR__._avoFunctionTrackSchemaFromEvent("type_product_switch", eventProperties, "R8fcgI-4KU", "241094b46ef0b94904170c7fed621cb024504f6fc96343626bfe07a2fa26e218");
    }
    // destination PostHog
    PostHog.logEvent("type_product_switch", (Object as any).assign({}, eventProperties));

    // destination GoogleTagManagerFront
    GoogleTagManagerFront.logEvent("type_product_switch", (Object as any).assign({}, eventProperties));
  } else {
    // do nothing
  }
}

export interface SelectItemProperties {
  preSelected: boolean;
  itemListId: string;
  itemListName: ItemListNameValueType;
  items: Items[];
  senderCountry: string;
  receiverCountry: string;
}
/**
 * select_item: Signifies that an item was selected from the list, by default or by the user.
 *
 * When to trigger this event:
 * 1. When an item is selected, by the user or pre selected.
 * View in Avo: https://www.avo.app/schemas/Vw4DbNQPc4e5WGVEh2nX/branches/lE4oRzTE7/events/TW3ovzujEz/trigger/j6347f76V
 * 2. When an item is selected, by default or by the user.
 * View in Avo: https://www.avo.app/schemas/Vw4DbNQPc4e5WGVEh2nX/branches/lE4oRzTE7/events/TW3ovzujEz/trigger/VHiJfJEst
 * 3. When an item is selected, by the user or by default.
 * View in Avo: https://www.avo.app/schemas/Vw4DbNQPc4e5WGVEh2nX/branches/lE4oRzTE7/events/TW3ovzujEz/trigger/g0pz6wazH
 * 4. When the user selects a promotion, they also select a different item.
 * View in Avo: https://www.avo.app/schemas/Vw4DbNQPc4e5WGVEh2nX/branches/lE4oRzTE7/events/TW3ovzujEz/trigger/qL5J65Z0s
 *
 * @param properties the properties associatied with this event
 * @param properties.preSelected: Wether an item was pre selected by default.
 * @param properties.itemListId: The ID of the list in which the item was presented to the user.
 * @param properties.itemListName: Where the list of products was shown.
 * @param properties.items: array of the items
 * @param properties.senderCountry: The sender country in ISO 3166-2 format.
 * @param properties.receiverCountry: The receiver country in ISO 3166-2 format.
 *
 * @see {@link https://www.avo.app/schemas/Vw4DbNQPc4e5WGVEh2nX/branches/lE4oRzTE7/events/TW3ovzujEz}
 */
export function selectItem(properties: SelectItemProperties) {
  // @ts-ignore
  let eventPropertiesArray: array = [];
  eventPropertiesArray.push({id: "Qo802MK4yi", name: "pre_selected", value: properties.preSelected});
  eventPropertiesArray.push({id: "zMFClw3wVX", name: "item_list_id", value: properties.itemListId});
  eventPropertiesArray.push({id: "OoZ9-N0h5p", name: "item_list_name", value: properties.itemListName});
  eventPropertiesArray.push({id: "EL97nwQpFG", name: "items", value: properties.items.map(function(value) { return {
      "item_id": value.itemId,
      "item_name": value.itemName,
      "price": value.price,
      "pre_selected": value.preSelected,
      "index": value.index,
      "item_category": value.itemCategory,
      "item_category2": value.itemCategory2,
      "item_list_name": value.itemListName,
      "item_list_id": value.itemListId,
    }
  })});
  eventPropertiesArray.push({id: "hl2iGjzQGU", name: "sender_country", value: properties.senderCountry});
  eventPropertiesArray.push({id: "VrFz3T9HK", name: "receiver_country", value: properties.receiverCountry});
  eventPropertiesArray.push({id: "0DRCc5VQqM", name: "platform", value: sysPlatform});
  let eventProperties = convertPropertiesArrayToMap(eventPropertiesArray)
  // @ts-ignore
  let userPropertiesArray: array = [];
  let userProperties = convertPropertiesArrayToMap(userPropertiesArray)
  // assert properties
  if (__AVO_ENV__ !== AvoEnv.Prod || __WEB_DEBUGGER__) {
    let messages: AvoAssertMessage[] = [];
    messages = messages.concat(assertItems(properties.items));
    // debug console in Avo
    if (!__AVO_NOOP__) {
      _avo_invoke(__AVO_ENV__, "TW3ovzujEz", "289f280c24d0898b10fdc10a0b0a440106eeab38bd263b54b66450c318db764f", messages.map(m => Object.assign({}, {tag: m.tag, propertyId: m.propertyId, additionalProperties: m.additionalProperties, actualType: m.actualType})), 'event');
    }
    InternalAvoLogger.logEventSent("select_item", eventProperties, userProperties);
    if (__WEB_DEBUGGER__) {
      // Avo web debugger
      _avo_debugger_log("TW3ovzujEz", "select_item", messages, eventPropertiesArray, userPropertiesArray, []);
    }
    // @ts-ignore
    if (__AVO_ENV__ !== AvoEnv.Prod && (__STRICT__ === null || __STRICT__)) {
      // throw exception if messages is not empty
      if (messages.length !== 0) {
        throw new Error("Error sending event 'select_item': " + messages[0]!.message)
      }
    } else {
      messages.forEach(function(m) {
        console[__REPORT_FAILURE_AS__ || 'error']("[avo] " + m.message);
      });
    }
  }
  if (!__AVO_NOOP__) {
    if (__INSPECTOR__ != null) {
      // @ts-ignore
      __INSPECTOR__._avoFunctionTrackSchemaFromEvent("select_item", eventProperties, "TW3ovzujEz", "289f280c24d0898b10fdc10a0b0a440106eeab38bd263b54b66450c318db764f");
    }
    // destination PostHog
    PostHog.logEvent("select_item", (Object as any).assign({}, eventProperties));

    // destination GoogleTagManagerFront
    GoogleTagManagerFront.logEvent("select_item", (Object as any).assign({}, eventProperties));
  } else {
    // do nothing
  }
}

export interface SelectAutotopupProperties {
  autoPayInterval: string;
}
/**
 * select_autotopup: Signifies the user has selected an autotopup frequency
 *
 * When to trigger this event:
 * 1. When the user has selected an auto tup up frequency by clicking on one of the button.
 * View in Avo: https://www.avo.app/schemas/Vw4DbNQPc4e5WGVEh2nX/branches/lE4oRzTE7/events/l-Q98iIAAO/trigger/We-1S5W9H
 * 2. When the users checks the autopay option
 * View in Avo: https://www.avo.app/schemas/Vw4DbNQPc4e5WGVEh2nX/branches/lE4oRzTE7/events/l-Q98iIAAO/trigger/dIldeIYB1
 *
 * @param properties the properties associatied with this event
 * @param properties.autoPayInterval: The frequency for a recurring payment in days.  for plans 'plan'  'auto_pay_interval' for the topups we should use the value from the UI in days.
 *
 * @see {@link https://www.avo.app/schemas/Vw4DbNQPc4e5WGVEh2nX/branches/lE4oRzTE7/events/l-Q98iIAAO}
 */
export function selectAutotopup(properties: SelectAutotopupProperties) {
  // @ts-ignore
  let eventPropertiesArray: array = [];
  eventPropertiesArray.push({id: "bMqhWZe9Bc", name: "auto_pay_interval", value: properties.autoPayInterval});
  eventPropertiesArray.push({id: "0DRCc5VQqM", name: "platform", value: sysPlatform});
  let eventProperties = convertPropertiesArrayToMap(eventPropertiesArray)
  // @ts-ignore
  let userPropertiesArray: array = [];
  let userProperties = convertPropertiesArrayToMap(userPropertiesArray)
  // assert properties
  if (__AVO_ENV__ !== AvoEnv.Prod || __WEB_DEBUGGER__) {
    let messages: AvoAssertMessage[] = [];
    // debug console in Avo
    if (!__AVO_NOOP__) {
      _avo_invoke(__AVO_ENV__, "l-Q98iIAAO", "9ffd538a59bbdf034aaeb5862976cf1e8acb5b4a35b8e4553fd4fa194add2a78", messages.map(m => Object.assign({}, {tag: m.tag, propertyId: m.propertyId, additionalProperties: m.additionalProperties, actualType: m.actualType})), 'event');
    }
    InternalAvoLogger.logEventSent("select_autotopup", eventProperties, userProperties);
    if (__WEB_DEBUGGER__) {
      // Avo web debugger
      _avo_debugger_log("l-Q98iIAAO", "select_autotopup", messages, eventPropertiesArray, userPropertiesArray, []);
    }
  }
  if (!__AVO_NOOP__) {
    if (__INSPECTOR__ != null) {
      // @ts-ignore
      __INSPECTOR__._avoFunctionTrackSchemaFromEvent("select_autotopup", eventProperties, "l-Q98iIAAO", "9ffd538a59bbdf034aaeb5862976cf1e8acb5b4a35b8e4553fd4fa194add2a78");
    }
    // destination PostHog
    PostHog.logEvent("select_autotopup", (Object as any).assign({}, eventProperties));

    // destination GoogleTagManagerFront
    GoogleTagManagerFront.logEvent("select_autotopup", (Object as any).assign({}, eventProperties));
  } else {
    // do nothing
  }
}

/**
 * view_optional_add_ons: Signifies that the user has viewed an option & addon screen
 *
 * When to trigger this event:
 * 1. When a users sees the options & add-ons
 * View in Avo: https://www.avo.app/schemas/Vw4DbNQPc4e5WGVEh2nX/branches/lE4oRzTE7/events/mFRbao4qie/trigger/t--lzECzhw
 *
 * @see {@link https://www.avo.app/schemas/Vw4DbNQPc4e5WGVEh2nX/branches/lE4oRzTE7/events/mFRbao4qie}
 */
export function viewOptionalAddOns() {
  // @ts-ignore
  let eventPropertiesArray: array = [];
  eventPropertiesArray.push({id: "0DRCc5VQqM", name: "platform", value: sysPlatform});
  let eventProperties = convertPropertiesArrayToMap(eventPropertiesArray)
  // @ts-ignore
  let userPropertiesArray: array = [];
  let userProperties = convertPropertiesArrayToMap(userPropertiesArray)
  // assert properties
  if (__AVO_ENV__ !== AvoEnv.Prod || __WEB_DEBUGGER__) {
    let messages: AvoAssertMessage[] = [];
    // debug console in Avo
    if (!__AVO_NOOP__) {
      _avo_invoke(__AVO_ENV__, "mFRbao4qie", "9a42f57e5b1f78a2ac43124c405217881e73c9372c8c40cb82fa8c74a5465aa6", messages.map(m => Object.assign({}, {tag: m.tag, propertyId: m.propertyId, additionalProperties: m.additionalProperties, actualType: m.actualType})), 'event');
    }
    InternalAvoLogger.logEventSent("view_optional_add_ons", eventProperties, userProperties);
    if (__WEB_DEBUGGER__) {
      // Avo web debugger
      _avo_debugger_log("mFRbao4qie", "view_optional_add_ons", messages, eventPropertiesArray, userPropertiesArray, []);
    }
  }
  if (!__AVO_NOOP__) {
    if (__INSPECTOR__ != null) {
      // @ts-ignore
      __INSPECTOR__._avoFunctionTrackSchemaFromEvent("view_optional_add_ons", eventProperties, "mFRbao4qie", "9a42f57e5b1f78a2ac43124c405217881e73c9372c8c40cb82fa8c74a5465aa6");
    }
    // destination PostHog
    PostHog.logEvent("view_optional_add_ons", (Object as any).assign({}, eventProperties));

    // destination GoogleTagManagerFront
    GoogleTagManagerFront.logEvent("view_optional_add_ons", (Object as any).assign({}, eventProperties));
  } else {
    // do nothing
  }
}

export interface ViewPromotionProperties {
  promotionName: string;
  creativeSlot: CreativeSlotValueType;
  promotionId: string;
  senderCountry: string;
  receiverCountry: string;
}
/**
 * view_promotion: Signifies that the users has seen a promotion
 *
 * When to trigger this event:
 * 1. When a users sees a promotion
 * View in Avo: https://www.avo.app/schemas/Vw4DbNQPc4e5WGVEh2nX/branches/lE4oRzTE7/events/NfMaEqvyXx/trigger/eutty94Mk
 * 2. When the users views a promotion detail.
Creative slot "promotion detail"
 * View in Avo: https://www.avo.app/schemas/Vw4DbNQPc4e5WGVEh2nX/branches/lE4oRzTE7/events/NfMaEqvyXx/trigger/J9-jrzkaw
 *
 * @param properties the properties associatied with this event
 * @param properties.promotionName: The name of the promotion associated with the event.
 * @param properties.creativeSlot: The name of the promotional creative slot associated with the event.
 * @param properties.promotionId: The ID of the promotion associated with the event.

 * @param properties.senderCountry: The sender country in ISO 3166-2 format.
 * @param properties.receiverCountry: The receiver country in ISO 3166-2 format.
 *
 * @see {@link https://www.avo.app/schemas/Vw4DbNQPc4e5WGVEh2nX/branches/lE4oRzTE7/events/NfMaEqvyXx}
 */
export function viewPromotion(properties: ViewPromotionProperties) {
  // @ts-ignore
  let eventPropertiesArray: array = [];
  eventPropertiesArray.push({id: "MxXP1Plj-B", name: "promotion_name", value: properties.promotionName});
  eventPropertiesArray.push({id: "fGv8qjSz13", name: "creative_slot", value: properties.creativeSlot});
  eventPropertiesArray.push({id: "psQY9ByUFx", name: "promotion_id", value: properties.promotionId});
  eventPropertiesArray.push({id: "hl2iGjzQGU", name: "sender_country", value: properties.senderCountry});
  eventPropertiesArray.push({id: "VrFz3T9HK", name: "receiver_country", value: properties.receiverCountry});
  eventPropertiesArray.push({id: "0DRCc5VQqM", name: "platform", value: sysPlatform});
  let eventProperties = convertPropertiesArrayToMap(eventPropertiesArray)
  // @ts-ignore
  let userPropertiesArray: array = [];
  let userProperties = convertPropertiesArrayToMap(userPropertiesArray)
  // assert properties
  if (__AVO_ENV__ !== AvoEnv.Prod || __WEB_DEBUGGER__) {
    let messages: AvoAssertMessage[] = [];
    // debug console in Avo
    if (!__AVO_NOOP__) {
      _avo_invoke(__AVO_ENV__, "NfMaEqvyXx", "2ae39585f570c5f4e4f223dce0bd0843127266e395d86a70b6fd463d12685f74", messages.map(m => Object.assign({}, {tag: m.tag, propertyId: m.propertyId, additionalProperties: m.additionalProperties, actualType: m.actualType})), 'event');
    }
    InternalAvoLogger.logEventSent("view_promotion", eventProperties, userProperties);
    if (__WEB_DEBUGGER__) {
      // Avo web debugger
      _avo_debugger_log("NfMaEqvyXx", "view_promotion", messages, eventPropertiesArray, userPropertiesArray, []);
    }
  }
  if (!__AVO_NOOP__) {
    if (__INSPECTOR__ != null) {
      // @ts-ignore
      __INSPECTOR__._avoFunctionTrackSchemaFromEvent("view_promotion", eventProperties, "NfMaEqvyXx", "2ae39585f570c5f4e4f223dce0bd0843127266e395d86a70b6fd463d12685f74");
    }
    // destination PostHog
    PostHog.logEvent("view_promotion", (Object as any).assign({}, eventProperties));

    // destination GoogleTagManagerFront
    GoogleTagManagerFront.logEvent("view_promotion", (Object as any).assign({}, eventProperties));
  } else {
    // do nothing
  }
}

export interface ButtonClickProperties {
  button: ButtonValueType;
  origin: OriginValueType;
}
/**
 * button_click: Signifies that the users has clicked a button. Via the property we can define which button is clicked.
 *
 * @param properties the properties associatied with this event
 * @param properties.button: The button that was clicked
 * @param properties.origin: Where the event originated from
 *
 * @see {@link https://www.avo.app/schemas/Vw4DbNQPc4e5WGVEh2nX/branches/lE4oRzTE7/events/zTPdIDceaU}
 */
export function buttonClick(properties: ButtonClickProperties) {
  // @ts-ignore
  let eventPropertiesArray: array = [];
  eventPropertiesArray.push({id: "0HsfMty-7g", name: "button", value: properties.button});
  eventPropertiesArray.push({id: "T_WshweU6t", name: "origin", value: properties.origin});
  eventPropertiesArray.push({id: "0DRCc5VQqM", name: "platform", value: sysPlatform});
  let eventProperties = convertPropertiesArrayToMap(eventPropertiesArray)
  // @ts-ignore
  let userPropertiesArray: array = [];
  let userProperties = convertPropertiesArrayToMap(userPropertiesArray)
  // assert properties
  if (__AVO_ENV__ !== AvoEnv.Prod || __WEB_DEBUGGER__) {
    let messages: AvoAssertMessage[] = [];
    // debug console in Avo
    if (!__AVO_NOOP__) {
      _avo_invoke(__AVO_ENV__, "zTPdIDceaU", "2253d2d682e8fefa20210b98123889408cf83b9ecb875db8a63a5b42ac4e8e8a", messages.map(m => Object.assign({}, {tag: m.tag, propertyId: m.propertyId, additionalProperties: m.additionalProperties, actualType: m.actualType})), 'event');
    }
    InternalAvoLogger.logEventSent("button_click", eventProperties, userProperties);
    if (__WEB_DEBUGGER__) {
      // Avo web debugger
      _avo_debugger_log("zTPdIDceaU", "button_click", messages, eventPropertiesArray, userPropertiesArray, []);
    }
  }
  if (!__AVO_NOOP__) {
    if (__INSPECTOR__ != null) {
      // @ts-ignore
      __INSPECTOR__._avoFunctionTrackSchemaFromEvent("button_click", eventProperties, "zTPdIDceaU", "2253d2d682e8fefa20210b98123889408cf83b9ecb875db8a63a5b42ac4e8e8a");
    }
    // destination PostHog
    PostHog.logEvent("button_click", (Object as any).assign({}, eventProperties));

    // destination GoogleTagManagerFront
    GoogleTagManagerFront.logEvent("button_click", (Object as any).assign({}, eventProperties));
  } else {
    // do nothing
  }
}

/**
 * view_authentication: Signifies that the user has viewed the authentication screen
 *
 * When to trigger this event:
 * 1. When the users views the authentication screen
 * View in Avo: https://www.avo.app/schemas/Vw4DbNQPc4e5WGVEh2nX/branches/lE4oRzTE7/events/5Aq4XKsKwA/trigger/rT6aY-Yh1
 *
 * @see {@link https://www.avo.app/schemas/Vw4DbNQPc4e5WGVEh2nX/branches/lE4oRzTE7/events/5Aq4XKsKwA}
 */
export function viewAuthentication() {
  // @ts-ignore
  let eventPropertiesArray: array = [];
  eventPropertiesArray.push({id: "0DRCc5VQqM", name: "platform", value: sysPlatform});
  let eventProperties = convertPropertiesArrayToMap(eventPropertiesArray)
  // @ts-ignore
  let userPropertiesArray: array = [];
  let userProperties = convertPropertiesArrayToMap(userPropertiesArray)
  // assert properties
  if (__AVO_ENV__ !== AvoEnv.Prod || __WEB_DEBUGGER__) {
    let messages: AvoAssertMessage[] = [];
    // debug console in Avo
    if (!__AVO_NOOP__) {
      _avo_invoke(__AVO_ENV__, "5Aq4XKsKwA", "58903b2007efaaa18e83d9e03c9020f82faf99b6f58d019bcb37a4e771a89a98", messages.map(m => Object.assign({}, {tag: m.tag, propertyId: m.propertyId, additionalProperties: m.additionalProperties, actualType: m.actualType})), 'event');
    }
    InternalAvoLogger.logEventSent("view_authentication", eventProperties, userProperties);
    if (__WEB_DEBUGGER__) {
      // Avo web debugger
      _avo_debugger_log("5Aq4XKsKwA", "view_authentication", messages, eventPropertiesArray, userPropertiesArray, []);
    }
  }
  if (!__AVO_NOOP__) {
    if (__INSPECTOR__ != null) {
      // @ts-ignore
      __INSPECTOR__._avoFunctionTrackSchemaFromEvent("view_authentication", eventProperties, "5Aq4XKsKwA", "58903b2007efaaa18e83d9e03c9020f82faf99b6f58d019bcb37a4e771a89a98");
    }
    // destination PostHog
    PostHog.logEvent("view_authentication", (Object as any).assign({}, eventProperties));

    // destination GoogleTagManagerFront
    GoogleTagManagerFront.logEvent("view_authentication", (Object as any).assign({}, eventProperties));
  } else {
    // do nothing
  }
}

export interface ViewTransactionDetailProperties {
  status: StatusValueType;
}
/**
 * view_transaction_detail: Signifiest that the users has viewed the transaction detail of a transaction.
 *
 * When to trigger this event:
 * 1. When a user views the detail of a transaction detail.
 * View in Avo: https://www.avo.app/schemas/Vw4DbNQPc4e5WGVEh2nX/branches/lE4oRzTE7/events/jv2FLflvac/trigger/BihF3Ssc5v
 *
 * @param properties the properties associatied with this event
 * @param properties.status: The status of a transaction
 *
 * @see {@link https://www.avo.app/schemas/Vw4DbNQPc4e5WGVEh2nX/branches/lE4oRzTE7/events/jv2FLflvac}
 */
export function viewTransactionDetail(
  properties: ViewTransactionDetailProperties) {
  // @ts-ignore
  let eventPropertiesArray: array = [];
  eventPropertiesArray.push({id: "3KiVobjXrc", name: "status", value: properties.status});
  eventPropertiesArray.push({id: "0DRCc5VQqM", name: "platform", value: sysPlatform});
  let eventProperties = convertPropertiesArrayToMap(eventPropertiesArray)
  // @ts-ignore
  let userPropertiesArray: array = [];
  let userProperties = convertPropertiesArrayToMap(userPropertiesArray)
  // assert properties
  if (__AVO_ENV__ !== AvoEnv.Prod || __WEB_DEBUGGER__) {
    let messages: AvoAssertMessage[] = [];
    // debug console in Avo
    if (!__AVO_NOOP__) {
      _avo_invoke(__AVO_ENV__, "jv2FLflvac", "98814a04d19d7d166d475304d494df3a33a24fcbf265e56d5a295f4a00aacf31", messages.map(m => Object.assign({}, {tag: m.tag, propertyId: m.propertyId, additionalProperties: m.additionalProperties, actualType: m.actualType})), 'event');
    }
    InternalAvoLogger.logEventSent("view_transaction_detail", eventProperties, userProperties);
    if (__WEB_DEBUGGER__) {
      // Avo web debugger
      _avo_debugger_log("jv2FLflvac", "view_transaction_detail", messages, eventPropertiesArray, userPropertiesArray, []);
    }
  }
  if (!__AVO_NOOP__) {
    if (__INSPECTOR__ != null) {
      // @ts-ignore
      __INSPECTOR__._avoFunctionTrackSchemaFromEvent("view_transaction_detail", eventProperties, "jv2FLflvac", "98814a04d19d7d166d475304d494df3a33a24fcbf265e56d5a295f4a00aacf31");
    }
    // destination PostHog
    PostHog.logEvent("view_transaction_detail", (Object as any).assign({}, eventProperties));

    // destination GoogleTagManagerFront
    GoogleTagManagerFront.logEvent("view_transaction_detail", (Object as any).assign({}, eventProperties));
  } else {
    // do nothing
  }
}

export interface RemoveFrequentPaymentProperties {
  itemId: string;
  autoPayInterval: string;
}
/**
 * remove_frequent_payment: Signifies that the user has removed a frequent payment
 *
 * When to trigger this event:
 * 1. When the user successfully removed the frequent payment by clicking on the remove button.
 * View in Avo: https://www.avo.app/schemas/Vw4DbNQPc4e5WGVEh2nX/branches/lE4oRzTE7/events/Utilnxp0Hl/trigger/pmT20O8qN
 *
 * @param properties the properties associatied with this event
 * @param properties.itemId: The ID of the item.    product_id
 * @param properties.autoPayInterval: The frequency for a recurring payment in days.  for plans 'plan'  'auto_pay_interval' for the topups we should use the value from the UI in days.
 *
 * @see {@link https://www.avo.app/schemas/Vw4DbNQPc4e5WGVEh2nX/branches/lE4oRzTE7/events/Utilnxp0Hl}
 */
export function removeFrequentPayment(
  properties: RemoveFrequentPaymentProperties) {
  // @ts-ignore
  let eventPropertiesArray: array = [];
  eventPropertiesArray.push({id: "yX-ShCKuUp", name: "item_id", value: properties.itemId});
  eventPropertiesArray.push({id: "bMqhWZe9Bc", name: "auto_pay_interval", value: properties.autoPayInterval});
  eventPropertiesArray.push({id: "0DRCc5VQqM", name: "platform", value: sysPlatform});
  let eventProperties = convertPropertiesArrayToMap(eventPropertiesArray)
  // @ts-ignore
  let userPropertiesArray: array = [];
  let userProperties = convertPropertiesArrayToMap(userPropertiesArray)
  // assert properties
  if (__AVO_ENV__ !== AvoEnv.Prod || __WEB_DEBUGGER__) {
    let messages: AvoAssertMessage[] = [];
    // debug console in Avo
    if (!__AVO_NOOP__) {
      _avo_invoke(__AVO_ENV__, "Utilnxp0Hl", "85d06bef84b014e85d776ebc527e61d2f8b88650e8eb3c2db52adea3df9f2675", messages.map(m => Object.assign({}, {tag: m.tag, propertyId: m.propertyId, additionalProperties: m.additionalProperties, actualType: m.actualType})), 'event');
    }
    InternalAvoLogger.logEventSent("remove_frequent_payment", eventProperties, userProperties);
    if (__WEB_DEBUGGER__) {
      // Avo web debugger
      _avo_debugger_log("Utilnxp0Hl", "remove_frequent_payment", messages, eventPropertiesArray, userPropertiesArray, []);
    }
  }
  if (!__AVO_NOOP__) {
    if (__INSPECTOR__ != null) {
      // @ts-ignore
      __INSPECTOR__._avoFunctionTrackSchemaFromEvent("remove_frequent_payment", eventProperties, "Utilnxp0Hl", "85d06bef84b014e85d776ebc527e61d2f8b88650e8eb3c2db52adea3df9f2675");
    }
    // destination PostHog
    PostHog.logEvent("remove_frequent_payment", (Object as any).assign({}, eventProperties));

    // destination GoogleTagManagerFront
    GoogleTagManagerFront.logEvent("remove_frequent_payment", (Object as any).assign({}, eventProperties));
  } else {
    // do nothing
  }
}

export interface ViewFrequentPaymentsProperties {
  amount: number;
}
/**
 * view_frequent_payments: Signifies the user has seen the frequent payments
 *
 * When to trigger this event:
 * 1. When the users views the frequent payments screen
 * View in Avo: https://www.avo.app/schemas/Vw4DbNQPc4e5WGVEh2nX/branches/lE4oRzTE7/events/H2I0-pQPzi/trigger/bcrVQHaU3
 *
 * @param properties the properties associatied with this event
 * @param properties.amount: The amount of results
 *
 * @see {@link https://www.avo.app/schemas/Vw4DbNQPc4e5WGVEh2nX/branches/lE4oRzTE7/events/H2I0-pQPzi}
 */
export function viewFrequentPayments(
  properties: ViewFrequentPaymentsProperties) {
  // @ts-ignore
  let eventPropertiesArray: array = [];
  eventPropertiesArray.push({id: "nmsIzCA_kX", name: "amount", value: properties.amount});
  eventPropertiesArray.push({id: "0DRCc5VQqM", name: "platform", value: sysPlatform});
  let eventProperties = convertPropertiesArrayToMap(eventPropertiesArray)
  // @ts-ignore
  let userPropertiesArray: array = [];
  let userProperties = convertPropertiesArrayToMap(userPropertiesArray)
  // assert properties
  if (__AVO_ENV__ !== AvoEnv.Prod || __WEB_DEBUGGER__) {
    let messages: AvoAssertMessage[] = [];
    // debug console in Avo
    if (!__AVO_NOOP__) {
      _avo_invoke(__AVO_ENV__, "H2I0-pQPzi", "079ddf08eff6d06b2df314d89be6dfb61ad3207227937dbdb90ea87316b0377c", messages.map(m => Object.assign({}, {tag: m.tag, propertyId: m.propertyId, additionalProperties: m.additionalProperties, actualType: m.actualType})), 'event');
    }
    InternalAvoLogger.logEventSent("view_frequent_payments", eventProperties, userProperties);
    if (__WEB_DEBUGGER__) {
      // Avo web debugger
      _avo_debugger_log("H2I0-pQPzi", "view_frequent_payments", messages, eventPropertiesArray, userPropertiesArray, []);
    }
  }
  if (!__AVO_NOOP__) {
    if (__INSPECTOR__ != null) {
      // @ts-ignore
      __INSPECTOR__._avoFunctionTrackSchemaFromEvent("view_frequent_payments", eventProperties, "H2I0-pQPzi", "079ddf08eff6d06b2df314d89be6dfb61ad3207227937dbdb90ea87316b0377c");
    }
    // destination PostHog
    PostHog.logEvent("view_frequent_payments", (Object as any).assign({}, eventProperties));

    // destination GoogleTagManagerFront
    GoogleTagManagerFront.logEvent("view_frequent_payments", (Object as any).assign({}, eventProperties));
  } else {
    // do nothing
  }
}

export interface ViewTransactionHistoryProperties {
  amount: number;
}
/**
 * view_transaction_history: Signifies that the users has seen the the transaction history list
 *
 * When to trigger this event:
 * 1. When a users views the transaction history.
 * View in Avo: https://www.avo.app/schemas/Vw4DbNQPc4e5WGVEh2nX/branches/lE4oRzTE7/events/Tkyiu2QX76/trigger/sQWdbvUSEz
 *
 * @param properties the properties associatied with this event
 * @param properties.amount: The amount of results
 *
 * @see {@link https://www.avo.app/schemas/Vw4DbNQPc4e5WGVEh2nX/branches/lE4oRzTE7/events/Tkyiu2QX76}
 */
export function viewTransactionHistory(
  properties: ViewTransactionHistoryProperties) {
  // @ts-ignore
  let eventPropertiesArray: array = [];
  eventPropertiesArray.push({id: "nmsIzCA_kX", name: "amount", value: properties.amount});
  eventPropertiesArray.push({id: "0DRCc5VQqM", name: "platform", value: sysPlatform});
  let eventProperties = convertPropertiesArrayToMap(eventPropertiesArray)
  // @ts-ignore
  let userPropertiesArray: array = [];
  let userProperties = convertPropertiesArrayToMap(userPropertiesArray)
  // assert properties
  if (__AVO_ENV__ !== AvoEnv.Prod || __WEB_DEBUGGER__) {
    let messages: AvoAssertMessage[] = [];
    // debug console in Avo
    if (!__AVO_NOOP__) {
      _avo_invoke(__AVO_ENV__, "Tkyiu2QX76", "5f741824ab6f560ab30e8bddc7254ca886472079d9fd93334fd7edbe8bcdaa73", messages.map(m => Object.assign({}, {tag: m.tag, propertyId: m.propertyId, additionalProperties: m.additionalProperties, actualType: m.actualType})), 'event');
    }
    InternalAvoLogger.logEventSent("view_transaction_history", eventProperties, userProperties);
    if (__WEB_DEBUGGER__) {
      // Avo web debugger
      _avo_debugger_log("Tkyiu2QX76", "view_transaction_history", messages, eventPropertiesArray, userPropertiesArray, []);
    }
  }
  if (!__AVO_NOOP__) {
    if (__INSPECTOR__ != null) {
      // @ts-ignore
      __INSPECTOR__._avoFunctionTrackSchemaFromEvent("view_transaction_history", eventProperties, "Tkyiu2QX76", "5f741824ab6f560ab30e8bddc7254ca886472079d9fd93334fd7edbe8bcdaa73");
    }
    // destination PostHog
    PostHog.logEvent("view_transaction_history", (Object as any).assign({}, eventProperties));

    // destination GoogleTagManagerFront
    GoogleTagManagerFront.logEvent("view_transaction_history", (Object as any).assign({}, eventProperties));
  } else {
    // do nothing
  }
}

/**
 * view_account: Signifies the user has viewed their account
 *
 * When to trigger this event:
 * 1.
 * View in Avo: https://www.avo.app/schemas/Vw4DbNQPc4e5WGVEh2nX/branches/lE4oRzTE7/events/lazc062nkC/trigger/Ox1IeW1Qz
 *
 * @see {@link https://www.avo.app/schemas/Vw4DbNQPc4e5WGVEh2nX/branches/lE4oRzTE7/events/lazc062nkC}
 */
export function viewAccount() {
  // @ts-ignore
  let eventPropertiesArray: array = [];
  eventPropertiesArray.push({id: "0DRCc5VQqM", name: "platform", value: sysPlatform});
  let eventProperties = convertPropertiesArrayToMap(eventPropertiesArray)
  // @ts-ignore
  let userPropertiesArray: array = [];
  let userProperties = convertPropertiesArrayToMap(userPropertiesArray)
  // assert properties
  if (__AVO_ENV__ !== AvoEnv.Prod || __WEB_DEBUGGER__) {
    let messages: AvoAssertMessage[] = [];
    // debug console in Avo
    if (!__AVO_NOOP__) {
      _avo_invoke(__AVO_ENV__, "lazc062nkC", "6c8a4eec105ac512dec49a3493ca99215807382c3bbb1f23a3c69b58cbd5e600", messages.map(m => Object.assign({}, {tag: m.tag, propertyId: m.propertyId, additionalProperties: m.additionalProperties, actualType: m.actualType})), 'event');
    }
    InternalAvoLogger.logEventSent("view_account", eventProperties, userProperties);
    if (__WEB_DEBUGGER__) {
      // Avo web debugger
      _avo_debugger_log("lazc062nkC", "view_account", messages, eventPropertiesArray, userPropertiesArray, []);
    }
  }
  if (!__AVO_NOOP__) {
    if (__INSPECTOR__ != null) {
      // @ts-ignore
      __INSPECTOR__._avoFunctionTrackSchemaFromEvent("view_account", eventProperties, "lazc062nkC", "6c8a4eec105ac512dec49a3493ca99215807382c3bbb1f23a3c69b58cbd5e600");
    }
    // destination PostHog
    PostHog.logEvent("view_account", (Object as any).assign({}, eventProperties));

    // destination GoogleTagManagerFront
    GoogleTagManagerFront.logEvent("view_account", (Object as any).assign({}, eventProperties));
  } else {
    // do nothing
  }
}

export interface SignUpProperties {
  method: MethodValueType;
}
/**
 * sign_up: Signifies the user has signed up
 *
 * When to trigger this event:
 * 1. When the users successfully signed up
 * View in Avo: https://www.avo.app/schemas/Vw4DbNQPc4e5WGVEh2nX/branches/lE4oRzTE7/events/-S4E-p3xj9/trigger/JBhv24hll
 *
 * @param properties the properties associatied with this event
 * @param properties.method: the authentication method of the user. 'provider_key' for social login and for email
 *
 * @see {@link https://www.avo.app/schemas/Vw4DbNQPc4e5WGVEh2nX/branches/lE4oRzTE7/events/-S4E-p3xj9}
 */
export function signUp(properties: SignUpProperties) {
  // @ts-ignore
  let eventPropertiesArray: array = [];
  eventPropertiesArray.push({id: "8sWXOlu-q2", name: "method", value: properties.method});
  eventPropertiesArray.push({id: "0DRCc5VQqM", name: "platform", value: sysPlatform});
  let eventProperties = convertPropertiesArrayToMap(eventPropertiesArray)
  // @ts-ignore
  let userPropertiesArray: array = [];
  let userProperties = convertPropertiesArrayToMap(userPropertiesArray)
  // assert properties
  if (__AVO_ENV__ !== AvoEnv.Prod || __WEB_DEBUGGER__) {
    let messages: AvoAssertMessage[] = [];
    // debug console in Avo
    if (!__AVO_NOOP__) {
      _avo_invoke(__AVO_ENV__, "-S4E-p3xj9", "3fac58427d7e933cf4f1705e21d81a2d2c3418ccd9b7a2c5cd547a66f12f065a", messages.map(m => Object.assign({}, {tag: m.tag, propertyId: m.propertyId, additionalProperties: m.additionalProperties, actualType: m.actualType})), 'event');
    }
    InternalAvoLogger.logEventSent("sign_up", eventProperties, userProperties);
    if (__WEB_DEBUGGER__) {
      // Avo web debugger
      _avo_debugger_log("-S4E-p3xj9", "sign_up", messages, eventPropertiesArray, userPropertiesArray, []);
    }
  }
  if (!__AVO_NOOP__) {
    if (__INSPECTOR__ != null) {
      // @ts-ignore
      __INSPECTOR__._avoFunctionTrackSchemaFromEvent("sign_up", eventProperties, "-S4E-p3xj9", "3fac58427d7e933cf4f1705e21d81a2d2c3418ccd9b7a2c5cd547a66f12f065a");
    }
    // destination PostHog
    PostHog.logEvent("sign_up", (Object as any).assign({}, eventProperties));

    // destination GoogleTagManagerFront
    GoogleTagManagerFront.logEvent("sign_up", (Object as any).assign({}, eventProperties));
  } else {
    // do nothing
  }
}

/**
 * view_sign_up: signifies the uses has seen the sign up screen
 *
 * When to trigger this event:
 * 1. When the users sees the form to sign up
 * View in Avo: https://www.avo.app/schemas/Vw4DbNQPc4e5WGVEh2nX/branches/lE4oRzTE7/events/K48k9CTWfY/trigger/MJfXAsh-M
 *
 * @see {@link https://www.avo.app/schemas/Vw4DbNQPc4e5WGVEh2nX/branches/lE4oRzTE7/events/K48k9CTWfY}
 */
export function viewSignUp() {
  // @ts-ignore
  let eventPropertiesArray: array = [];
  eventPropertiesArray.push({id: "0DRCc5VQqM", name: "platform", value: sysPlatform});
  let eventProperties = convertPropertiesArrayToMap(eventPropertiesArray)
  // @ts-ignore
  let userPropertiesArray: array = [];
  let userProperties = convertPropertiesArrayToMap(userPropertiesArray)
  // assert properties
  if (__AVO_ENV__ !== AvoEnv.Prod || __WEB_DEBUGGER__) {
    let messages: AvoAssertMessage[] = [];
    // debug console in Avo
    if (!__AVO_NOOP__) {
      _avo_invoke(__AVO_ENV__, "K48k9CTWfY", "5301fa18fb7197e7a870bc23d8083287d02b2fe9a5b0ee410e13e9d5047eea73", messages.map(m => Object.assign({}, {tag: m.tag, propertyId: m.propertyId, additionalProperties: m.additionalProperties, actualType: m.actualType})), 'event');
    }
    InternalAvoLogger.logEventSent("view_sign_up", eventProperties, userProperties);
    if (__WEB_DEBUGGER__) {
      // Avo web debugger
      _avo_debugger_log("K48k9CTWfY", "view_sign_up", messages, eventPropertiesArray, userPropertiesArray, []);
    }
  }
  if (!__AVO_NOOP__) {
    if (__INSPECTOR__ != null) {
      // @ts-ignore
      __INSPECTOR__._avoFunctionTrackSchemaFromEvent("view_sign_up", eventProperties, "K48k9CTWfY", "5301fa18fb7197e7a870bc23d8083287d02b2fe9a5b0ee410e13e9d5047eea73");
    }
    // destination PostHog
    PostHog.logEvent("view_sign_up", (Object as any).assign({}, eventProperties));

    // destination GoogleTagManagerFront
    GoogleTagManagerFront.logEvent("view_sign_up", (Object as any).assign({}, eventProperties));
  } else {
    // do nothing
  }
}

/**
 * password_forgot: Signifies that the users has forgotten their password and has send an email to reset the password.
 *
 * When to trigger this event:
 * 1. When the user has successfully send themselves an email to reset the password.
 * View in Avo: https://www.avo.app/schemas/Vw4DbNQPc4e5WGVEh2nX/branches/lE4oRzTE7/events/cV496dLEqS/trigger/mtH8UD4ip
 *
 * @see {@link https://www.avo.app/schemas/Vw4DbNQPc4e5WGVEh2nX/branches/lE4oRzTE7/events/cV496dLEqS}
 */
export function passwordForgot() {
  // @ts-ignore
  let eventPropertiesArray: array = [];
  eventPropertiesArray.push({id: "0DRCc5VQqM", name: "platform", value: sysPlatform});
  let eventProperties = convertPropertiesArrayToMap(eventPropertiesArray)
  // @ts-ignore
  let userPropertiesArray: array = [];
  let userProperties = convertPropertiesArrayToMap(userPropertiesArray)
  // assert properties
  if (__AVO_ENV__ !== AvoEnv.Prod || __WEB_DEBUGGER__) {
    let messages: AvoAssertMessage[] = [];
    // debug console in Avo
    if (!__AVO_NOOP__) {
      _avo_invoke(__AVO_ENV__, "cV496dLEqS", "22d0a5ddbae6c68efa225f3f2743e2388ee05ac4e72b73e7b6d4e152790c7c35", messages.map(m => Object.assign({}, {tag: m.tag, propertyId: m.propertyId, additionalProperties: m.additionalProperties, actualType: m.actualType})), 'event');
    }
    InternalAvoLogger.logEventSent("password_forgot", eventProperties, userProperties);
    if (__WEB_DEBUGGER__) {
      // Avo web debugger
      _avo_debugger_log("cV496dLEqS", "password_forgot", messages, eventPropertiesArray, userPropertiesArray, []);
    }
  }
  if (!__AVO_NOOP__) {
    if (__INSPECTOR__ != null) {
      // @ts-ignore
      __INSPECTOR__._avoFunctionTrackSchemaFromEvent("password_forgot", eventProperties, "cV496dLEqS", "22d0a5ddbae6c68efa225f3f2743e2388ee05ac4e72b73e7b6d4e152790c7c35");
    }
    // destination PostHog
    PostHog.logEvent("password_forgot", (Object as any).assign({}, eventProperties));

    // destination GoogleTagManagerFront
    GoogleTagManagerFront.logEvent("password_forgot", (Object as any).assign({}, eventProperties));
  } else {
    // do nothing
  }
}

export interface ErrorLoggedProperties {
  errorMessage: string;
  errorCode: string;
  errorCategory: ErrorCategoryValueType;
  autopayEnabled: boolean | null | undefined;
  paymentType: PaymentTypeValueType | null | undefined;
  itemId: string | null | undefined;
}
/**
 * error_logged: This signifies that the user has encountered an error
 *
 * @param properties the properties associatied with this event
 * @param properties.errorMessage: The error message dat was exposed to the client but max 100 char
 * @param properties.errorCode: The error code for the error
 * @param properties.errorCategory: The category of the error
 * @param properties.autopayEnabled: Autopay enabled for either top-up or plan
 * @param properties.paymentType: The chosen method of payment.    'payment_method'
 * @param properties.itemId: The ID of the item.    product_id
 *
 * @see {@link https://www.avo.app/schemas/Vw4DbNQPc4e5WGVEh2nX/branches/lE4oRzTE7/events/cnXpcD0PIE}
 */
export function errorLogged(properties: ErrorLoggedProperties) {
  // @ts-ignore
  let eventPropertiesArray: array = [];
  eventPropertiesArray.push({id: "eP8ozuvvYN", name: "error_message", value: properties.errorMessage});
  eventPropertiesArray.push({id: "3sqWsNPwdx", name: "error_code", value: properties.errorCode});
  eventPropertiesArray.push({id: "6Sy7U_3xqE", name: "error_category", value: properties.errorCategory});
  properties.autopayEnabled !== undefined && properties.autopayEnabled !== null ?
    eventPropertiesArray.push({id: "7wbhhr6QC", name: "autopay_enabled", value: properties.autopayEnabled}) :
    eventPropertiesArray.push({id: "7wbhhr6QC", name: "autopay_enabled", value: null});
  properties.paymentType !== undefined && properties.paymentType !== null ?
    eventPropertiesArray.push({id: "rBA97j-2pA", name: "payment_type", value: properties.paymentType}) :
    eventPropertiesArray.push({id: "rBA97j-2pA", name: "payment_type", value: null});
  properties.itemId !== undefined && properties.itemId !== null ?
    eventPropertiesArray.push({id: "yX-ShCKuUp", name: "item_id", value: properties.itemId}) :
    eventPropertiesArray.push({id: "yX-ShCKuUp", name: "item_id", value: null});
  eventPropertiesArray.push({id: "0DRCc5VQqM", name: "platform", value: sysPlatform});
  let eventProperties = convertPropertiesArrayToMap(eventPropertiesArray)
  // @ts-ignore
  let userPropertiesArray: array = [];
  let userProperties = convertPropertiesArrayToMap(userPropertiesArray)
  // assert properties
  if (__AVO_ENV__ !== AvoEnv.Prod || __WEB_DEBUGGER__) {
    let messages: AvoAssertMessage[] = [];
    // debug console in Avo
    if (!__AVO_NOOP__) {
      _avo_invoke(__AVO_ENV__, "cnXpcD0PIE", "def0a64a6a95b91549a820bbf2f31625ddb38e69554c856b46bdd47f83fe405b", messages.map(m => Object.assign({}, {tag: m.tag, propertyId: m.propertyId, additionalProperties: m.additionalProperties, actualType: m.actualType})), 'event');
    }
    InternalAvoLogger.logEventSent("error_logged", eventProperties, userProperties);
    if (__WEB_DEBUGGER__) {
      // Avo web debugger
      _avo_debugger_log("cnXpcD0PIE", "error_logged", messages, eventPropertiesArray, userPropertiesArray, []);
    }
  }
  if (!__AVO_NOOP__) {
    if (__INSPECTOR__ != null) {
      // @ts-ignore
      __INSPECTOR__._avoFunctionTrackSchemaFromEvent("error_logged", eventProperties, "cnXpcD0PIE", "def0a64a6a95b91549a820bbf2f31625ddb38e69554c856b46bdd47f83fe405b");
    }
    // destination PostHog
    PostHog.logEvent("error_logged", (Object as any).assign({}, eventProperties));

    // destination GoogleTagManagerFront
    GoogleTagManagerFront.logEvent("error_logged", (Object as any).assign({}, eventProperties));
  } else {
    // do nothing
  }
}

/**
 * email_verification_sent: This signifies that an email was sent to a user to verify
 *
 * When to trigger this event:
 * 1. When the email has been sent to the user
 * View in Avo: https://www.avo.app/schemas/Vw4DbNQPc4e5WGVEh2nX/branches/lE4oRzTE7/events/bdBY64Wlgl/trigger/12cNPDmAJ
 *
 * @see {@link https://www.avo.app/schemas/Vw4DbNQPc4e5WGVEh2nX/branches/lE4oRzTE7/events/bdBY64Wlgl}
 */
export function emailVerificationSent() {
  // @ts-ignore
  let eventPropertiesArray: array = [];
  eventPropertiesArray.push({id: "0DRCc5VQqM", name: "platform", value: sysPlatform});
  let eventProperties = convertPropertiesArrayToMap(eventPropertiesArray)
  // @ts-ignore
  let userPropertiesArray: array = [];
  let userProperties = convertPropertiesArrayToMap(userPropertiesArray)
  // assert properties
  if (__AVO_ENV__ !== AvoEnv.Prod || __WEB_DEBUGGER__) {
    let messages: AvoAssertMessage[] = [];
    // debug console in Avo
    if (!__AVO_NOOP__) {
      _avo_invoke(__AVO_ENV__, "bdBY64Wlgl", "412db67d1edd96f13cf8754612c8402f88a1cf2fd4062b0f085d6775a72d9834", messages.map(m => Object.assign({}, {tag: m.tag, propertyId: m.propertyId, additionalProperties: m.additionalProperties, actualType: m.actualType})), 'event');
    }
    InternalAvoLogger.logEventSent("email_verification_sent", eventProperties, userProperties);
    if (__WEB_DEBUGGER__) {
      // Avo web debugger
      _avo_debugger_log("bdBY64Wlgl", "email_verification_sent", messages, eventPropertiesArray, userPropertiesArray, []);
    }
  }
  if (!__AVO_NOOP__) {
    if (__INSPECTOR__ != null) {
      // @ts-ignore
      __INSPECTOR__._avoFunctionTrackSchemaFromEvent("email_verification_sent", eventProperties, "bdBY64Wlgl", "412db67d1edd96f13cf8754612c8402f88a1cf2fd4062b0f085d6775a72d9834");
    }
    // destination PostHog
    PostHog.logEvent("email_verification_sent", (Object as any).assign({}, eventProperties));

    // destination GoogleTagManagerFront
    GoogleTagManagerFront.logEvent("email_verification_sent", (Object as any).assign({}, eventProperties));
  } else {
    // do nothing
  }
}

/**
 * email_verification_verified: This signifies that the user has verified their email by clicking on the link in the email.
 *
 * @see {@link https://www.avo.app/schemas/Vw4DbNQPc4e5WGVEh2nX/branches/lE4oRzTE7/events/Y92f8w9ztw}
 */
export function emailVerificationVerified() {
  // @ts-ignore
  let eventPropertiesArray: array = [];
  eventPropertiesArray.push({id: "0DRCc5VQqM", name: "platform", value: sysPlatform});
  let eventProperties = convertPropertiesArrayToMap(eventPropertiesArray)
  // @ts-ignore
  let userPropertiesArray: array = [];
  let userProperties = convertPropertiesArrayToMap(userPropertiesArray)
  // assert properties
  if (__AVO_ENV__ !== AvoEnv.Prod || __WEB_DEBUGGER__) {
    let messages: AvoAssertMessage[] = [];
    // debug console in Avo
    if (!__AVO_NOOP__) {
      _avo_invoke(__AVO_ENV__, "Y92f8w9ztw", "1e66a3a44e8c818fc01677a976d96c7e407fb5b9fd4331f3629be2552bd89e53", messages.map(m => Object.assign({}, {tag: m.tag, propertyId: m.propertyId, additionalProperties: m.additionalProperties, actualType: m.actualType})), 'event');
    }
    InternalAvoLogger.logEventSent("email_verification_verified", eventProperties, userProperties);
    if (__WEB_DEBUGGER__) {
      // Avo web debugger
      _avo_debugger_log("Y92f8w9ztw", "email_verification_verified", messages, eventPropertiesArray, userPropertiesArray, []);
    }
  }
  if (!__AVO_NOOP__) {
    if (__INSPECTOR__ != null) {
      // @ts-ignore
      __INSPECTOR__._avoFunctionTrackSchemaFromEvent("email_verification_verified", eventProperties, "Y92f8w9ztw", "1e66a3a44e8c818fc01677a976d96c7e407fb5b9fd4331f3629be2552bd89e53");
    }
    // destination PostHog
    PostHog.logEvent("email_verification_verified", (Object as any).assign({}, eventProperties));

    // destination GoogleTagManagerFront
    GoogleTagManagerFront.logEvent("email_verification_verified", (Object as any).assign({}, eventProperties));
  } else {
    // do nothing
  }
}

export interface ViewPaymentOptionsProperties {
  receiverCountry: string;
  senderCountry: string;
}
/**
 * view_payment_options: This signifies that the user has seen the payment options
 *
 * When to trigger this event:
 * 1.
 * View in Avo: https://www.avo.app/schemas/Vw4DbNQPc4e5WGVEh2nX/branches/lE4oRzTE7/events/jY0zfadHez/trigger/ejbG7Zgk9
 * 2.
 * View in Avo: https://www.avo.app/schemas/Vw4DbNQPc4e5WGVEh2nX/branches/lE4oRzTE7/events/jY0zfadHez/trigger/qkTjATQ1u
 *
 * @param properties the properties associatied with this event
 * @param properties.receiverCountry: The receiver country in ISO 3166-2 format.
 * @param properties.senderCountry: The sender country in ISO 3166-2 format.
 *
 * @see {@link https://www.avo.app/schemas/Vw4DbNQPc4e5WGVEh2nX/branches/lE4oRzTE7/events/jY0zfadHez}
 */
export function viewPaymentOptions(properties: ViewPaymentOptionsProperties) {
  // @ts-ignore
  let eventPropertiesArray: array = [];
  eventPropertiesArray.push({id: "VrFz3T9HK", name: "receiver_country", value: properties.receiverCountry});
  eventPropertiesArray.push({id: "hl2iGjzQGU", name: "sender_country", value: properties.senderCountry});
  eventPropertiesArray.push({id: "0DRCc5VQqM", name: "platform", value: sysPlatform});
  let eventProperties = convertPropertiesArrayToMap(eventPropertiesArray)
  // @ts-ignore
  let userPropertiesArray: array = [];
  let userProperties = convertPropertiesArrayToMap(userPropertiesArray)
  // assert properties
  if (__AVO_ENV__ !== AvoEnv.Prod || __WEB_DEBUGGER__) {
    let messages: AvoAssertMessage[] = [];
    // debug console in Avo
    if (!__AVO_NOOP__) {
      _avo_invoke(__AVO_ENV__, "jY0zfadHez", "6f9aad33b4840571a28cea92d3ed3b0398c5a197f0ffeca9da345da0484c7990", messages.map(m => Object.assign({}, {tag: m.tag, propertyId: m.propertyId, additionalProperties: m.additionalProperties, actualType: m.actualType})), 'event');
    }
    InternalAvoLogger.logEventSent("view_payment_options", eventProperties, userProperties);
    if (__WEB_DEBUGGER__) {
      // Avo web debugger
      _avo_debugger_log("jY0zfadHez", "view_payment_options", messages, eventPropertiesArray, userPropertiesArray, []);
    }
  }
  if (!__AVO_NOOP__) {
    if (__INSPECTOR__ != null) {
      // @ts-ignore
      __INSPECTOR__._avoFunctionTrackSchemaFromEvent("view_payment_options", eventProperties, "jY0zfadHez", "6f9aad33b4840571a28cea92d3ed3b0398c5a197f0ffeca9da345da0484c7990");
    }
    // destination PostHog
    PostHog.logEvent("view_payment_options", (Object as any).assign({}, eventProperties));

    // destination GoogleTagManagerFront
    GoogleTagManagerFront.logEvent("view_payment_options", (Object as any).assign({}, eventProperties));
  } else {
    // do nothing
  }
}

export interface SelectPaymentOptionProperties {
  paymentType: PaymentTypeValueType;
  savedPayment: boolean;
  senderCountry: string;
  receiverCountry: string;
}
/**
 * select_payment_option: This signifies that the user has selected a payment options
 *
 * When to trigger this event:
 * 1. When the user has selected a payment option and successfully continues to the next step.
 * View in Avo: https://www.avo.app/schemas/Vw4DbNQPc4e5WGVEh2nX/branches/lE4oRzTE7/events/nFBmBtOCaI/trigger/Qn06isOVK
 *
 * @param properties the properties associatied with this event
 * @param properties.paymentType: The chosen method of payment.    'payment_method'
 * @param properties.savedPayment: If the payment option was a previously saved card
 * @param properties.senderCountry: The sender country in ISO 3166-2 format.
 * @param properties.receiverCountry: The receiver country in ISO 3166-2 format.
 *
 * @see {@link https://www.avo.app/schemas/Vw4DbNQPc4e5WGVEh2nX/branches/lE4oRzTE7/events/nFBmBtOCaI}
 */
export function selectPaymentOption(properties: SelectPaymentOptionProperties
  ) {
  // @ts-ignore
  let eventPropertiesArray: array = [];
  eventPropertiesArray.push({id: "rBA97j-2pA", name: "payment_type", value: properties.paymentType});
  eventPropertiesArray.push({id: "QkvYGRZ1kZ", name: "saved_payment", value: properties.savedPayment});
  eventPropertiesArray.push({id: "hl2iGjzQGU", name: "sender_country", value: properties.senderCountry});
  eventPropertiesArray.push({id: "VrFz3T9HK", name: "receiver_country", value: properties.receiverCountry});
  eventPropertiesArray.push({id: "0DRCc5VQqM", name: "platform", value: sysPlatform});
  let eventProperties = convertPropertiesArrayToMap(eventPropertiesArray)
  // @ts-ignore
  let userPropertiesArray: array = [];
  let userProperties = convertPropertiesArrayToMap(userPropertiesArray)
  // assert properties
  if (__AVO_ENV__ !== AvoEnv.Prod || __WEB_DEBUGGER__) {
    let messages: AvoAssertMessage[] = [];
    // debug console in Avo
    if (!__AVO_NOOP__) {
      _avo_invoke(__AVO_ENV__, "nFBmBtOCaI", "d6d7e294b209ff2edbbb620684bf0e3279e4bd5c5db819734fa5e79a5602a0cf", messages.map(m => Object.assign({}, {tag: m.tag, propertyId: m.propertyId, additionalProperties: m.additionalProperties, actualType: m.actualType})), 'event');
    }
    InternalAvoLogger.logEventSent("select_payment_option", eventProperties, userProperties);
    if (__WEB_DEBUGGER__) {
      // Avo web debugger
      _avo_debugger_log("nFBmBtOCaI", "select_payment_option", messages, eventPropertiesArray, userPropertiesArray, []);
    }
  }
  if (!__AVO_NOOP__) {
    if (__INSPECTOR__ != null) {
      // @ts-ignore
      __INSPECTOR__._avoFunctionTrackSchemaFromEvent("select_payment_option", eventProperties, "nFBmBtOCaI", "d6d7e294b209ff2edbbb620684bf0e3279e4bd5c5db819734fa5e79a5602a0cf");
    }
    // destination PostHog
    PostHog.logEvent("select_payment_option", (Object as any).assign({}, eventProperties));

    // destination GoogleTagManagerFront
    GoogleTagManagerFront.logEvent("select_payment_option", (Object as any).assign({}, eventProperties));
  } else {
    // do nothing
  }
}

/**
 * card_removed: This signifies
 that the user has successfully removed a saved card
 *
 * When to trigger this event:
 * 1. When the users has successfully removed the card after pressing the button.
 * View in Avo: https://www.avo.app/schemas/Vw4DbNQPc4e5WGVEh2nX/branches/lE4oRzTE7/events/2ed8pI4jW_/trigger/A2lFmFYey
 *
 * @see {@link https://www.avo.app/schemas/Vw4DbNQPc4e5WGVEh2nX/branches/lE4oRzTE7/events/2ed8pI4jW_}
 */
export function cardRemoved() {
  // @ts-ignore
  let eventPropertiesArray: array = [];
  eventPropertiesArray.push({id: "0DRCc5VQqM", name: "platform", value: sysPlatform});
  let eventProperties = convertPropertiesArrayToMap(eventPropertiesArray)
  // @ts-ignore
  let userPropertiesArray: array = [];
  let userProperties = convertPropertiesArrayToMap(userPropertiesArray)
  // assert properties
  if (__AVO_ENV__ !== AvoEnv.Prod || __WEB_DEBUGGER__) {
    let messages: AvoAssertMessage[] = [];
    // debug console in Avo
    if (!__AVO_NOOP__) {
      _avo_invoke(__AVO_ENV__, "2ed8pI4jW_", "7a1193c09bf87f93db3d11a5a62aa8f3e5c9ae7b03ee938ed6cbc1caa213d14b", messages.map(m => Object.assign({}, {tag: m.tag, propertyId: m.propertyId, additionalProperties: m.additionalProperties, actualType: m.actualType})), 'event');
    }
    InternalAvoLogger.logEventSent("card_removed", eventProperties, userProperties);
    if (__WEB_DEBUGGER__) {
      // Avo web debugger
      _avo_debugger_log("2ed8pI4jW_", "card_removed", messages, eventPropertiesArray, userPropertiesArray, []);
    }
  }
  if (!__AVO_NOOP__) {
    if (__INSPECTOR__ != null) {
      // @ts-ignore
      __INSPECTOR__._avoFunctionTrackSchemaFromEvent("card_removed", eventProperties, "2ed8pI4jW_", "7a1193c09bf87f93db3d11a5a62aa8f3e5c9ae7b03ee938ed6cbc1caa213d14b");
    }
    // destination PostHog
    PostHog.logEvent("card_removed", (Object as any).assign({}, eventProperties));

    // destination GoogleTagManagerFront
    GoogleTagManagerFront.logEvent("card_removed", (Object as any).assign({}, eventProperties));
  } else {
    // do nothing
  }
}

/**
 * view_guest_checkout: Signifies the users wants to checkout as a guest and has seen the form.
 *
 * When to trigger this event:
 * 1. When the users has seen this guest checkout screen
 * View in Avo: https://www.avo.app/schemas/Vw4DbNQPc4e5WGVEh2nX/branches/lE4oRzTE7/events/qjwd6HfnJ1/trigger/RQPsgKPH5
 *
 * @see {@link https://www.avo.app/schemas/Vw4DbNQPc4e5WGVEh2nX/branches/lE4oRzTE7/events/qjwd6HfnJ1}
 */
export function viewGuestCheckout() {
  // @ts-ignore
  let eventPropertiesArray: array = [];
  eventPropertiesArray.push({id: "0DRCc5VQqM", name: "platform", value: sysPlatform});
  let eventProperties = convertPropertiesArrayToMap(eventPropertiesArray)
  // @ts-ignore
  let userPropertiesArray: array = [];
  let userProperties = convertPropertiesArrayToMap(userPropertiesArray)
  // assert properties
  if (__AVO_ENV__ !== AvoEnv.Prod || __WEB_DEBUGGER__) {
    let messages: AvoAssertMessage[] = [];
    // debug console in Avo
    if (!__AVO_NOOP__) {
      _avo_invoke(__AVO_ENV__, "qjwd6HfnJ1", "0a810db64dc3b00ae4fbf970bd9c96180e502d304c6c4f93293454be6c30f7fe", messages.map(m => Object.assign({}, {tag: m.tag, propertyId: m.propertyId, additionalProperties: m.additionalProperties, actualType: m.actualType})), 'event');
    }
    InternalAvoLogger.logEventSent("view_guest_checkout", eventProperties, userProperties);
    if (__WEB_DEBUGGER__) {
      // Avo web debugger
      _avo_debugger_log("qjwd6HfnJ1", "view_guest_checkout", messages, eventPropertiesArray, userPropertiesArray, []);
    }
  }
  if (!__AVO_NOOP__) {
    if (__INSPECTOR__ != null) {
      // @ts-ignore
      __INSPECTOR__._avoFunctionTrackSchemaFromEvent("view_guest_checkout", eventProperties, "qjwd6HfnJ1", "0a810db64dc3b00ae4fbf970bd9c96180e502d304c6c4f93293454be6c30f7fe");
    }
    // destination PostHog
    PostHog.logEvent("view_guest_checkout", (Object as any).assign({}, eventProperties));

    // destination GoogleTagManagerFront
    GoogleTagManagerFront.logEvent("view_guest_checkout", (Object as any).assign({}, eventProperties));
  } else {
    // do nothing
  }
}

/**
 * view_promotion_overview: Signifies the user has viewed the promotion overview
 *
 * When to trigger this event:
 * 1.
 * View in Avo: https://www.avo.app/schemas/Vw4DbNQPc4e5WGVEh2nX/branches/lE4oRzTE7/events/IAmn1rZGkQ/trigger/cxCo-XCDZ
 *
 * @see {@link https://www.avo.app/schemas/Vw4DbNQPc4e5WGVEh2nX/branches/lE4oRzTE7/events/IAmn1rZGkQ}
 */
export function viewPromotionOverview() {
  // @ts-ignore
  let eventPropertiesArray: array = [];
  eventPropertiesArray.push({id: "0DRCc5VQqM", name: "platform", value: sysPlatform});
  let eventProperties = convertPropertiesArrayToMap(eventPropertiesArray)
  // @ts-ignore
  let userPropertiesArray: array = [];
  let userProperties = convertPropertiesArrayToMap(userPropertiesArray)
  // assert properties
  if (__AVO_ENV__ !== AvoEnv.Prod || __WEB_DEBUGGER__) {
    let messages: AvoAssertMessage[] = [];
    // debug console in Avo
    if (!__AVO_NOOP__) {
      _avo_invoke(__AVO_ENV__, "IAmn1rZGkQ", "1a44a4f824eb692801129bf57901eee83421cb72d2e9645c17cc2055d829cb43", messages.map(m => Object.assign({}, {tag: m.tag, propertyId: m.propertyId, additionalProperties: m.additionalProperties, actualType: m.actualType})), 'event');
    }
    InternalAvoLogger.logEventSent("view_promotion_overview", eventProperties, userProperties);
    if (__WEB_DEBUGGER__) {
      // Avo web debugger
      _avo_debugger_log("IAmn1rZGkQ", "view_promotion_overview", messages, eventPropertiesArray, userPropertiesArray, []);
    }
  }
  if (!__AVO_NOOP__) {
    if (__INSPECTOR__ != null) {
      // @ts-ignore
      __INSPECTOR__._avoFunctionTrackSchemaFromEvent("view_promotion_overview", eventProperties, "IAmn1rZGkQ", "1a44a4f824eb692801129bf57901eee83421cb72d2e9645c17cc2055d829cb43");
    }
    // destination PostHog
    PostHog.logEvent("view_promotion_overview", (Object as any).assign({}, eventProperties));

    // destination GoogleTagManagerFront
    GoogleTagManagerFront.logEvent("view_promotion_overview", (Object as any).assign({}, eventProperties));
  } else {
    // do nothing
  }
}

export interface RequestShareProperties {
  receiverCountry: string;
  items: Items[] | null | undefined;
  currency: string | null | undefined;
  value: number | null | undefined;
}
/**
 * request_share: When the user has indicated they want to share their link.
 *
 * When to trigger this event:
 * 1. When the user clicked on share to clipboard button.
 * View in Avo: https://www.avo.app/schemas/Vw4DbNQPc4e5WGVEh2nX/branches/lE4oRzTE7/events/6fodp2tLd6/trigger/sXnQ7mR78
 *
 * @param properties the properties associatied with this event
 * @param properties.receiverCountry: The receiver country in ISO 3166-2 format.
 * @param properties.items: array of the items
 * @param properties.currency: Currency of the items associated with the event, in 3-letter ISO 4217 format.
 * @param properties.value: The monetary value of the event.  The value that digicel will receive. If senders doesn't pay for tax send value without tax if senders pays tax include tax.

The value is determined by the product selected by the customer. The /topups/products API returns the list of products. Each product has two IDs. with_tax.id and without_tax.id. If the customer selects to include tax use the with_tax.id, otherwise use the other.
 *
 * @see {@link https://www.avo.app/schemas/Vw4DbNQPc4e5WGVEh2nX/branches/lE4oRzTE7/events/6fodp2tLd6}
 */
export function requestShare(properties: RequestShareProperties) {
  // @ts-ignore
  let eventPropertiesArray: array = [];
  eventPropertiesArray.push({id: "VrFz3T9HK", name: "receiver_country", value: properties.receiverCountry});
  properties.items !== undefined && properties.items !== null ?
    eventPropertiesArray.push({id: "EL97nwQpFG", name: "items", value: properties.items.map(function(value) { return {
        "item_id": value.itemId,
        "item_name": value.itemName,
        "price": value.price,
        "pre_selected": value.preSelected,
        "index": value.index,
        "item_category": value.itemCategory,
        "item_category2": value.itemCategory2,
        "item_list_name": value.itemListName,
        "item_list_id": value.itemListId,
      }
    })}) :
    eventPropertiesArray.push({id: "EL97nwQpFG", name: "items", value: null});
  properties.currency !== undefined && properties.currency !== null ?
    eventPropertiesArray.push({id: "GHJ57MO8Fg", name: "currency", value: properties.currency}) :
    eventPropertiesArray.push({id: "GHJ57MO8Fg", name: "currency", value: null});
  properties.value !== undefined && properties.value !== null ?
    eventPropertiesArray.push({id: "JnJy5h18EQ", name: "value", value: properties.value}) :
    eventPropertiesArray.push({id: "JnJy5h18EQ", name: "value", value: null});
  eventPropertiesArray.push({id: "0DRCc5VQqM", name: "platform", value: sysPlatform});
  let eventProperties = convertPropertiesArrayToMap(eventPropertiesArray)
  // @ts-ignore
  let userPropertiesArray: array = [];
  let userProperties = convertPropertiesArrayToMap(userPropertiesArray)
  // assert properties
  if (__AVO_ENV__ !== AvoEnv.Prod || __WEB_DEBUGGER__) {
    let messages: AvoAssertMessage[] = [];
    messages = messages.concat(assertItemsOptional(properties.items));
    // debug console in Avo
    if (!__AVO_NOOP__) {
      _avo_invoke(__AVO_ENV__, "6fodp2tLd6", "66928af18b825763ff443e51c8f0dee80a69abfb9b8512e33d93088f3bf40e9f", messages.map(m => Object.assign({}, {tag: m.tag, propertyId: m.propertyId, additionalProperties: m.additionalProperties, actualType: m.actualType})), 'event');
    }
    InternalAvoLogger.logEventSent("request_share", eventProperties, userProperties);
    if (__WEB_DEBUGGER__) {
      // Avo web debugger
      _avo_debugger_log("6fodp2tLd6", "request_share", messages, eventPropertiesArray, userPropertiesArray, []);
    }
    // @ts-ignore
    if (__AVO_ENV__ !== AvoEnv.Prod && (__STRICT__ === null || __STRICT__)) {
      // throw exception if messages is not empty
      if (messages.length !== 0) {
        throw new Error("Error sending event 'request_share': " + messages[0]!.message)
      }
    } else {
      messages.forEach(function(m) {
        console[__REPORT_FAILURE_AS__ || 'error']("[avo] " + m.message);
      });
    }
  }
  if (!__AVO_NOOP__) {
    if (__INSPECTOR__ != null) {
      // @ts-ignore
      __INSPECTOR__._avoFunctionTrackSchemaFromEvent("request_share", eventProperties, "6fodp2tLd6", "66928af18b825763ff443e51c8f0dee80a69abfb9b8512e33d93088f3bf40e9f");
    }
    // destination PostHog
    PostHog.logEvent("request_share", (Object as any).assign({}, eventProperties));

    // destination GoogleTagManagerFront
    GoogleTagManagerFront.logEvent("request_share", (Object as any).assign({}, eventProperties));
  } else {
    // do nothing
  }
}

export interface RequestViewProperties {
  receiverCountry: string;
  items: Items[] | null | undefined;
  currency: string | null | undefined;
  value: number | null | undefined;
}
/**
 * request_view: Signifies a customers has viewed a request
 *
 * When to trigger this event:
 * 1. When a users views the request
 * View in Avo: https://www.avo.app/schemas/Vw4DbNQPc4e5WGVEh2nX/branches/lE4oRzTE7/events/4agtZh4yOk/trigger/SX_oOIjL2
 *
 * @param properties the properties associatied with this event
 * @param properties.receiverCountry: The receiver country in ISO 3166-2 format.
 * @param properties.items: array of the items
 * @param properties.currency: Currency of the items associated with the event, in 3-letter ISO 4217 format.
 * @param properties.value: The monetary value of the event.  The value that digicel will receive. If senders doesn't pay for tax send value without tax if senders pays tax include tax.

The value is determined by the product selected by the customer. The /topups/products API returns the list of products. Each product has two IDs. with_tax.id and without_tax.id. If the customer selects to include tax use the with_tax.id, otherwise use the other.
 *
 * @see {@link https://www.avo.app/schemas/Vw4DbNQPc4e5WGVEh2nX/branches/lE4oRzTE7/events/4agtZh4yOk}
 */
export function requestView(properties: RequestViewProperties) {
  // @ts-ignore
  let eventPropertiesArray: array = [];
  eventPropertiesArray.push({id: "VrFz3T9HK", name: "receiver_country", value: properties.receiverCountry});
  properties.items !== undefined && properties.items !== null ?
    eventPropertiesArray.push({id: "EL97nwQpFG", name: "items", value: properties.items.map(function(value) { return {
        "item_id": value.itemId,
        "item_name": value.itemName,
        "price": value.price,
        "pre_selected": value.preSelected,
        "index": value.index,
        "item_category": value.itemCategory,
        "item_category2": value.itemCategory2,
        "item_list_name": value.itemListName,
        "item_list_id": value.itemListId,
      }
    })}) :
    eventPropertiesArray.push({id: "EL97nwQpFG", name: "items", value: null});
  properties.currency !== undefined && properties.currency !== null ?
    eventPropertiesArray.push({id: "GHJ57MO8Fg", name: "currency", value: properties.currency}) :
    eventPropertiesArray.push({id: "GHJ57MO8Fg", name: "currency", value: null});
  properties.value !== undefined && properties.value !== null ?
    eventPropertiesArray.push({id: "JnJy5h18EQ", name: "value", value: properties.value}) :
    eventPropertiesArray.push({id: "JnJy5h18EQ", name: "value", value: null});
  eventPropertiesArray.push({id: "0DRCc5VQqM", name: "platform", value: sysPlatform});
  let eventProperties = convertPropertiesArrayToMap(eventPropertiesArray)
  // @ts-ignore
  let userPropertiesArray: array = [];
  let userProperties = convertPropertiesArrayToMap(userPropertiesArray)
  // assert properties
  if (__AVO_ENV__ !== AvoEnv.Prod || __WEB_DEBUGGER__) {
    let messages: AvoAssertMessage[] = [];
    messages = messages.concat(assertItemsOptional(properties.items));
    // debug console in Avo
    if (!__AVO_NOOP__) {
      _avo_invoke(__AVO_ENV__, "4agtZh4yOk", "d639297838f29fa57ba1f6af2b87e5f7934b1cd343ad622bb7386bf4cba0dc20", messages.map(m => Object.assign({}, {tag: m.tag, propertyId: m.propertyId, additionalProperties: m.additionalProperties, actualType: m.actualType})), 'event');
    }
    InternalAvoLogger.logEventSent("request_view", eventProperties, userProperties);
    if (__WEB_DEBUGGER__) {
      // Avo web debugger
      _avo_debugger_log("4agtZh4yOk", "request_view", messages, eventPropertiesArray, userPropertiesArray, []);
    }
    // @ts-ignore
    if (__AVO_ENV__ !== AvoEnv.Prod && (__STRICT__ === null || __STRICT__)) {
      // throw exception if messages is not empty
      if (messages.length !== 0) {
        throw new Error("Error sending event 'request_view': " + messages[0]!.message)
      }
    } else {
      messages.forEach(function(m) {
        console[__REPORT_FAILURE_AS__ || 'error']("[avo] " + m.message);
      });
    }
  }
  if (!__AVO_NOOP__) {
    if (__INSPECTOR__ != null) {
      // @ts-ignore
      __INSPECTOR__._avoFunctionTrackSchemaFromEvent("request_view", eventProperties, "4agtZh4yOk", "d639297838f29fa57ba1f6af2b87e5f7934b1cd343ad622bb7386bf4cba0dc20");
    }
    // destination PostHog
    PostHog.logEvent("request_view", (Object as any).assign({}, eventProperties));

    // destination GoogleTagManagerFront
    GoogleTagManagerFront.logEvent("request_view", (Object as any).assign({}, eventProperties));
  } else {
    // do nothing
  }
}

export default {
  AvoEnv,
  initAvo,
  setSystemProperties,
  avoInspectorApiKey,
  Platform,
  Button,
  Status,
  ErrorCategory,
  Method,
  ItemCategory2,
  CustomerType,
  ItemListName,
  Origin,
  ItemCategory,
  CreativeSlot,
  PaymentType,
  purchase,
  addPaymentInfo,
  beginCheckout,
  viewCart,
  addToCart,
  selectPromotion,
  homeView,
  selectReceiverCountry,
  selectSenderCountry,
  login,
  topupStart,
  viewItemList,
  typeProductSwitch,
  selectItem,
  selectAutotopup,
  viewOptionalAddOns,
  viewPromotion,
  buttonClick,
  viewAuthentication,
  viewTransactionDetail,
  removeFrequentPayment,
  viewFrequentPayments,
  viewTransactionHistory,
  viewAccount,
  signUp,
  viewSignUp,
  passwordForgot,
  errorLogged,
  emailVerificationSent,
  emailVerificationVerified,
  viewPaymentOptions,
  selectPaymentOption,
  cardRemoved,
  viewGuestCheckout,
  viewPromotionOverview,
  requestShare,
  requestView,
}

// AVOMODULEMAP:"Avo"
// AVOEVENTMAP:["purchase","addPaymentInfo","beginCheckout","viewCart","addToCart","selectPromotion","homeView","selectReceiverCountry","selectSenderCountry","login","topupStart","viewItemList","typeProductSwitch","selectItem","selectAutotopup","viewOptionalAddOns","viewPromotion","buttonClick","viewAuthentication","viewTransactionDetail","removeFrequentPayment","viewFrequentPayments","viewTransactionHistory","viewAccount","signUp","viewSignUp","passwordForgot","errorLogged","emailVerificationSent","emailVerificationVerified","viewPaymentOptions","selectPaymentOption","cardRemoved","viewGuestCheckout","viewPromotionOverview","requestShare","requestView"]
