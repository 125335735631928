import { Components, Theme } from '@mui/material';

export const MuiAccordion: Components<Theme>['MuiAccordion'] = {
  defaultProps: {
    disableGutters: true,
    elevation: 0,
  },
  styleOverrides: {
    root: ({ theme }) => ({
      borderRadius: 10,

      '&:not(:last-child)': {
        marginBottom: theme.spacing(2),
      },

      '&:before': {
        display: 'none',
      },
      '&:last-of-typem, &:first-of-type': {
        borderRadius: 10,
      },
    }),
  },
};
