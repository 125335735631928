import 'theme/iubenda.css';

import { CacheProvider, EmotionCache } from '@emotion/react';
import { CssBaseline } from '@mui/material';
import { HydrationBoundary, QueryClientProvider } from '@tanstack/react-query';
import type { AppProps } from 'next/app';
import dynamic from 'next/dynamic';
import Script from 'next/script';
import AuthProvider from 'providers/auth';
import { CountryProvider } from 'providers/country';
import { MdaProvider } from 'providers/mda';
import { MountedProvider } from 'providers/mounted';
import PrismicProvider from 'providers/prismic';
import React, { Suspense, useEffect, useRef, useState } from 'react';

import config from 'config';

import { createEmotionCache } from 'utils/emotion-cache.utils';
import { isDev, isProd } from 'utils/env.utils';

import { initializeAvo } from 'services/avo';
import IntlProvider from 'services/i18n';
import SnackbarProvider from 'services/notistack';
import PosthogProvider from 'services/posthog';
import { queryClient as customQueryClient } from 'services/react-query';
import ThemeContext from 'services/theme-context';

import RootBoundary from 'components/@boundaries/RootBoundary';
import PageContainer from 'components/@screens/PageContainer';

const ReactQueryDevtools = dynamic(() =>
  import('@tanstack/react-query-devtools').then(
    (mod) => mod.ReactQueryDevtools,
  ),
);

interface MyAppProps
  extends AppProps<{ dehydratedState: unknown; fixedHeight?: boolean }> {
  emotionCache?: EmotionCache;
}

const clientSideEmotionCache = createEmotionCache();

const BEACON_URL =
  'https://test.accdab.net/cdn/cs/_gfAgIDrai-tR6ttFjHtUd8u2B4.js';
const PRODUCTION_BEACON_URL =
  'https://prod.accdab.net/cdn/cs/HNaQNkqwvt_2_ZxVE0pK3sTqVkA.js';

const inAuthScript = isProd() ? PRODUCTION_BEACON_URL : BEACON_URL;

const PageWrapper = ({ Component, pageProps }: MyAppProps) => {
  return (
    <IntlProvider>
      <PageContainer fixedHeight={pageProps.fixedHeight}>
        <Suspense>
          <Component {...pageProps} />
        </Suspense>
      </PageContainer>
    </IntlProvider>
  );
};

function MyApp(props: MyAppProps) {
  const didInitAvo = useRef(false);
  const { pageProps } = props;
  const [queryClient] = useState(() => customQueryClient);

  const { emotionCache = clientSideEmotionCache, ...rest } = props;

  useEffect(() => {
    if (!didInitAvo.current) {
      initializeAvo();
      didInitAvo.current = true;
    }
  }, []);

  return (
    <>
      <Script
        id="gtag-base"
        strategy="afterInteractive"
        dangerouslySetInnerHTML={{
          __html: `
       (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
       new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
       j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
       'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
       })(window,document,'script','dataLayer', '${config.GTM.ID}');
     `,
        }}
      />
      <Script id="inauth" src={inAuthScript} strategy="lazyOnload" />
      <RootBoundary>
        <PosthogProvider>
          <PrismicProvider>
            <QueryClientProvider client={queryClient}>
              <HydrationBoundary state={pageProps.dehydratedState}>
                <MdaProvider>
                  <CountryProvider>
                    <AuthProvider>
                      <MountedProvider>
                        <CacheProvider value={emotionCache}>
                          <ThemeContext>
                            <SnackbarProvider>
                              <CssBaseline />
                              <PageWrapper {...rest} />
                            </SnackbarProvider>
                          </ThemeContext>
                        </CacheProvider>
                      </MountedProvider>
                    </AuthProvider>
                  </CountryProvider>
                </MdaProvider>
              </HydrationBoundary>
              {isDev() && (
                <ReactQueryDevtools
                  initialIsOpen={false}
                  buttonPosition="bottom-left"
                />
              )}
            </QueryClientProvider>
          </PrismicProvider>
        </PosthogProvider>
      </RootBoundary>
    </>
  );
}

export default MyApp;
