import { deleteCookie, getCookie, setCookie } from 'cookies-next';

import { COOKIES_MAX_AGE_REMEMBER } from 'constants/storage.constants';
import { TokenResponse, TokenStorage } from 'types/auth.types';

export const storeTokens = (data: TokenResponse, remember: boolean) => {
  setCookie(TokenStorage.AccessToken, data.accessToken, {
    expires: remember
      ? new Date(new Date().getTime() + data.expiresIn * 1000)
      : undefined,
    secure: true,
    sameSite: 'strict',
  });
  setCookie(TokenStorage.RefreshToken, data.refreshToken, {
    maxAge: remember ? COOKIES_MAX_AGE_REMEMBER : undefined,
  });

  if (remember) {
    setCookie(TokenStorage.Remember, 'true', {
      maxAge: COOKIES_MAX_AGE_REMEMBER,
      secure: true,
      sameSite: 'strict',
    });
  }
};

export const clearTokens = () => {
  deleteCookie(TokenStorage.AccessToken);
  deleteCookie(TokenStorage.RefreshToken);
  deleteCookie(TokenStorage.Remember);
};

export const getTokens = () => {
  return {
    accessToken: getCookie(TokenStorage.AccessToken) as string | undefined,
    appAccessToken: getCookie(TokenStorage.Authentication) as
      | string
      | undefined,
    refreshToken: getCookie(TokenStorage.RefreshToken) as string | undefined,
    remember: !!getCookie(TokenStorage.Remember),
  };
};
