import { ApiResponse } from 'types/api.types';
import { PurchaseQueryParams } from 'types/topup.types';
import { Transaction, TransactionDetail } from 'types/transaction.types';

import RestApiService from '.';

export default {
  getTransactions(this: RestApiService, params: { page: number }) {
    return this.api.get<ApiResponse<Array<Transaction>>>('/transactions', {
      params,
    });
  },
  getTransaction(
    this: RestApiService,
    transactionId: TransactionDetail['id'],
    query?: PurchaseQueryParams,
  ) {
    return this.api.get<TransactionDetail>(
      `/topups/transactions/${transactionId}`,
      { params: query },
    );
  },
};
