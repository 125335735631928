import {
  UseInfiniteQueryOptions,
  UseQueryOptions,
} from '@tanstack/react-query';
import { AxiosError } from 'axios';

export const ONE_MINUTE = 60000;

export enum StaleTime {
  THIRTY_SEC = ONE_MINUTE / 2,
  MINUTE = ONE_MINUTE,
  FIVE_MIN = 5 * ONE_MINUTE,
  FIFTEEN_MIN = 15 * ONE_MINUTE,
  THIRTY_MIN = 30 * ONE_MINUTE,
  FORTY_FIVE_MIN = 45 * ONE_MINUTE,
  ONE_HOUR = 60 * ONE_MINUTE,
  FOREVER = Infinity,
  ONE_DAY = 24 * 60 * ONE_MINUTE,
}

export enum RefetchTime {
  MINUTE = ONE_MINUTE,
  FIVE_MIN = 5 * ONE_MINUTE,
  FIFTEEN_MIN = 15 * ONE_MINUTE,
}

export interface UseCommonQueryOptions<T, E = AxiosError>
  extends Omit<
    UseQueryOptions<T, E>,
    'queryKey' | 'queryFn' | 'refetchInterval'
  > {
  refetchInterval?: number | false;
}

export interface UseCommonInfiniteQueryOptions<T>
  extends Omit<
    UseInfiniteQueryOptions<T>,
    'queryKey' | 'queryFn' | 'refetchInterval'
  > {
  refetchInterval?: number | false;
}
