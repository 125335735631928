import { PrismicPreview } from '@prismicio/next';
import { PrismicProvider as Provider } from '@prismicio/react';
import React, { PropsWithChildren } from 'react';

import { repositoryName } from 'services/prismic';

import Link from 'components/@common/Link';

const PrismicProvider: React.FC<PropsWithChildren> = ({ children }) => {
  return (
    <Provider
      internalLinkComponent={({ href, ...props }) => (
        <Link href={href}>
          <a {...props} />
        </Link>
      )}
    >
      {children}
      <PrismicPreview repositoryName={repositoryName} />
    </Provider>
  );
};

export default PrismicProvider;
