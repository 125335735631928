import { Components, Theme } from '@mui/material';

export const MuiFilledInput: Components<Theme>['MuiFilledInput'] = {
  styleOverrides: {
    root: ({ theme }) => ({
      borderRadius: '48px',
      border: `1px solid ${theme.palette.support.neutral05}`,
      backgroundColor: theme.palette.support.neutral06,
    }),
    input: ({ theme }) => ({
      padding: '11px 21px 9px',
      color: theme.palette.brand.darkBlue,
    }),
  },
};
