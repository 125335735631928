import { RegisterParams } from 'types/auth.types';
import {
  ChangePasswordParams,
  SignupGuestParams,
  SignupGuestResponse,
  User,
  UserUpdate,
} from 'types/user.types';

import RestApiService from '.';

export default {
  getUserProfile(this: RestApiService) {
    return this.api.get<User>('/users/profile');
  },
  updateUserProfile(this: RestApiService, user: UserUpdate) {
    return this.api.post('/users/profile', user);
  },
  activateUser(this: RestApiService, activationToken: string) {
    return this.api.post('/users/activate', { activationToken });
  },
  register(
    this: RestApiService,
    { username, password, ...rest }: RegisterParams,
  ) {
    return this.api.post<{ email: string }>(
      '/users',
      {
        email: username,
        password,
        confirmPassword: password,
        ...rest,
      },
      { authDisabled: true },
    );
  },
  forgotPassword(this: RestApiService, email: string, resetUrl: string) {
    return this.api.post(
      '/users/forgot_password',
      { email, resetUrl },
      { authDisabled: true },
    );
  },
  changePassword(this: RestApiService, params: ChangePasswordParams) {
    return this.api.post('/users/change_password', params, {
      authDisabled: true,
    });
  },
  signupGuest(this: RestApiService, params: SignupGuestParams) {
    return this.api.post<SignupGuestResponse>('/users/guest', params, {
      hasAnonymous: true,
    });
  },
};
