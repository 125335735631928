import { generatePath } from 'utils/route.utils';

import { Routes } from 'constants/routes.constants';
import { ApiResponse } from 'types/api.types';
import {
  ApplePayMerchantValidationResponse,
  AutoPay,
  BonusProduct,
  GetPaypalUrlParams,
  ProductUpsell,
  ProductUpsellParams,
  PromocodeParams,
  Purchase,
  PurchaseParams,
  PurchasePaypalParams,
  PurchaseQueryParams,
  PurchaseStatus,
  PurchaseWalletPayParams,
  TopupCountry,
  TopupProduct,
  TopupProductParams,
} from 'types/topup.types';

import RestApiService from '.';

export default {
  getAllCountries(this: RestApiService) {
    return this.api.get<ApiResponse<Array<TopupCountry>>>(
      '/topups/countries/__secret__/noauth',
    );
  },
  getProducts(this: RestApiService, params: TopupProductParams) {
    return this.api.get<TopupProduct>('/topups/products', {
      params,
      hasAnonymous: true,
    });
  },
  getProductUpsell(
    this: RestApiService,
    { productId, withTax }: ProductUpsellParams,
  ) {
    return this.api.get<ProductUpsell>(
      `/topups/products/${productId}/upsells`,
      {
        params: { withTax },
        hasAnonymous: true,
      },
    );
  },
  getProductBonus(this: RestApiService, productId: string) {
    return this.api.get<BonusProduct>(`/topups/products/${productId}/bonus`, {
      hasAnonymous: true,
    });
  },
  validatePromo(this: RestApiService, params: PromocodeParams) {
    return this.api.post('/topups/validate_promo', params, {
      hasAnonymous: true,
    });
  },
  merchantValidation(this: RestApiService, validationUrl: string) {
    return this.api.post<ApplePayMerchantValidationResponse>(
      `/topups/purchases/applepaymerchantverification`,
      {
        validationUrl,
      },
    );
  },
  purchase(
    this: RestApiService,
    params: Array<PurchaseParams>,
    query?: PurchaseQueryParams,
  ) {
    return this.api.post<Purchase>(
      '/topups/purchases',
      params.length > 1 ? params : params[0],
      { params: query },
    );
  },
  purchasePaypal(
    this: RestApiService,
    purchaseId: string,
    params: PurchasePaypalParams,
  ) {
    return this.api.post(`/topups/purchases/${purchaseId}/paypal`, params);
  },
  purchaseDigitalWallet(
    this: RestApiService,
    purchaseId: string,
    params: PurchaseWalletPayParams,
  ) {
    return this.api.post(`/topups/purchases/${purchaseId}/receipt`, params);
  },
  getPurchaseStatus(
    this: RestApiService,
    purchaseId: Purchase['purchaseId'],
    query?: PurchaseQueryParams,
  ) {
    return this.api.get<PurchaseStatus>(
      `/topups/purchases/${purchaseId}/status`,
      { params: query },
    );
  },
  getAutoPays(this: RestApiService) {
    return this.api.get<ApiResponse<Array<AutoPay>>>(`/topups/autopays`);
  },
  removeAutoPay(this: RestApiService, autoPayId: AutoPay['id']) {
    return this.api.delete(`/topups/autopays/${autoPayId}`);
  },
  removeSavedPayment(
    this: RestApiService,
    paymentMethodReference: string,
    paymentMethod: string,
  ) {
    return this.api.delete('/topups/saved_payment_method', {
      params: {
        paymentMethodReference,
        paymentMethod,
      },
    });
  },
  getPaypalUrl(this: RestApiService, { url, purchaseId }: GetPaypalUrlParams) {
    return this.api.post<{ url: string }>(url, {
      returnUrl: `${window.location.origin}${generatePath(
        Routes.PaymentPaypal,
        {
          purchaseId,
        },
      )}`,
    });
  },
};
