import { getCookie } from 'cookies-next';
import { useRouter } from 'next/router';
import { posthog } from 'posthog-js';
import { PostHogProvider } from 'posthog-js/react';
import React, { PropsWithChildren, useEffect } from 'react';

import config from 'config';

import { isDev, isStaging } from 'utils/env.utils';

if (typeof window !== 'undefined') {
  const flags = getCookie('bootstrapData') as string;

  let bootstrapData = undefined;

  if (flags) {
    bootstrapData = JSON.parse(flags);
  }

  const disableDecide = !!bootstrapData ? false : true;

  posthog.init(config.POSTHOG.API_KEY, {
    api_host: config.POSTHOG.HOST,
    bootstrap: bootstrapData ?? {},
    autocapture: false,
    disable_session_recording: true,
    capture_pageview: false,
    capture_pageleave: false,
    advanced_disable_decide: disableDecide,
    loaded: (posthog) => {
      if (isStaging() || isDev()) {
        posthog.debug();
      }
    },
  });
}

const PosthogProvider = ({ children }: PropsWithChildren) => {
  const { asPath } = useRouter();

  useEffect(() => {
    if (asPath.includes('__posthog')) {
      const toolbarJSON = new URLSearchParams(asPath.split('#')[1]).get(
        '__posthog',
      );

      if (toolbarJSON) {
        posthog.loadToolbar(JSON.parse(toolbarJSON));
      }
    }
  }, [asPath]);

  return <PostHogProvider client={posthog}>{children}</PostHogProvider>;
};

export default PosthogProvider;
