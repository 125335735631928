export const eCommerceEvents = [
  'add_payment_info',
  'add_to_cart',
  'begin_checkout',
  'purchase',
  'select_item',
  'select_promotion',
  'view_cart',
  'view_item_list',
  'view_promotion',
];

export const dataLayerName = 'dataLayer';
