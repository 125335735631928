import { Components, Theme } from '@mui/material';

export const MuiChip: Components<Theme>['MuiChip'] = {
  styleOverrides: {
    root: ({ theme }) => ({
      backgroundColor: theme.palette.brand.darkBlue,
      color: theme.palette.support.neutral01,
      fontWeight: 700,
      fontSize: 13,
      textTransform: 'uppercase',
      height: 20,
      paddingTop: 2,
    }),
    label: {
      paddingInline: 10,
    },
    avatar: {
      width: 14,
      height: 14,
    },
  },
};
